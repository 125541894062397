import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ITour } from '../steps';
import { OnBoardingService } from '../on-boarding.service';

@Component({
  selector: 'vp-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.scss'],
})
export class OnBoardingComponent implements OnInit {
  public tour: ITour;

  constructor(
    private onBoardingService: OnBoardingService,
    private bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {}

  startTour() {
    this.onBoardingService.showOnBoardingTour(this.tour);
    this.bsModalRef.hide();
  }
}
