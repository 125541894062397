import { STORE_FEATURE_KEY, State } from './store.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IApp } from '../../models';
import { SidenavState } from './store.reducers.sidenav';

// Lookup the 'Store' feature state managed by NgRx
export const getStoreState = createFeatureSelector<State>(STORE_FEATURE_KEY);

export const getLoaded = createSelector(
  getStoreState,
  (state: State) => state.loaded
);

export const getLoading = createSelector(
  getStoreState,
  (state: State) => state.loading
);

export const getAppLoaded = createSelector(
  getStoreState,
  (state: State) => state.appLoaded
);

export const getAppLoading = createSelector(
  getStoreState,
  (state: State) => state.appLoading
);

export const getStore = createSelector(getStoreState, (store: State) => store);

export const collections = createSelector(
  getStoreState,
  (store: State) => store.collections
);

export const categories = createSelector(
  getStoreState,
  (store: State) => store.categories
);

export const sidenav = createSelector(
  getStoreState,
  (store: State) => store.sidenav
);

export const focus = createSelector(
  getStoreState,
  (store: State) => store.focus
);

export const periods = createSelector(
  getStoreState,
  (store: State) => store.period
);

export const isHeaderVisible = createSelector(
  getStoreState,
  (store: State) => store.isHeaderVisible
);

export const instances = createSelector(
  getStoreState,
  (store: State) => store.instances
);

export const instance = createSelector(getStoreState, (store: State) => {
  return store.instance;
});

export const assistantEnabled = createSelector(
  getStoreState,
  (store: State) => {
    return store.instance?.config?.data.assistantEnabled;
  }
);

export const getCurrentApp = createSelector(
         getStoreState,
         getLoaded,
         getAppLoaded,
         instance,
         (store: State, loaded, appLoaded, ins) => {
           if (loaded && appLoaded && store.app) {
             return { ...store.app, instanceRef: ins };
           }
           return undefined;
         }
       );

export const getApps = createSelector(
  getStoreState,
  getCurrentApp,
  (store: State, app: IApp) =>
    store.apps.map((a) => {
      return { ...a, isActive: a.id === app?.id };
    })
);

export const isAppAvailable = createSelector(
  getStoreState,
  getLoaded,
  (store: State, loaded, props: { app: string }) => {
    if (loaded) {
      return {
        ...store.apps.find((a) => a.name.toLowerCase() === props.app),
        instanceRef: store.instance,
      };
    }
  }
);

export const expandMap = createSelector(getStoreState, (store: State) => {
  return store.expandMap;
});

export const user = createSelector(getStoreState, (store: State) => store.user);

/**SIde nav */
export const getSideNavStore = createSelector(
  getStoreState,
  (state: State) => state.sidenav
);

export const getExtraUrlParams = createSelector(
  getSideNavStore,
  (sidenav: SidenavState) => {
    return sidenav.extraUrlParams;
  }
);

export const getMultipleIndicator = createSelector(
  getSideNavStore,
  (sidenav: SidenavState) => sidenav.multiple
);
