<div class="tile_chart-dualbar">
  <div class="tile_chart-legend">
    <span>
      <i [ngStyle]="{ 'background-color': data.series_colors[0] }"></i>Male
    </span>
    <span>
      <i [ngStyle]="{ 'background-color': data.series_colors[1] }"></i>Female
    </span>
  </div>
  <div class="chart-container dual">
    <div>
      <apx-chart
        [states]="leftChartOptions.states"
        [tooltip]="leftChartOptions.tooltip"
        [series]="leftChartOptions.series"
        [chart]="leftChartOptions.chart"
        [plotOptions]="leftChartOptions.plotOptions"
        [dataLabels]="leftChartOptions.dataLabels"
        [grid]="leftChartOptions.grid"
        [yaxis]="leftChartOptions.yaxis"
        [xaxis]="leftChartOptions.xaxis"
        [colors]="leftChartOptions.colors"
        isDisabled="true"
      ></apx-chart>
    </div>
    <div class="labels-container" id="labelsContainer">
      <div *ngFor="let i of data.list">
        <span>{{ i }}</span>
      </div>
    </div>
    <div>
      <apx-chart
        [states]="rightChartOptions.states"
        [tooltip]="rightChartOptions.tooltip"
        [series]="rightChartOptions.series"
        [chart]="rightChartOptions.chart"
        [plotOptions]="rightChartOptions.plotOptions"
        [dataLabels]="rightChartOptions.dataLabels"
        [grid]="rightChartOptions.grid"
        [yaxis]="rightChartOptions.yaxis"
        [xaxis]="rightChartOptions.xaxis"
        [colors]="rightChartOptions.colors"
        isDisabled="true"
      ></apx-chart>
    </div>
  </div>
</div>
