<div class="teamsBox">
        <h4>This will add Vantage App to your Channel</h4>
        <p>
                Vantage is an AI-enabled cloud platform that empowers healthcare workers to make the right decision, take the right action, right now.
                <br/><br/>
                By combining big data with purposeful analytics and proven workflows, we ensure they receive real-time insight and impactful action to best use time, and in a way that truly matters.
                <br/><br/>
                Through Vantage, the best leading-edge technology is distributed in simple and immediate ways to save lives.
                <br/><br/>
                We’re helping people see what’s next, to make right now.
        </p>
</div>