import { ActivatedRoute, Router } from '@angular/router';
import {
  AppState,
  IApp,
  ICollection,
  IInstance,
  ISideNavItemModel,
} from '@vantage-platform/store/lib/models';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import {
  SidenavState,
  categories,
  collections,
  getApps,
  getExtraUrlParams,
  instance,
  isHeaderVisible,
  resetCategories,
  resetCollections,
  setApp,
  setMultiple,
  sidenav,
} from '@vantage-platform/store';
import { distinctUntilChanged, filter, take, takeUntil } from "rxjs/operators";

import { InsightService } from '@vantage-platform/app-insight';
import { LoadingIndicatorService } from '@vantage-platform/store/lib/services/loading-indicator/loading-indicator.service';
import { Store } from '@ngrx/store';
import { setCardsItems } from '@vantage-platform/cards-ui';

@Component({
  selector: 'vp-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
  private _isToggled = this.breakpoints.isMatched(Breakpoints.Large);
  // by default open on desktop and closed on smaller portable devices
  @HostBinding('class.open') get isToggled() {
    return this._isToggled;
  } ;
  set isToggled(val) {
    this._isToggled = val;
    this.service.onToggle$.next(val);
  }
  @HostBinding('class.apps') isAppsToggled = false;
  @HostBinding('class.header-visible') isHeaderVisible = false;

  @Input() service: {
    navigateToAppSelector();
    createCollection();
    onToggle$: Subject<boolean>;
  };

  sidenav: SidenavState;
  instance: IInstance;
  collections$: Observable<ICollection[]>;
  categories$: Observable<ISideNavItemModel[]>;
  apps$: Observable<IApp[]>;
  collections: ICollection[];
  categories: ISideNavItemModel[];
  multiple: boolean = false;

  private destroy$ = new Subject();

  get isAllCategories(): boolean {
    return !(
      this.collections.some((c) => c.isSelected) ||
      this.categories.some((c) => c.isSelected)
    );
  }

  get isLargeScreen(): boolean {
    return this.breakpoints.isMatched([Breakpoints.Large, Breakpoints.XLarge]);
  }

  constructor(
    public store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private track: InsightService,
    private loadingIndicatorService: LoadingIndicatorService,
    private breakpoints: BreakpointObserver
  ) {}

  ngOnInit() {
    this.store.select(sidenav).subscribe((nav: SidenavState) => {
      this.sidenav = nav;
      this.multiple = nav.multiple;
    });
    this.collections$ = this.store.select(collections);
    this.categories$ = this.store.select(categories);

    this.apps$ = this.store.select(getApps);
    this.store.select(instance).subscribe((i) => (this.instance = i));
    this.store
      .select(isHeaderVisible)
      .subscribe((v) => (this.isHeaderVisible = v));

    this.collections$.subscribe((c) => (this.collections = c));
    this.categories$.subscribe((c) => {
      this.categories = c;
      let multi = c.filter((i) => i.isSelected).length > 1;
      if (multi && !this.multiple) {
        this.multiple = multi;
      }
    });
    // if hit analytics report route then close side nav
    this.store.select("router").pipe(
      distinctUntilChanged(),
      filter(r => /\/\d+\/analytics\/report\/\d+\/\d+/g.test(r.state.url)),
      takeUntil(this.destroy$),
    ).subscribe(a => this.isToggled = false);
  }

  ngOnDestroy() {
    this.destroy$.next(undefined);
  }

  /**
   * navigate - navigate to app selector page
   */
  public navigateToAppSelector() {
    this.track.event('viewVantageSuite');
    this.loadingIndicatorService.show();

    this.store.dispatch(setApp({ app: undefined }));
    this.router.navigate(['.'], {
      relativeTo: this.route,
    });
  }

  /**
   * toggleSideNav - expand/collapse side nav function
   */
  public toggleSideNav() {
    this.track.event('sideNavToggle');

    this.isToggled = !this.isToggled;
  }

  public navigate(app: IApp) {
    if (app.isActive) return;

    this.track.event('changeApp', { newAppId: app.id, newAppName: app.name });
    if (app.isExternalApp) {
      window.open(app.url);
    } else {
      this.loadingIndicatorService.show(app);
      this.store.dispatch(setCardsItems({ payload: [] }));
      this.router.navigate([`${this.instance.id}/${app.name.toLowerCase()}`]);
    }
    this.toggleAppsSuite();
  }

  /**
   * toggleSideNav - expand/collapse side nav with apps
   */
  public toggleAppsSuite() {
    this.track.event('toggleAppSuite');

    this.isAppsToggled = !this.isAppsToggled;
  }

  /**
   * Toggle multiple mode for categories
   */
  public toggleMultiple() {
    this.track.event('toggleSideNavMultiple');

    if (this.multiple) {
      // Apply all categories if multiple off
      this.allFocusClick(false);
    }
    this.store.dispatch(setMultiple({ payload: !this.multiple }));
  }

  /**
   * Remove area parameter from URL
   * all categories applied
   */
  public allFocusClick(toggleSideNav: boolean = true) {
    this.track.event('viewAllCategories');
    this.store.dispatch(resetCollections());
    this.store.dispatch(resetCategories());

    let extraParams;
    this.store
      .select(getExtraUrlParams)
      .pipe(take(1))
      .subscribe((values) => {
        extraParams = values || {};
      });

    const options = {
      relativeTo: this.route,
      queryParams: { ...extraParams, page: 1 },
    };
    this.router.navigate([`.${this.sidenav.path}`], options);
    if (!this.isLargeScreen && toggleSideNav) {
      this.isToggled = false;
    }
  }

  /**
   * focusSelect - call service focus areas select
   */
  public categoriesSelect(selected: ISideNavItemModel) {
    let item = { ...selected };
    item.isSelected = !item.isSelected;

    let expraParams;
    this.store
      .select(getExtraUrlParams)
      .pipe(take(1))
      .subscribe((values) => {
        expraParams = values || {};
      });

    let ids;
    if (this.multiple) {
      ids = [
        ...this.categories
          .filter((i) => i.isSelected && i.categoryID !== item.categoryID)
          .map((i) => i.categoryID),
      ];
      if (item.isSelected) {
        ids.push(item.categoryID);
      }
    } else {
      ids = [item.categoryID];
    }

    const options = {
      relativeTo: this.route,
      queryParams: {
        ...expraParams,
        area: [...ids],
        page: 1,
      },
    };
    this.track.event('selectCategory', {
      categoryId: selected.categoryID,
      categoryName: selected.title,
      multiple: this.multiple,
      categoriesIds: ids,
    });
    this.router.navigate([`.${this.sidenav.path}`], options);
    if (!this.isLargeScreen && !this.multiple) {
      this.isToggled = false;
    }
  }

  /**
   * collectionsSelect - call service collection select method
   */
  public collectionsSelect(selected: ICollection): void {
    this.track.event('selectCollection', { collectionId: selected.id });
    this.router.navigate([`./${this.sidenav.path}/collections`, selected.id], {
      relativeTo: this.route,
      queryParams: { page: 1 },
    });
    if (!this.isLargeScreen) {
      this.isToggled = false;
    }
  }

  /**
   * createCollection - call create collection modal
   */
  public createCollection() {
    this.track.event('openCreateCollectionModal');

    this.service.createCollection();
  }
}
