import { BLOB_STORAGE_TOKEN, azureBlobStorageFactory } from './services/token';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthService } from './auth/auth.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from '@angular/common';
import { FlowService } from './services/flow.service';
import { GraphService } from './services/graph.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { Share2TeamsComponent } from './share-card-modal/share-2-teams.component';
import { TeamsContextService } from './services/teams-context.service';
import { TeamsService } from './services/teams.service';
import { ToasterModule } from '@vantage-platform/toastr';

function teamsCtxFactory(teamsCtx: TeamsContextService) {
    return (): Promise<any> => {
      return teamsCtx.init()
    }
  }

// @dynamic
@NgModule({
    declarations: [Share2TeamsComponent],
    imports: [
        ModalModule.forRoot(),
        PopoverModule.forRoot(),
        BsDropdownModule.forRoot(),
        CommonModule,
        NgxSelectModule,
        ReactiveFormsModule,
        FormsModule,
        NgxSpinnerModule,
        ToasterModule,
    ],
    providers: [
        TeamsContextService,
        {
            provide: APP_INITIALIZER,
            useFactory: teamsCtxFactory,
            deps: [TeamsContextService],
            multi: true
        },
        AuthService,
        {
            provide: BLOB_STORAGE_TOKEN,
            multi: false,
            useFactory: azureBlobStorageFactory,
        },
        TeamsService,
        GraphService,
        FlowService,
    ],
    exports: [Share2TeamsComponent]
})
export class ShareToTeamsModule {}
