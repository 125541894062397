import * as microsoftTeams from '@microsoft/teams-js';

import { AuthService, TEAMS_SIGNIN_MODAL } from '@vantage-platform/auth';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { delay, filter, take, tap } from 'rxjs/operators';
import { lastValueFrom, pipe } from 'rxjs';

import { TeamsContextService } from '@vantage-platform/share-to-teams';
import { environment } from './../../../../environments/environment.qa';
import { timeout } from 'rxjs-compat/operator/timeout';

@Component({
  selector: 'vp-teams-login-modal',
  templateUrl: './teams-login-modal.component.html',
  styleUrls: ['./teams-login-modal.component.scss'],
})
export class TeamsLoginModalComponent implements OnInit {
  constructor(
    public auth: AuthService,
    private msal: MsalService,
    private msalBroadcast: MsalBroadcastService,
    public ctx: TeamsContextService
  ) {}

  ngOnInit() {
    window.name = TEAMS_SIGNIN_MODAL;
    if (
      window.location.search.indexOf('action=logout') > -1 &&
      this.auth.isLoggedIn
    ) {
        lastValueFrom(
          this.msal.logoutRedirect({
            authority: environment.aad_config.authority,
            postLogoutRedirectUri:
              '/login?action=logoutredirect',
            onRedirectNavigate: (url) => {
              return true;
            },
            extraQueryParameters: {
              action: 'logoutredirect',
            },
          })
        ).then(() => {
          microsoftTeams.authentication.notifySuccess();
        });
    } else if (window.location.search.indexOf('action=logoutredirect') > -1) {
      microsoftTeams.authentication.notifySuccess();
    } else if (this.auth.isLoggedIn) {
      this.handleSignedIn();
    } else {
      this.msalBroadcast.inProgress$.pipe(take(1)).subscribe((status) => {
        if (status != InteractionStatus.HandleRedirect) {
          this.msal.loginRedirect();
        }
        this.msal
          .handleRedirectObservable().pipe(filter((result) => {
            return !!result
          }))
          .subscribe({
            next: (result: AuthenticationResult) => {
              console.error('result called', result);
              if (result) {
                this.handleSignedIn(result);
              }
            },
            error: (error) => this.handleSignedOut(error),
          })
      });
    }
  }

  private handleSignedIn(response?) {
      this.ctx.msTeams.authentication.notifySuccess();
  }

  private handleSignedOut(error) {
    this.ctx.msTeams.authentication.notifyFailure(error);
  }
}
