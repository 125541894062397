import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationService } from './toaster/notification.service';
import { ToasterComponent } from './toaster/toaster.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    declarations: [ToasterComponent],
    imports: [
        CommonModule,
        ToastrModule.forRoot({
            closeButton: false,
            toastComponent: ToasterComponent
        })
    ],
    providers: [NotificationService],
    exports: [ToasterComponent]
})
export class ToasterModule {}
