import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  AppState,
  IApp,
  ISideNavItemModel,
  SidenavState,
  categories,
  detectApp,
  getLoaded,
  getStore,
  setAppLoading,
  sidenav,
} from '@vantage-platform/store';
import { Store, select } from '@ngrx/store';
import { filter, first, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AppResolver  {
  constructor(private store: Store<AppState>, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IApp> | Promise<any> {
    // raise app detection based on URL route
    this.store.next(setAppLoading({ appLoading: true }));
    this.store
      .pipe(
        select(getLoaded),
        filter((r) => {
          return r;
        }),
        first()
      )
      .subscribe((r) => {
        // TODO: check if needs cancellation when resolver called again immediately
        this.store.dispatch(detectApp(route.routeConfig.path));
      });

    const store = this.store.pipe(
      select(getStore),
      filter((store) => {
        return store.app && store.appLoaded;
      }),
      first()
    );
    // wait for selectors to be sure that data for aplication page loaded
    // return app data only
    return store
      .pipe(
        map((store) => {
          this.checkCategories(
            store.app,
            store.categories,
            store.sidenav,
            route
          );
          return { ...store.app, instanceRef: store.instance};
        })
      )
      .toPromise();
  }

  // check user categories - remove categories without permissions from url
  private checkCategories(
    appState: IApp,
    categories: ISideNavItemModel[],
    sidenav: SidenavState,
    route: ActivatedRouteSnapshot
  ) {
    const areas = route.queryParams['area'] || [];
    const ids = (Array.isArray(areas) ? areas : [areas]).map((p) => +p);
    const allowedCategories = categories.map((r) => r.categoryID);

    const deniedCategories = ids.filter((i) => !allowedCategories.includes(i));

    if (deniedCategories.length) {
      const newCategories = ids.filter((i) => allowedCategories.includes(i));
      const url = `/${appState.instanceRef.id}${sidenav.path}`;

      this.router.navigate([url], {
        queryParams: {
          ...route.queryParams,
          area: newCategories,
        },
        replaceUrl: true,
      });
    }
  }
}
