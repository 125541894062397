import { Pipe, PipeTransform } from '@angular/core';

import { formatNumber } from '@angular/common';

@Pipe({
  name: 'customThousandSeparator',
})
export class CustomThousandSeparatorPipe implements PipeTransform {
  transform(value: any, seperator: string = ' '): unknown {
    const convertedValue = Number(value);

    if (isNaN(value) || value === ' ') {
      return value;
    }

    const commaSeperated = formatNumber(convertedValue, 'en-ZA');
    const formattedValue = commaSeperated.replace(/,/g, seperator);
    return formattedValue;
  }
}
