<button
  placement="left top"
  [adaptivePosition]="false"
  [popover]="addToCollection"
  container="body"
  containerClass="v4-add-2-collections-popup"
  class="text round icon-btn add-to-collection-btn-mobile"
  #addToCollectionTrigger="bs-popover"
  [ngClass]="{ active: card.myFocusBoards.length !== 0 }"
>
  <span
    class="icon"
    [ngClass]="{
      'icon-bookmark_border': !card.myFocusBoards.length,
      'icon-bookmark': card.myFocusBoards.length
    }"
  ></span>
  <span class="button-text">Add to Collection</span>
</button>

<ng-template #addToCollection>
  <div class="v4-add-2-collections-header">
    <h4 class="v4-add-2-collections-title">Add to Collection</h4>
    <button
      aria-label="Close"
      class="text"
      (click)="addToCollectionTrigger.hide()"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <ng-container *ngIf="(cardsService.$user_collections | async).length !== 0">
    <div class="v4-add-2-collections-body" [perfectScrollbar]>
      <div class="v4-add-2-collections-data">
        <h5 class="v4-add-2-collections-data-header">
          In existing collections
        </h5>
        <div
          class="v4-add-2-collections-data-item"
          *ngFor="
            let item of cardsService.$user_collections
              | async
              | filterInclude: card.myFocusBoards
          "
        >
          <span>{{ item.name }}</span>
          <button class="text" (click)="unPinCard(item.id)">
            Remove
          </button>
        </div>
        <h5 class="v4-add-2-collections-data-header"></h5>
        <div
          class="v4-add-2-collections-data-item"
          *ngFor="
            let item of cardsService.$user_collections
              | async
              | filterExclude: card.myFocusBoards
          "
        >
          <span>{{ item.name }}</span>
          <button class="text" (click)="pinCard(item.id)">
            Save
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <div
    class="v4-add-2-collections-no-data"
    *ngIf="(cardsService.$user_collections | async).length === 0"
  >
    <div>
      <span class="icon icon-bookmark_border"></span>
    </div>
    <div>Get started by creating your first collection</div>
  </div>
  <div class="v4-add-2-collections-footer">
    <button class="w-100" (click)="cardsService.createCollection()">
      <span class="icon icon-add_box"> </span>Create new Collection
    </button>
  </div>
</ng-template>
