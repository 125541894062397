import {
  APPS,
  AppState,
  IApp,
  IInstance,
  SidenavState,
  getCurrentApp,
  instance,
  instances,
  loadStoreSuccess
} from '@vantage-platform/store';
import { APPS_TOUR_MAP, ITour, TourDeviceType } from '../on-boarding/steps';
import {
  BehaviorSubject,
  NEVER,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  of
} from 'rxjs';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { distinctUntilChanged, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { AuthService } from '@vantage-platform/auth';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BsModalService } from 'ngx-bootstrap/modal';
import { InsightService } from '@vantage-platform/app-insight';
import { NotSupportedViewportComponent } from '../not-supported-viewport/not-supported-viewport.component';
import { OnBoardingService } from '../on-boarding/on-boarding.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TeamsContextService } from '@vantage-platform/share-to-teams';

@Component({
  selector: 'vp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() appVersion: string;
  @Input() showInstanceSelector = true;
  public ver = '';
  public profile: any = {};
  destroy$ = new Subject<void>();
  public $tourAvailable: Observable<boolean>;
  readonly tour$ = new BehaviorSubject<() => ITour>(null);
  public instances$ = new BehaviorSubject<IInstance[]>([]);
  public selectedInstance$ = new ReplaySubject<IInstance>(1);
  readonly appSideNav$ = new ReplaySubject<SidenavState>();
  readonly app$ = new ReplaySubject<IApp>(1);

  constructor(
    public tourService: OnBoardingService,
    public auth: AuthService,
    public store: Store<AppState>,
    private track: InsightService,
    private router: Router,
    private ctx: TeamsContextService,
    private breakpoints: BreakpointObserver,
    private bsModalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.ver = this.appVersion;
    if (this.auth.isLoggedIn) {
      const { family_name, given_name, name, emails } = this.auth.user
        .idTokenClaims as any;
      const initials = `${given_name.substring(0, 1)}${family_name.substring(
        0,
        1
      )}`.toUpperCase();
      this.profile = {
        avatar: ``,
        initials,
        name,
        email: emails[0]
      };
    }

    this.store
      .select(getCurrentApp)
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.app$);
    this.app$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        switchMap((res) => {
          if (res && res.id) {
            const tourFunction: (deviceType: TourDeviceType) => ITour = APPS_TOUR_MAP.get(APPS[res.name.toLowerCase()]);

            if (!tourFunction) return of(null);

            return of(() =>
                tourFunction
                  ? tourFunction(
                      this.breakpoints.isMatched('(max-width: 576px)')
                        ? { deviceType: 'mobile' }
                        : {
                            deviceType: this.breakpoints.isMatched([
                              '(min-width: 576px) and (max-width: 839.98px) and (orientation: portrait)',
                              '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
                            ])
                              ? 'tablet'
                              : 'desktop',
                            profilesSearchContainerType: this.breakpoints.isMatched(
                              `(max-width: 68em)`
                            )
                              ? 'mobile'
                              : 'desktop',
                          }
                    )
                  : null
              );
          }
          return NEVER;
        })
      )
      .subscribe(this.tour$);
    this.$tourAvailable = combineLatest([this.tour$, this.app$]).pipe(
      takeUntil(this.destroy$),
      map(([v, tour]) => {
        return !!v && !!tour;
      })
    );

    this.store
      .select(instance)
      .pipe(
        takeUntil(this.destroy$),
        map((i) => ({ ...i }))
      )
      .subscribe(this.selectedInstance$);

    this.store
      .select(instances)
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.instances$);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  openGuideTourModal() {
    if (
      this.breakpoints.isMatched([
        '(orientation: landscape) and (max-height: 500px)',
      ])
    ) {
      this.showNotSupportedViewportNotification();
      return;
    }
    this.track.event('startTour');
    this.tour$.pipe(take(1)).subscribe(getTour => this.tourService.startTourFlow(getTour()));
  }

  showNotSupportedViewportNotification() {
    this.bsModalService.show(NotSupportedViewportComponent, {
      class: 'not-supported-orientation-modal'
    });
  }

  instanceSelect(e: number) {
    this.instances$.pipe(take(1)).subscribe((loadedInstances) => {
      if (!loadedInstances && loadedInstances.length === 0) return;

      this.track.event('instanceChange', {
        newInstanceId: e,
        newInstanceName: loadedInstances.find((i) => i.id === e).name
      });
    });

    this.store.dispatch(
      loadStoreSuccess({
        payload: {
          loaded: false,
          appLoaded: false,
          appLoading: true,
          app: undefined,
          apps: []
        }
      })
    );
    this.router.navigateByUrl(`${e}`);
  }

  logOut() {
     this.auth.logOut();
  }
}
