import { Component, OnInit } from '@angular/core';
import * as microsoftTeams from "@microsoft/teams-js";

@Component({
  selector: 'vp-teams-config-tab',
  templateUrl: './teams-config-tab.component.html',
  styleUrls: ['./teams-config-tab.component.scss']
})
export class TeamsConfigTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    microsoftTeams.app.initialize().then(()=> {

      microsoftTeams.app.getContext().then((context)  => {
        microsoftTeams.pages.config.setValidityState(true);
        microsoftTeams.app.notifySuccess();
      });

      microsoftTeams.pages.config.registerOnSaveHandler((saveEvent: microsoftTeams.settings.SaveEvent) => {
        // Calculate host dynamically to enable local debugging
        let host = "https://" + window.location.host;
  
        microsoftTeams.pages.config.setConfig({
          contentUrl: host + "/login?context=teams",
          websiteUrl: host,
          suggestedDisplayName: "Vantage",
          removeUrl: host + "/teamsremovetab",
          entityId: "VantageApp"
        });
        saveEvent.notifySuccess();
      });
      // microsoftTeams.pages.config.setValidityState(true);
    });
  }
}
