<div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <ng-container *ngFor="let col of data.columns; let colIndex = index">
          <ng-container
            *ngTemplateOutlet="
              col.main ? headerFirstColumn : headerColumn;
              context: { colData: col }
            "
          >
          </ng-container>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of data.rows; let rowIndex = index">
        <ng-container *ngFor="let col of data.columns; let colIndex = index">
          <ng-container
            *ngTemplateOutlet="
              col.main ? bodyFirstColumn : bodyColumn;
              context: { colData: col, rowData: row }
            "
          >
          </ng-container>
        </ng-container>
      </tr>
      <!-- {this.data.rows.map((row) => (
        <tr>
          {this.data.columns.map((col) => {
            return this.getRowItem(row, col);
          })}
        </tr>
      ))} -->
    </tbody>
  </table>
</div>

<ng-template #headerFirstColumn let-colData="colData">
  <th scope="col" class="freeze visible">
    <span>{{ colData.label }}</span>
  </th>
  <th scope="col" class="freeze hidden">
    <span>{{ colData.label }}</span>
  </th>
</ng-template>

<ng-template #headerColumn let-colData="colData">
  <th scope="col" >
    <span [class]="headerExtraClass">{{ colData.label }}</span>
  </th>
</ng-template>

<ng-template #bodyFirstColumn let-colData="colData" let-rowData="rowData">
  <th scope="row" class="freeze visible">
    <span>{{ rowData.cells[colData.key].value }}</span>
  </th>
  <th scope="row" class="freeze hidden">
    <span>{{ rowData.cells[colData.key].value }}</span>
  </th>
</ng-template>

<ng-template #bodyColumn let-colData="colData" let-rowData="rowData">
  <td>
    <span
      [class.text-nowrap]="colData.config?.wrap == 'nowrap'"
      [class.text-wrap]="colData.config?.wrap == 'wrap'"
      [class.text-left]="colData.config?.alignment == 'left'"
      [class.text-center]="colData.config?.alignment == 'center'"
      [class.text-right]="colData.config?.alignment == 'right'"
      [style.color]="rowData.cells[colData.key].textColour"
      >{{ rowData.cells[colData.key].value }}</span
    >
  </td>
</ng-template>
