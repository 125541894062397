import * as microsoftTeams from '@microsoft/teams-js';

import { Injectable } from '@angular/core';
import { firstValueFrom, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeamsContextService {
  private _msTeams;
  private _context: microsoftTeams.app.Context;
  private _msTeamsInitialized: boolean = false;
  private _teamsInitPromise;

  get msTeams() {
    return this._msTeamsInitialized ? this._msTeams : null;
  }

  get context() {
    return this._context;
  }

  get isTeamsTab() {
    // tslint:disable-next-line: no-bitwise
    return typeof this._context !== 'undefined';
  }

  constructor() {
    this._msTeams = microsoftTeams;

  }

    public async init(): Promise<any> {
      if(!this._msTeamsInitialized) {
        try {
          await this.ensureTeamsSdkInitialized();
          const context = await microsoftTeams.app.getContext();
          this._msTeamsInitialized = true;
          this._context = context;
          return firstValueFrom(of(context.app.host.name === microsoftTeams.HostName.teams));
      }
      catch (e) {
          console.log(`${e} from Teams SDK, may be running outside of Teams`);    
          return firstValueFrom(of(false));
      }
    }
  }

  ensureTeamsSdkInitialized() {
      if (!this._teamsInitPromise) {
        this._teamsInitPromise = microsoftTeams.app.initialize();
      }
      return this._teamsInitPromise;
  }
}


