import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-om-perfect-scrollbar';

import { AssistantComponent } from './assistant/assistant.component';
import { AuthModule } from '@vantage-platform/auth';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { BottomSheetDirective } from './bottom-sheet/bottom-sheet.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollectionsCrudComponent } from './modals/collections-crud/collections-crud.component';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './modals/confirm/confirm.component';
import { ConfirmModalService } from './modals/confirm/confirm-modal.service';
import { DisableInstancePipe } from './helpers/pipes/disable-instance.pipe';
import { HeaderComponent } from './header/header.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotSupportedViewportComponent } from './not-supported-viewport/not-supported-viewport.component';
import { OnBoardingModule } from './on-boarding/on-boarding.module';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { RouterModule } from '@angular/router';
import { SideNavComponent } from './side-nav/side-nav.component';
import { SideNavService } from './side-nav/side-nav.service';
import { SortByPipe } from './utilits/sort-by.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { VPStoreModule } from '@vantage-platform/store';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    PerfectScrollbarModule,
    AuthModule,
    NgxSpinnerModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    OnBoardingModule,
    NgxSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    SideNavComponent,
    ProfileMenuComponent,
    HeaderComponent,
    LoadingScreenComponent,
    AssistantComponent,
    DisableInstancePipe,
    LoadingIndicatorComponent,
    BottomSheetComponent,
    BottomSheetDirective,
    NotSupportedViewportComponent,
    SortByPipe,
    CollectionsCrudComponent,
    ConfirmComponent,
  ],
  exports: [
    SideNavComponent,
    HeaderComponent,
    LoadingScreenComponent,
    AssistantComponent,
    LoadingIndicatorComponent,
    BottomSheetComponent,
    BottomSheetDirective,
    SortByPipe,
    ProfileMenuComponent,
    CollectionsCrudComponent,
    ConfirmComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    SideNavService,
    ConfirmModalService,
  ],
  entryComponents: [CollectionsCrudComponent, ConfirmComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CommonUiModule {}
