import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ICard, ICardConfig } from '../../models/i-card';

import { CardsService } from '../../cards/cards.service';
import { Router } from '@angular/router';
import {
  APPS,
  AppState,
  getApps,
  getCurrentApp,
  IInstance,
  instance,
} from '@vantage-platform/store';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { InsightService } from "@vantage-platform/app-insight";

@Component({
  selector: 'vantage-platform-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss'],
})
export class CardHeaderComponent implements OnInit {
  @Input() public config: ICardConfig;
  @Input() card: ICard;
  public geoDistrictLight: string;
  public geoDistrictDark: string;
  public geoDistrictFull: string;
  private instance: IInstance;
  private currentApp;
  private appsAvailable;
  constructor(
    public cardsService: CardsService,
    public element: ElementRef,
    private router: Router,
    private store: Store<AppState>,
    private track: InsightService,
  ) {}

  ngOnInit(): void {
    this.parseGeoDistricts();
    this.store.select(instance).subscribe((i) => (this.instance = i));
    this.store
      .select(getCurrentApp)
      .pipe(take(1))
      .subscribe((a) => (this.currentApp = a));
    this.store
      .select(getApps)
      .subscribe(
        (a) => (this.appsAvailable = a.filter((b) => b.isAppAvailable))
      );
  }

  private parseGeoDistricts() {
    const geoDistLength = 40;
    if (
      this.card.geoLocationHierarchy == null ||
      this.card.geoLocationHierarchy.length === 0
    ) {
      this.geoDistrictDark = this.card.geoLocation;
      this.geoDistrictFull = this.geoDistrictDark;
      return;
    }
    const geoLocations = [...this.card.geoLocationHierarchy].sort(
      (x) => x.hierachy
    );
    this.geoDistrictFull = geoLocations
      .map((g) => g.geoLocation)
      .reverse()
      .join(' / ');
    if (geoLocations.length > 1) {
      this.geoDistrictLight = '';
      for (let i = geoLocations.length - 1; i > 0; i--) {
        this.geoDistrictLight += `${geoLocations[i].geoLocation} / `;
      }
    }
    this.geoDistrictDark = geoLocations[0].geoLocation;
    if (this.geoDistrictLight && this.geoDistrictLight.length > 0) {
      if (
        this.geoDistrictLight.length + this.geoDistrictDark.length >
        geoDistLength
      ) {
        this.geoDistrictLight = `...${this.geoDistrictLight.substring(
          this.geoDistrictLight.length +
            this.geoDistrictDark.length -
            geoDistLength,
          this.geoDistrictLight.length
        )}`;
      }
    } else {
      if (this.geoDistrictDark.length > geoDistLength) {
        this.geoDistrictDark = `...${this.geoDistrictDark.substring(
          this.geoDistrictDark.length - geoDistLength,
          geoDistLength
        )}`;
      }
    }
  }

  navigateToProfiles(): void {
    const sortedGeolocations = this.getSortedGeolocations();
    this.track.event('viewCardInProfiles', {
      cardId: this.card.id,
      categoryId: this.card.categoryID,
      cardTitle: this.card.title,
      question: this.card.question,
      questionId: this.card.questionID,
      geolocationGeoLevel: this.card.geoLevel,
      geolocation: this.card.geoLocation,
      parentGeolocationGeoLevel: sortedGeolocations.length > 1 ? sortedGeolocations[sortedGeolocations.length - 1].geoLevel : null,
      parentGeolocation: sortedGeolocations.length > 1 ? sortedGeolocations[sortedGeolocations.length - 1].geoLocation : null,
    });
    this.router.navigateByUrl(this.getProfilesUrl());
  }

  displayNavigateToProfiles(): boolean {
    return (
      typeof this.card.geoLocationHierarchy !== 'undefined' &&
      this.isProfilesAvailable() &&
      this.isCurrentAppFocus()
    );
  }

  private getSortedGeolocations() {
    return [...this.card.geoLocationHierarchy].sort(
      (x) => x.hierachy
    );
  }

  private getProfilesUrl(): string {
    const geoLocations = this.getSortedGeolocations();

    let url = `/${this.instance.id}/profiles?gl=${this.card.geoLocation}`;

    if (geoLocations.length > 0)
      url += `&pgl=${geoLocations[geoLocations.length - 1].geoLocation}`;

    return url;
  }

  private isProfilesAvailable(): boolean {
    return this.appsAvailable.some(
      (apps) => apps.name.toLowerCase() === APPS.profiles
    );
  }

  private isCurrentAppFocus(): boolean {
    return this.currentApp?.name?.toLowerCase() === APPS.focus;
  }
}
