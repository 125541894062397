<div class="top-icon">
  <div class="icon-screen_rotation"></div>
</div>
<div class="message-text">
  <h3>Change Orientation</h3>
  <p>In order to view the Vantage tour please rotate your device back to portrait mode.</p>
</div>
<div class="btn-block">
  <button (click)="close()">GOT IT</button>
</div>

