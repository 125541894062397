import { Component, OnInit } from '@angular/core';

import { CARDS_CONFIG } from '@vantage-platform/cards-ui';
import { ICard } from '@vantage-platform/store';

@Component({
  templateUrl: './share-card.component.html',
  styleUrls: ['./share-card.component.scss'],
})
export class ShareCardComponent implements OnInit {
  card: ICard;

  constructor() {
    const exportData = window['export'];
    this.card = (typeof exportData === 'string'
      ? JSON.parse(exportData)
      : exportData
    ).buckets.card;
  }

  ngOnInit(): void {
    window['exportReady'] = true;
    window.dispatchEvent(new Event('export.ready'));
  }

  getCardConfig(card: ICard) {
    if (card.appID) {
      return CARDS_CONFIG[card.appID](card);
    }
  }
}
