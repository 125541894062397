<apx-chart
  [states]="chartOptions.states"
  [tooltip]="chartOptions.tooltip"
  [series]="chartOptions.series"
  [chart]="chartOptions.chart"
  [plotOptions]="chartOptions.plotOptions"
  [dataLabels]="chartOptions.dataLabels"
  [grid]="chartOptions.grid"
  [yaxis]="chartOptions.yaxis"
  [xaxis]="chartOptions.xaxis"
  [colors]="chartOptions.colors"
  [legend]="chartOptions.legend"
  [markers]="chartOptions.markers"
  [fill]="chartOptions.fill"
  isDisabled="true"
></apx-chart>
