<div class="vp-card-header">
  <div class="vp-card-header-inner">
    <div
      class="header-indicator"
      [ngClass]="'header-indicator-' + config.indicatorClass"
    >
      <span class="icon" [ngClass]="'icon-' + card.iconName"></span>
    </div>
    <div class="card-title card-title-desktop">{{ card.title }}</div>
    <div
      class="card-district"
      [tooltip]="this.geoDistrictFull"
      container="body"
      placement="top"
      containerClass="nowrap"
    >
      <span class="vp-card-header-geodistrictlight">
        {{ this.geoDistrictLight }}
      </span>
      <span class="vp-card-header-geodistrictdark">
        {{ this.geoDistrictDark }}
      </span>
    </div>

    <div class="vp-card-header-period" *ngIf="card.period">
      {{ card.period }}
    </div>
    <div class="header-buttons-container">
      <ng-container *ngIf="displayNavigateToProfiles()">
        <button class="text round icon-btn profiles-btn"
          (click)="navigateToProfiles()"
        >
          <span class="icon icon-product_profiles"></span>
        </button>
      </ng-container>
      <vantage-platform-add-to-collection
        *ngIf="card.bookmark"
        [card]="card"
      ></vantage-platform-add-to-collection>
      <button
        *ngIf="card.upload"
        class="text round icon-btn card-share-2-teams"
        (click)="
          cardsService.share2teams(element.nativeElement.offsetParent, card)
        "
      >
        <span class="icon icon-share"></span>
      </button>
    </div>
    <button
      class="text round icon-btn show-mobile-menu-btn"
      [vpBottomSheet]="bottomSheetTemplateRef"
    >
      <span class="icon icon-move_vertical"></span>
    </button>
    <ng-template #bottomSheetTemplateRef>
      <ng-container *ngIf="card.upload">
        <button
          class="text bottom-sheet-menu-item card-share-2-teams"
          (click)="
            cardsService.share2teams(element.nativeElement.offsetParent, card)
          "
        >
          <span class="icon icon-share"></span>
          <span class="button-text">Share</span>
        </button>
      </ng-container>
      <vantage-platform-add-to-collection
        class="mobile"
        *ngIf="card.bookmark"
        [card]="card"
      ></vantage-platform-add-to-collection>
    </ng-template>
  </div>

  <div class="card-title card-title-mobile">{{ card.title }}</div>
</div>
