import { Injectable } from '@angular/core';
import { ITour } from './steps';
import { AppState, IInstance } from '@vantage-platform/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export abstract class OnBoardingService {
  instance: IInstance;
  categories: any;
  focusBoard: any;
  appCollections: any;
  modalService: BsModalService;
  store: Store<AppState>;
  abstract showOnBoardingTour(tour: ITour);
  abstract startTourFlow(tour: ITour);
  abstract isDefaultBoard();
}
