import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppState, resetLoadState } from '@vantage-platform/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConfigService } from '@vantage-platform/store/lib/services/config.service';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingIndicatorService } from '@vantage-platform/store/lib/services/loading-indicator/loading-indicator.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class InstanceGuard  {
  constructor(
    private config: ConfigService,
    private router: Router,
    private loadingIndicatorService: LoadingIndicatorService,
    private store: Store<AppState>
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store.next(resetLoadState());
    this.loadingIndicatorService.show();

    const instance =
      next.firstChild?.params['instance'] || next.params['instance'];

    if (!instance) {
      return false;
    }

    return this.config.validateAccess({ instanceId: instance }).pipe(
      map((r: HttpResponse<any>) => {
        if (r.status === 403) {
          return this.router.createUrlTree(['']);
        }

        return r.body['accessAllowed'];
      }),
      catchError(() => {
        //By default redirect to host,
        // based on logim will be selected assigned to user instance
        window.location.href = '';

        //Catch 403 error when user have no access to the instance or instance id missing
        return of(false);
      })
    );
  }
}
