import { ITour } from './i-tour';
import {
  builtInButtons,
  TourDeviceType,
  triggerCardActionsModalByState,
  triggerSideMenuDesktopByState,
  triggerSideMenuMobileByState
} from './default-step-options';
import shepherd from 'shepherd.js';

// if at least one of predicates returns false then total result should false
function combinePredicates(predicates: (() => boolean)[]): () => boolean {
  return () => {
    debugger;
    return predicates.every(p => p());
  };
}

function checkStepDomElementExistence(step: shepherd.Step.StepOptions): () => boolean {
  return (): boolean => {
    let elOrSelector = typeof step.attachTo === 'string' ? step.attachTo : step.attachTo.element
    if (typeof elOrSelector === 'string') {
      elOrSelector = document.querySelector(elOrSelector) as HTMLElement;
    }
    if (!!elOrSelector) {
      console.log('el exists: ', step.attachTo)
    } else {
      console.log('el does not exist: ', step.attachTo)
    }
    return !!elOrSelector;
  }
}

function patchShowOnForSteps(steps: shepherd.Step.StepOptions[]): shepherd.Step.StepOptions[] {

  return steps.map(step => {
    return {
      ...step,
      showOn: step.showOn
        ? combinePredicates([step.showOn, checkStepDomElementExistence(step)])
        : checkStepDomElementExistence(step),
    } as shepherd.Step.StepOptions
  })
}

function patchLastStepButtons(steps: shepherd.Step.StepOptions[]): shepherd.Step.StepOptions[] {
  const _steps = steps, lastStep = _steps[_steps.length - 1], restOfSteps = _steps.splice(0, _steps.length - 1);
  const lastStepBtns = [...lastStep.buttons];

  lastStepBtns.filter(b => b === builtInButtons.next);
  if (!lastStepBtns.includes(builtInButtons.cancel)) {
    lastStepBtns.push(builtInButtons.cancel);
  }

  return [
    ...restOfSteps,
    {
      ...lastStep,
      buttons: lastStepBtns,
    }
  ]
}

const triggerProfilesCardsOrMap = () => {
  return ({platformRequiresToOpenCards, shouldOpen}) =>
    new Promise((resolve: (val?: unknown) => void) => {
      if (platformRequiresToOpenCards) {
        // if side menu is hidden
        const areCardsClosed =
          !document.querySelector(`#mainContainer.map-view`)?.classList?.contains('info-view');
        if (areCardsClosed === shouldOpen) {
          const el = document.querySelector('#switchView');
          (el as any)?.click();
          setTimeout(() => resolve(), 500);
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });

};

export const profilesSteps = (
  deviceType: TourDeviceType
): shepherd.Step.StepOptions[] => {
  const mobileSearchContainerUsed = deviceType.deviceType === 'mobile' || deviceType.profilesSearchContainerType === 'mobile';
  const platformRequiresToOpenCardActionsModal = deviceType.deviceType === 'mobile';
  const onlyVerticalAlignment = deviceType.deviceType === 'mobile';
  const platformRequiresToOpenSideNav = deviceType.deviceType === 'mobile' || deviceType.profilesSearchContainerType === 'mobile';

  const hasVantageAssistant = !!document.querySelector('.assistant-fab-button');

  const _preparations = (x: {
    shouldOpenCards: boolean;
    shouldOpenCardsMenu: boolean;
    shouldOpenSideNav: boolean; // open sidenav on mobile devices
    requiresScrollTop?: true;
  }) => Promise.all([
      triggerSideMenuMobileByState({platformRequiresToOpenSideNav, shouldOpenSideNav: x.shouldOpenSideNav})()
        .then(triggerSideMenuDesktopByState({shouldOpen: !platformRequiresToOpenSideNav})),
      triggerCardActionsModalByState({shouldOpenCardsMenu: x.shouldOpenCardsMenu, platformRequiresToOpenCardActionsModal})(),
      triggerProfilesCardsOrMap()({
        platformRequiresToOpenCards: mobileSearchContainerUsed,
        shouldOpen: x.shouldOpenCards
      })
    ]).then(async () => {
      if ('requiresScrollTop' in x) {
        const cardsScrollContainer = document.querySelector('.cards-wrapper');
        cardsScrollContainer.scrollTop = 0;
        await new Promise((resolve) => setTimeout(resolve, 300)); // 250ms is header animation
      }
    });

  return [
    {
      attachTo: {
        element: '#map',
      },
      beforeShowPromise: () =>
        _preparations({
          shouldOpenCards: false,
          shouldOpenCardsMenu: false,
          shouldOpenSideNav: false,
          requiresScrollTop: true,
        }),
      scrollTo: false,
      popperOptions: {
        modal: false,
      },
      buttons: [builtInButtons.next],
      id: 'defaultLandingScreen',
      title: 'Default Landing Screen',
      text:
        'The data visualized on the map and Insight Cards are influenced by the selected Indicators. Simply hover over the the map to view the relevant data per geo-location.',
    } as shepherd.Step.StepOptions,
    {
      attachTo: {
        element: '#map',
      },
      beforeShowPromise: () =>
        _preparations({ shouldOpenCards: false, shouldOpenCardsMenu: false, shouldOpenSideNav: false, requiresScrollTop: true }),
      buttons: [builtInButtons.back, builtInButtons.next],
      id: 'navigateMap',
      title: 'Navigate Map',
      text:
        'To focus on a geo-location double-click the area, or click on the name.',
    } as shepherd.Step.StepOptions,
    {
      attachTo: {
        element: `#searchContainer${mobileSearchContainerUsed ? '.mobile-only' : '.desktop-only'}`,
        on: 'top',
      },
      beforeShowPromise: () => {
        const a = mobileSearchContainerUsed;
        return _preparations({ shouldOpenCards: false, shouldOpenCardsMenu: false, shouldOpenSideNav: false });
      },
      buttons: [builtInButtons.back, builtInButtons.next],
      highlightClass: 'visible',
      id: 'searchAvailableIndicators',
      title: 'Search Available Indicators',
      text: `The default Indicator is represented on the map as a heat map.
      The second Indicator is shown as circles. Add, delete or drag to reorder
       Indicators for different data views.`,
    } as shepherd.Step.StepOptions,

    {
      attachTo: {
        element:
          'div.ps-content > nav:nth-child(1) > ul.bottom-line-devider+ul > li:nth-child(1)',
        on: onlyVerticalAlignment ? 'bottom-start' : 'right-start',
      },
      beforeShowPromise: () =>
        _preparations({
          shouldOpenCards: false,
          shouldOpenCardsMenu: false,
          shouldOpenSideNav: true,
        }),
      buttons: [builtInButtons.back, builtInButtons.next],
      id: 'informationDomains',
      title: 'Information Domains',
      text:
        'Filter the Insight cards by selecting a Information Domain. Available ' +
        'indicators from that domain will be displayed within the search.',
    } as shepherd.Step.StepOptions,

    {
      attachTo: {
        element:
          'div.ps-content > nav:nth-child(1) > ul.nav-title-wrapper > li:nth-child(1)',
        on: onlyVerticalAlignment ? 'bottom-start' : 'right-start',
      },
      beforeShowPromise: () =>
        _preparations({
          shouldOpenCards: false,
          shouldOpenCardsMenu: false,
          shouldOpenSideNav: true,
        }),
      buttons: [builtInButtons.back, builtInButtons.next],
      id: 'multipleInformationDomains',
      title: 'Select Multiple Information Domains',
      text: 'To view multiple Information Domais at once, you can switch the ' +
        'multiple toggle on and simply select the Information Domains you ' +
        'would like displayed.',
    } as shepherd.Step.StepOptions,

    {
      attachTo: {
        element: '.static-content-wrapper .nav-item.create-collection',
        on: 'top-start',
      },
      beforeShowPromise: () =>
        _preparations({ shouldOpenCards: false, shouldOpenCardsMenu: false, shouldOpenSideNav: true }),
      scrollTo: true,
      buttons: [builtInButtons.back, builtInButtons.next],
      highlightClass: 'hover-effect-btn',
      id: 'createCollection',
      title: 'Create a Collection',
      text:
        'Creating a Collection is a great way to group a set of Insight Cards for a specific meeting or analysis. Your Collections will be saved in the navigation panel.',
    } as shepherd.Step.StepOptions,

    {
      attachTo: {
        element: 'vantage-platform-card vantage-platform-question, vantage-platform-empty, vp-no-profiles-cards',
        on: 'top',
      },
      beforeShowPromise: () =>
        _preparations({
          shouldOpenCards: true,
          shouldOpenCardsMenu: false,
          shouldOpenSideNav: false,
        }),
      scrollTo: mobileSearchContainerUsed,
      buttons: [builtInButtons.back, builtInButtons.next],
      id: 'insightCards',
      title: 'Insight Cards',
      text:
        'Discover new Insight Cards. These are prioritised based on the' +
        ' order of indicators in the search field.',
    } as shepherd.Step.StepOptions,

    ...(platformRequiresToOpenCardActionsModal
      ? [
        {
          attachTo: {
            element: '.show-mobile-menu-btn',
            on: onlyVerticalAlignment ? 'bottom-end' : 'right-end',
          },
          beforeShowPromise: () =>
            _preparations({
              shouldOpenCards: true,
              shouldOpenCardsMenu: false,
              shouldOpenSideNav: false,
              requiresScrollTop: true,
            }),
          scrollTo: true,
          buttons: [builtInButtons.back, builtInButtons.next],
          highlightClass: 'hover-effect-icon-btn',
          id: 'cardActionsMenu',
          title: `Cards's actions menu`,
          text: 'You can add cards to collections and share using this menu',
        } as shepherd.Step.StepOptions,

        {
          attachTo: {
            element: 'vantage-platform-add-to-collection.mobile>button',
            on: onlyVerticalAlignment ? 'top-end' : 'right-end',
          },
          beforeShowPromise: () =>
            _preparations({
              shouldOpenCards: true,
              shouldOpenCardsMenu: true,
              shouldOpenSideNav: false,
            }),
          buttons: [builtInButtons.back, builtInButtons.next],
          highlightClass: 'hover-effect-icon-btn',
          id: 'addToCollection',
          title: 'Add Card to Collection',
          text:
            'You can add an Insight Card to a Collection, there is no limit.',
        } as shepherd.Step.StepOptions
      ]
      : [
        {
          attachTo: {
            element: 'vantage-platform-add-to-collection>button',
            on: onlyVerticalAlignment ? 'bottom-end' : 'right-end',
          },
          beforeShowPromise: () =>
            _preparations({
              shouldOpenCards: false,
              shouldOpenCardsMenu: false,
              shouldOpenSideNav: false,
            }),
          buttons: [builtInButtons.back, builtInButtons.next],
          highlightClass: 'hover-effect-icon-btn',
          id: 'addToCollection',
          title: 'Add Card to Collection',
          text:
            'You can add an Insight Card to a Collection, there is no limit.',
        } as shepherd.Step.StepOptions
      ]),

    {
      attachTo: {
        element: platformRequiresToOpenCardActionsModal
          ? 'button.bottom-sheet-menu-item.card-share-2-teams'
          : 'button.card-share-2-teams',
        on: 'bottom-end',
      },
      beforeShowPromise: () =>
        _preparations({
          shouldOpenCards: true,
          shouldOpenCardsMenu: true,
          shouldOpenSideNav: false,
        }),
      buttons: [builtInButtons.back, builtInButtons.next],
      highlightClass: 'hover-effect-icon-btn',
      id: 'shareCard',
      title: 'Share Insight Card',
      text:
        'Share Insight Cards directly to your preferred teams or team members in Microsoft Teams.',
    } as shepherd.Step.StepOptions,

    {
      attachTo: {
        element:
          'vp-side-nav > div.side-menu > div.side-menu-header > button.text.menu-btn > span',
        on: onlyVerticalAlignment ? 'bottom' : 'left',
      },
      beforeShowPromise: () =>
        _preparations({
          shouldOpenCards: false,
          shouldOpenSideNav: true,
          shouldOpenCardsMenu: false,
        }),
      buttons: [builtInButtons.back, hasVantageAssistant ? builtInButtons.next : builtInButtons.cancel],
      highlightClass: 'hover-effect-btn',
      id: 'appsSelector',
      title: 'Vantage Product Suite',
      text: 'Access your full Vantage Product Suite here.',
    } as shepherd.Step.StepOptions,

    ...(hasVantageAssistant ? [{
      attachTo: {
        element: '.assistant-fab-button',
        on: onlyVerticalAlignment ? 'top' : 'right-end',
      },
      beforeShowPromise: () =>
        _preparations({
          shouldOpenCards: false,
          shouldOpenCardsMenu: false,
          shouldOpenSideNav: false
        }),
      buttons: [builtInButtons.back, builtInButtons.cancel],
      id: 'assistant',
      title: 'Ask the Vantage Assistant',
      text:
        'Have a program-related question? Ask Vantage Assistant to guide you to find the right answer.',
    } as shepherd.Step.StepOptions] : []),
  ] as shepherd.Step.StepOptions[];
};

export const profilesTour = (deviceType: TourDeviceType): ITour => ({
  welcomeMsg: 'Welcome to Vantage Profiles',
  tourDescription:
    'Learn more about the environment in which your programme operates.',
  path: 'profiles',
  icon: 'product_profiles',
  steps: patchLastStepButtons(patchShowOnForSteps(profilesSteps(deviceType))),
});
