import {
  BoardState,
  CARDSUISTATE_FEATURE_KEY,
  CardsUiState,
} from './cards-ui-state.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IParams } from '@vantage-platform/store';

// Lookup the 'CardsUiState' feature state managed by NgRx
export const getCardsUiFeatureState = createFeatureSelector<CardsUiState>(
  CARDSUISTATE_FEATURE_KEY
);

export const getCardsUiState = createSelector(
  getCardsUiFeatureState,
  (state: CardsUiState) => state
);

export const getLoaded = createSelector(
  getCardsUiFeatureState,
  (state: CardsUiState) => state.loaded
);

export const getAllCardsItems = createSelector(
  getCardsUiFeatureState,
  getLoaded,
  (state: CardsUiState, isLoaded) => {
    return state.cards;
  }
);

export const getCardsItem = createSelector(
  getCardsUiFeatureState,
  (state: CardsUiState, props) => {
    return state.cards.find((c) => c.id === props.cardId);
  }
);

export const getFocusCardsItem = createSelector(
  getCardsUiFeatureState,
  (state: CardsUiState) => {
    return state.cards.find((c) => c.isFocusCard);
  }
);

export const isFocusCardExists = createSelector(
  getCardsUiFeatureState,
  (state: CardsUiState) => {
    return state.cards.some((c) => c.isFocusCard);
  }
);

export const getDynamicLocationName = createSelector(
  getCardsUiFeatureState,
  (state: CardsUiState) => {
    return state.dynamicLocationName;
  }
);

export const getBoardId = createSelector(
  getCardsUiFeatureState,
  (state: CardsUiState) => state.boardId
);

export const getIsBoardEmpty = createSelector(
  getCardsUiFeatureState,
  (state: CardsUiState) => state.boardEmpty
);

export const getIsLoading = createSelector(
  getCardsUiFeatureState,
  (state: CardsUiState) => state.isLoading
);

export const getEndOfPage = createSelector(
  getCardsUiFeatureState,
  (state: CardsUiState) => state.endOfPage && !!~state.cards.length
);

export const getBoardDetails = createSelector(
  getCardsUiFeatureState,
  (state: CardsUiState): BoardState => {
    return {
      loaded: state.loaded,
      isLoading: state.isLoading,
      boardEmpty: state.boardEmpty,
      params: state.params,
      boardId: state.boardId,
    };
  }
);

export const getParams = createSelector(
  getCardsUiFeatureState,
  (state: CardsUiState) => state.params
);

export const getApiParams = createSelector(
  getParams,
  getBoardId,
  (params: IParams, boardId: number) => {
    return {
      params,
      boardId,
    };
  }
);

export const isBoardReady = createSelector(
  getLoaded,
  getAllCardsItems,
  getCardsUiState,
  (isLoaded, cards, state) => {
    if (isLoaded && cards[0]?.rows) {
      return true;
    }
  }
);
