export const CARDS_API = {
  url: 'https://vantage-qa.azure-api.net/vantage/boards/v4/',
  key: '9378d4555edb4170b15cd502685b0122',
};

export const DATA_API = {
  url: 'https://vantage-qa.azure-api.net/data/v1/',
  key: 'a33d85cca1ab435ab9064c7748a5d7b5',
};

export const DATA_API_V4 = {
  url: 'https://vantage-qa.azure-api.net/data/v4/',
};

export const CONFIG_API = {
  url: 'https://vantage-qa.azure-api.net/vantage/config/v1/',
};

export const PROFILES_API = {
  url: 'https://vantage-qa.azure-api.net/vantage/profiles/v1/',
};

export const MATCHING_API = {
  url: 'https://vantage-qa.azure-api.net/matching-engine/v1/',
};

export const SI_STAGING_API = {
  url: 'https://vantage-qa.azure-api.net/staging/v1/',
};

export const SI_DATA_UPLOAD_API = {
  url: 'https://vantage-qa.azure-api.net/si/storage/v1/',
};

export const APIS = [
  CARDS_API,
  DATA_API,
  DATA_API_V4,
  CONFIG_API,
  PROFILES_API,
  SI_STAGING_API,
  SI_DATA_UPLOAD_API,
  MATCHING_API,
];

export const aad_access_scopes = [
  'https://VantageAadB2cQA.onmicrosoft.com/card-service/user_impersonation',
];

export const aad_profiles_scopes = [
  'https://VantageAadB2cQA.onmicrosoft.com/profiles/user_impersonation',
];

export const aad_si_staging_scope = [
  'https://VantageAadB2cQA.onmicrosoft.com/staging/user_impersonation',
];

export const aad_si_dataupload_scope = [
  'https://VantageAadB2cQA.onmicrosoft.com/data-upload/user_impersonation',
];

const resourceArray: [string, string[]][] = [
  [CARDS_API.url, aad_access_scopes],
  [DATA_API.url, aad_access_scopes],
  [DATA_API_V4.url, aad_access_scopes],
  [CONFIG_API.url, aad_access_scopes],
  [MATCHING_API.url, aad_access_scopes],
  [PROFILES_API.url, aad_profiles_scopes],
  [SI_STAGING_API.url, aad_si_staging_scope],
  [SI_DATA_UPLOAD_API.url, aad_si_dataupload_scope],
];

export const environment = {
  production: true,
  title: 'Vantage (QA)',
  includeProfiles: true,
  aad_config: {
    clientId: '675cd929-adaa-4e7f-bb5b-941e423c6a97',
    authorityDomain: 'https://vantageaadb2cqa.b2clogin.com',
    authority:
      'https://vantageaadb2cqa.b2clogin.com/tfp/vantageaadb2cqa.onmicrosoft.com/B2C_1_SI',
    redirectUri: 'https://web-qa.govantage.io/login',
    postLogoutRedirectUri: 'https://web-qa.govantage.io/login',
    password_authority:
      'https://vantageaadb2cqa.b2clogin.com/tfp/vantageaadb2cqa.onmicrosoft.com/b2c_1_pwreset/',
    access_scopes: aad_access_scopes,
    policies: {
      signUpSignIn: 'B2C_1_SI' as const,
      resetPassword: 'b2c_1_pwreset' as const,
    },
  },
  share_config: {
    share_screenshot_api:
      'https://vantage-qa.azure-api.net/frontend-export/v1/getBoardJpg',
    auth: {
      clientId: '822c4fbf-e60e-45a8-98c0-f88197f2643d',
      authority:
        'https://login.microsoftonline.com/96332844-4275-435a-8218-841a471e729f/',
      redirectUri: 'https://web-qa.govantage.io',
      postLogoutRedirectUri: 'https://web-qa.govantage.io',
      navigateToLoginRequestUrl: false,
      validateAuthority: false,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    msalScopes: {
      scopes: ['profile'],
    },
  },
  api: CARDS_API,
  data: DATA_API,
  dataV4: DATA_API_V4,
  config: CONFIG_API,
  matching: MATCHING_API,
  profiles: PROFILES_API,
  staging: SI_STAGING_API,
  dataUpload: SI_DATA_UPLOAD_API,
  submissionInstanceID: 1,
  paginationDefault: {
    focus: 10,
  },
  apps: {
    invision: 'https://invis.io/WBUC7K18UQC',
  },
  bot: {
    key: '6e5f83c9257a43dcba4bc587ab70751c',
    infoUrl: 'https://vantage-qa.azure-api.net/bots/v1/',
  },
  covidSettings: {
    host: 'https://covid-maps-qa.govantage.io/',
    apiBaseUrl: 'https://vantage-qa.azure-api.net/vantage/covid19/v1/',
    ocpApimSubscriptionKey: '50148cccd5024d6fb9f753d1adc3f893',
    dataExpirationTime: 900000,
    rootLevelCode: 'WROO'
  },
  mapbox: {
    accessToken:
      'pk.eyJ1IjoiYnJ2YW50YWdlIiwiYSI6ImNrbzhqbzh4czBrdWgydm11dWtqcDF0MjEifQ.NHH18FfjaOCpPRy9Jlkmiw',
    style: 'mapbox://styles/brvantage/cko18usw50myc17pjjy02d20c',
    baseUrl: 'https://api.mapbox.com',
    allowedUrls: [],
    username: 'brvantage'
  },
  azureInsights: {
    instrumentationKey: '543fcc36-e426-4fe4-b6e3-37d2558b86e3',
  },
};
