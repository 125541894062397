import { ActivatedRoute, Router } from '@angular/router';
import {
  AppState,
  IInstance,
  categories,
  getCurrentApp,
  instance,
} from '@vantage-platform/store';
import { ITour, defaultStepOptions } from './steps';
import { delay, filter, map, take } from 'rxjs/operators';
import { getCardsUiState, isBoardReady } from '@vantage-platform/cards-ui';

import { BsModalService } from 'ngx-bootstrap/modal';
import { INSTANCES_PERIOD_TOUR_DATA } from './steps/instance.constants';
import { Injectable } from '@angular/core';
import { OnBoardingComponent } from './on-boarding/on-boarding.component';
import { ShepherdService } from 'angular-shepherd';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { OnBoardingService } from './on-boarding.service';

@Injectable({
  providedIn: 'root',
})
export class OnBoardingServiceImpl extends OnBoardingService {
  private tour;
  private bsModalRef;
  instance: IInstance;
  categories: any;
  focusBoard: any;
  appCollections: any;

  constructor(
    public modalService: BsModalService,
    public store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private onBoardingService: ShepherdService
  ) {
    super();
    this.onBoardingService.defaultStepOptions = defaultStepOptions;
    this.onBoardingService.modal = true;
    this.onBoardingService.confirmCancel = false;

    this.store.select(instance).subscribe((i) => {
      this.instance = i;
    });
  }

  public showOnBoardingTour(tour: ITour) {
    if (this.tour) {
      this.tour.complete();
    }
    this.isDefaultBoard().subscribe((v) => {
      if (v) {
        this.runTour(tour);
      } else {
        this.router
          .navigate([this.instance.id, tour.path], {
            relativeTo: this.activatedRoute,
          })
          .then(() => {
            const sbsTour = this.store
              .select(isBoardReady)
              .pipe(
                filter((value) => !!value),
                delay(1000)
              )
              .subscribe((_) => {
                const elem = document.querySelector(
                  `.cards-container vantage-platform-card .card-body`
                );
                if (_ && elem) {
                  sbsTour.unsubscribe();
                  this.runTour(tour);
                }
              });
          });
      }
    });
  }

  public startTourFlow = (tour: ITour) => {
    this.bsModalRef = this.modalService.show(OnBoardingComponent, {
      class: 'modal-dialog-centered on-boarding-modal',
      initialState: { tour: tour },
    });
  };

  private runTour(tour: ITour) {
    this.onBoardingService.addSteps(
      this.applyInstanceSpecificUpdates(tour.steps)
    );
    this.tour = this.onBoardingService.tourObject;
    this.tour.start();
  }

  public isDefaultBoard() {
    return forkJoin({
      categories: this.store.select(categories).pipe(
        take(1),
        map((r) => r.some((c) => c.isSelected))
      ),
      app: this.store.select(getCurrentApp).pipe(take(1)),
      cardsState: this.store.select(getCardsUiState).pipe(take(1)),
    }).pipe(
      map((r) => {
        return (
          !r.categories &&
          r.app.defaultBoardID === r.cardsState.boardId &&
          r.cardsState.params.page &&
          +r.cardsState.params.page === 1
        );
      })
    );
  }

  private applyInstanceSpecificUpdates(steps) {
    return steps.map((s) => {
      if (s.id === 'periods') {
        let newStep = {
          ...s,
          text: s.text + INSTANCES_PERIOD_TOUR_DATA.get(this.instance.id),
        };

        return newStep;
      }

      return s;
    });
  }
}
