<div class="apps-suite">
  <div class="apps-suite-header">
    <button class="text menu-btn" (click)="toggleAppsSuite()">
      <span class="icon icon-arrow_back"></span>Back
    </button>
  </div>
  <div class="apps-suite-body">
    <h4 class="apps-suite-title">
      Products
    </h4>
    <ul class="apps-list">
      <ng-container *ngFor="let app of apps$ | async">
        <li
          class="app-item"
          [class.disabled]="!app.isAppAvailable"
          [class.active]="app.isActive"
          (click)="navigate(app)"
        >
          <span class="icon-wrapper app-item-{{ app.name.toLowerCase() }}">
            <span class="icon icon-product_{{ app.name.toLowerCase() }}"></span>
          </span>
          <div class="text">{{ app.name }}</div>
        </li>
      </ng-container>
    </ul>
    <div class="btn-container">
      <button class="outline" (click)="navigateToAppSelector()">
        VIEW VANTAGE SUITE
      </button>
    </div>
  </div>
</div>
<div class="side-menu">
  <div class="side-menu-header">
    <button
      class="text menu-btn"
      (click)="toggleAppsSuite()"
      tooltip="Change Application"
      container="body"
      placement="right"
    >
      <span class="icon icon-apps"></span>
    </button>
    <button id="side-toggle-btn-mobile" class="side-toggle-btn mobile" (click)="toggleSideNav()">
      <span
        class="icon desctop"
        [ngClass]="[isToggled ? 'icon-arrow_back_ios' : 'icon-menu']"
      ></span>

      <span
        class="icon mobile"
        [ngClass]="[isToggled ? 'icon-close' : 'icon-menu']"
      ></span>
    </button>
    <div id="app-title" class="app-title">
      <span class="icon icon-{{ sidenav.appIcon }}">
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
      <span class="app-title-label">
        <span class="title">{{ sidenav.appTitle }}</span>
        <span class="instance">{{ instance.name }} </span>
      </span>
    </div>
  </div>
  <div class="dynamic-content-wrapper">
    <div class="dynamic-content">
      <perfect-scrollbar style="max-width: inherit; max-height: inherit;">
        <nav class="nav-menu" *ngIf="categories.length">
          <ul class="nav-title-wrapper">
            <li
              class="nav-title"
              [tooltip]="sidenav.categoriesTitle"
              container="body"
              placement="right"
              [isDisabled]="isToggled"
            >
              <div class="title">
                <span>
                  {{ sidenav.categoriesTitle }}
                </span>
                <label
                  class="multiple_categories noselect"
                  for="multiple_categories"
                >
                  Multiple
                  <label class="switch">
                    <input
                      type="checkbox"
                      id="multiple_categories"
                      [checked]="multiple"
                      (change)="toggleMultiple()"
                    />
                    <span class="slider round"></span>
                  </label>
                </label>
              </div>
            </li>
          </ul>
          <ul class="bottom-line-devider">
            <li
              class="nav-item"
              [class.active]="isAllCategories"
              (click)="allFocusClick()"
              [tooltip]="sidenav.categoriesAllTitle"
              container="body"
              placement="right"
              [isDisabled]="isToggled"
            >
              <span class="icon icon-web"></span>
              <span class="text">{{ sidenav.categoriesAllTitle }}</span>
            </li>
          </ul>
          <ul>
            <li
              *ngFor="let item of categories | sortBy: 'asc':'sortOrder'"
              class="nav-item"
              [class.active]="item.isSelected"
              [class.multiple]="multiple"
              (click)="categoriesSelect(item)"
              [tooltip]="item.title"
              container="body"
              placement="right"
              [isDisabled]="isToggled"
            >
              <span class="icon icon-{{ item.iconName }}"></span>
              <span class="text">{{ item.title }}</span>
              <span
                class="icon checkbox icon-{{
                  item.isSelected ? 'check_box' : 'check_box_outline'
                }}"
              ></span>
            </li>
          </ul>
        </nav>
        <nav class="nav-menu" *ngIf="(collections$ | async).length">
          <ul class="nav-title-wrapper top-line-devider">
            <li
              class="nav-title"
              tooltip="Collections"
              container="body"
              placement="right"
              [isDisabled]="isToggled"
            >
              <div class="title">
                <span>
                  Collections
                </span>
              </div>
            </li>
          </ul>
          <ul>
            <ng-container *ngFor="let item of collections$ | async">
              <li
                class="nav-item"
                [class.active]="item.isSelected"
                (click)="collectionsSelect(item)"
                [tooltip]="item.name"
                container="body"
                placement="right"
                [isDisabled]="isToggled"
              >
                <span class="icon icon-{{ item.iconName }}"></span>
                <span class="text">{{ item.name }}</span>
              </li>
            </ng-container>
            <li
              class="nav-item no-collections"
              *ngIf="!(collections$ | async).length"
            >
              You have no collections
            </li>
          </ul>
        </nav>
        <nav class="nav-menu">
          <ul>
            <li
              class="nav-item"
              *ngFor="let item of sidenav.staticLinks"
              [routerLink]="item.link"
              routerLinkActive="router-link-active"
            >
              <span class="icon icon-{{ item.icon }}"></span>
              <span class="text">{{ item.title }}</span>
            </li>
          </ul>
        </nav>
      </perfect-scrollbar>
    </div>
  </div>
  <div
    class="static-content-wrapper nav-menu top-line-devider"
    *ngIf="sidenav.staticLinks.length === 0"
  >
    <ul>
      <li
        class="nav-item create-collection"
        (click)="createCollection()"
        tooltip="Create NEW Collection"
        container="body"
        placement="right"
        [isDisabled]="isToggled"
      >
        <span class="icon icon-add_box"></span>
        <span class="text">CREATE NEW COLLECTION</span>
      </li>
    </ul>
  </div>
</div>
