export interface ISpinnerConfig {
  indicator: string;
  color: string;
  title: string;
}

export const BASE_SPINNER_CONFIG = {
  indicator: 'vector',
  color: '#2534F7',
  title: 'Your Product',
};

export const FOCUS_SPINNER_CONFIG = {
  indicator: 'product_focus',
  color: '#2534f7',
  title: 'Focus',
};

export const ANALYTICS_SPINNER_CONFIG = {
  indicator: 'product_analytics',
  color: '#fac337',
  title: 'Analytics',
};

export const PROFILES_SPINNER_CONFIG = {
  indicator: 'product_profiles',
  color: '#fa6e14',
  title: 'Profiles',
};

export const SUBMISSION_SPINNER_CONFIG = {
  indicator: 'product_submission',
  color: '#171b2c',
  title: 'Submission',
};

export const MATCHING_SPINNER_CONFIG = {
  indicator: 'product_matching',
  color: '#171b2c',
  title: 'Matching Engine',
};

export const APPS_SPINNER_CONFIG = {
  1: FOCUS_SPINNER_CONFIG,
  2: ANALYTICS_SPINNER_CONFIG,
  3: PROFILES_SPINNER_CONFIG,
  7: SUBMISSION_SPINNER_CONFIG,
  8: MATCHING_SPINNER_CONFIG,
};
