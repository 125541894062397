import * as StoreActions from './store.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { IInstance, ISideNavItemModel } from '../../models';
import {
  SIDE_NAV_REDUCERS,
  initialSidenavState,
} from './store.reducers.sidenav';

import { VPState } from './store.models';

export const STORE_FEATURE_KEY = 'state';

export interface State extends VPState {
  expandMap: boolean;
  loaded: boolean; // has the Store list been loaded
  loading: boolean;
  appLoading: boolean; // current app state
  appLoaded: boolean; // is app data loaded
}

export interface StorePartialState {
  readonly [STORE_FEATURE_KEY]: State;
}

export const initialState: Partial<State> = {
  // set initial required properties
  loaded: false,
  loading: false,
  appLoading: false,
  appLoaded: false,
  focus: [],

  collections: [],
  categories: [],
  period: [],
  apps: [],
  app: undefined,
  isHeaderVisible: true,
  expandMap: true,
  sidenav: initialSidenavState,
  user: {
    id: undefined,
    identityProviderUserID: '',
    username: '',
    titleID: undefined,
    firstname: '',
    surname: '',
    avatarImage: '',
    active: true,
    prefContactNo: '',
    mobileNo: '',
    email: '',
    emailAlert: true,
    spotfire: true,
    reset: true,
    token: '',
    tokenCreated: '',
    firebaseKey: '',
    wms: true,
    organisationalCategoryID: undefined,
    skypeID: '',
    managerID: undefined,
    defaultLanguageID: undefined,
    changeUserID: undefined,
    changeDBUser: '',
    changeDate: '',
    createdUserID: undefined,
    createdDBUser: '',
    createdDate: '',
  },
  instances: [],
  instance: undefined,
};

const storeReducer = createReducer(
  initialState,
  on(StoreActions.loadUserConfigSuccess, (state: State, { payload }) => ({
    ...state,
    ...payload,
    loading: true,
  })),
  on(StoreActions.loadStoreSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
  })),

  on(StoreActions.resetLoadState, (state) => ({
    ...state,
    loading: true,
  })),

  on(StoreActions.setAppLoading, (state, { appLoading }) => ({
    ...state,
    appLoading,
    appLoaded: false,
  })),
  on(StoreActions.setInstance, (state, { instance }) => ({
    ...state,
    instance,
    apps: [],
    app: undefined,
    appLoading: false,
    loaded: false, // set to false by default as we should reload data
    loading: true,
  })),
  on(StoreActions.updateInstance, (state, { config }) => {
    let instance = { ...state.instance, config };
    return {
      ...state,
      instance,
    };
  }),
  on(StoreActions.setApp, (state, { app }) => {
    return {
      ...state,
      appLoading: true,
      appLoaded: false,
      app,
    };
  }),
  on(StoreActions.pathcAppFocusboard, (state, { focusboard }) => {
    return {
      ...state,
      appLoading: false,
      app: { ...state.app, defaultBoardID: focusboard.defaultBoardID },
    };
  }),
  on(StoreActions.setApps, (state, { apps }) => {
    return {
      ...state,
      apps,
      appLoaded: false,
      appLoading: false,
      app: undefined,
    };
  }),
  on(StoreActions.loadStoreFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(StoreActions.setHeaderVisible, (state, { isHeaderVisible }) => ({
    ...state,
    isHeaderVisible,
  })),

  on(StoreActions.patchExpandMap, (state, { expandMap }) => ({
    ...state,
    expandMap,
  })),

  on(StoreActions.setPeriods, (state, { payload }) => ({
    ...state,
    period: payload,
  })),

  on(StoreActions.selectPeriod, (state, { periodType }) => {
    const { period } = state;
    const newPeriods = period.map((p) => {
      return {
        ...p,
        active: periodType.toLowerCase() === p.PeriodType.toLowerCase(),
      };
    });

    return {
      ...state,
      period: newPeriods,
    };
  }),

  on(StoreActions.updateCollections, (state, { payload }) => {
    const collections = [...state.collections];

    const newCollection = { ...payload };
    newCollection.title = payload.name;
    newCollection.iconName = 'gesture';

    // TODO: refactor to fix models
    const collection = collections.find((c) => c.id === payload.id);
    if (collection) {
      collections.splice(
        collections.findIndex((i) => i.id === collection.id),
        1,
        newCollection
      );
    } else {
      collections.push(newCollection);
    }

    return {
      ...state,
      collections: collections,
    };
  }),

  on(StoreActions.removeCollections, (state, { payload }) => {
    const collections = state.collections.filter((c) => c.id !== payload.id);

    return {
      ...state,
      collections: collections,
    };
  }),
  on(StoreActions.selectCollection, (state, { id }) => {
    const collections = state.collections.map((c) => {
      const col = { ...c, isSelected: c.id === +id };

      return col;
    });

    return {
      ...state,
      collections: collections,
    };
  }),
  on(StoreActions.resetCollections, (state) => {
    const collections = state.collections.map((i: any) => {
      const item = { ...i };
      item.isSelected = false;
      item.isChipsSelected = false;
      return item;
    });

    return {
      ...state,
      collections: collections,
    };
  }),
  ...SIDE_NAV_REDUCERS
);

export function reducer(state: State | undefined, action: Action) {
  return storeReducer(state, action);
}
