<apx-chart
  [series]="series"
  [chart]="chart"
  [xaxis]="xaxis"
  [colors]="colors"
  [markers]="markers"
  [legend]="legend"
  [yaxis]="yaxis"
  [stroke]="stroke"
></apx-chart>
