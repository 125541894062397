import { Pipe, PipeTransform } from '@angular/core';

import { ICollection } from '@vantage-platform/store';

@Pipe({
  name: 'filterInclude',
  pure: false,
})
export class FilterIncludePipe implements PipeTransform {
  transform(items: ICollection[], args: any[]): any {
    return items.filter((item) => args.includes(item.id));
  }
}

@Pipe({
  name: 'filterExclude',
  pure: false,
})
export class FilterExcludePipe implements PipeTransform {
  transform(items: ICollection[], args: any[]): any {
    return items.filter((item) => !args.includes(item.id));
  }
}
