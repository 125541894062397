<div *ngIf="this.authService.loggedIn">
  <div class="modal-header">
    <h4>Share Insight Card</h4>
    <button
      aria-label="Close"
      class="close-modal-btn text"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form class="share-form" [formGroup]="shareForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <div class="form-group">
        <span
          [ngClass]="{ visible: shareForm.controls['team'].value }"
          class="label"
          >Team</span
        >
        <ngx-select
          [items]="teams"
          formControlName="team"
          optionTextField="displayName"
          optionValueField="id"
          placeholder="Team"
        >
        </ngx-select>
      </div>

      <div class="form-group">
        <span
          [ngClass]="{ visible: shareForm.controls['channel'].value }"
          class="label"
          >Channel</span
        >
        <ngx-select
          [items]="channels"
          formControlName="channel"
          optionTextField="displayName"
          optionValueField="id"
          placeholder="Channel"
        >
        </ngx-select>
      </div>

      <div class="form-group">
        <textarea
          class="message"
          placeholder="Message"
          formControlName="message"
        ></textarea>
        <span
          [ngClass]="{ visible: shareForm.controls['message'].value }"
          class="label"
          >Message</span
        >
      </div>
    </div>

    <div class="modal-footer">
      <div class="buttons-right-container">
        <button class="text" (click)="modalRef.hide()" type="button">
          Cancel
        </button>
        <button [disabled]="shareForm.invalid" type="submit">Send</button>
      </div>
    </div>
    <ngx-spinner
      name="share-spinner"
      type="ball-clip-rotate"
      bdColor="rgba(255,255,255,0.6)"
      color="rgba(37,52,247,.54)"
      size="medium"
      [fullScreen]="false"
    >
    </ngx-spinner>
  </form>
  <div class="backdrop"></div>
</div>
