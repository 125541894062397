import { AppState, ICard, ICollection } from '../models';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Operation } from 'fast-json-patch';
import { Store } from '@ngrx/store';
import { environment } from 'apps/vantage-focus/src/environments/environment';
import { instance } from '../+state/app/store.selectors';
import { take } from 'rxjs/operators';

const COLLECTIONS_URL = `/me/boards/collections`;

@Injectable({
  providedIn: 'root',
})
export class CollectionsDataService {
  constructor(private http: HttpClient, public store: Store<AppState>) {}

  getCollections(): Observable<ICollection[]> {
    let inst;
    this.store
      .select(instance)
      .pipe(take(1))
      .subscribe((values) => {
        inst = values;
      });

    const params = {
      'instance-id': inst.id,
    };

    return this.http.get(`${environment.api.url}${COLLECTIONS_URL}`, {
      params,
    }) as Observable<ICollection[]>;
  }

  createCollections(collection: ICollection): Observable<ICollection> {
    return this.http.post(
      `${environment.api.url}${COLLECTIONS_URL}`,
      collection
    ) as Observable<ICollection>;
  }

  saveCollections(
    collectionId: number,
    patch: Operation[]
  ): Observable<ICollection> {
    return this.http.patch(
      `${environment.api.url}${COLLECTIONS_URL}/${collectionId}`,
      patch
    ) as Observable<ICollection>;
  }

  removeCollections(collectionId: number): Observable<ICollection> {
    return this.http.delete(
      `${environment.api.url}${COLLECTIONS_URL}/${collectionId}`
    ) as Observable<ICollection>;
  }

  getCollectionData(
    collectionId: number,
    page: number
  ): Observable<HttpResponse<ICard[]>> {
    const params = {
      page: page.toString(),
    };

    return this.http.get(
      `${environment.api.url}focusboards/${collectionId}/cards`,
      { params, observe: 'response' }
    ) as Observable<HttpResponse<ICard[]>>;
  }
}
