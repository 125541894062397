import { AuthService, checkInTeams, isTeamsApp, LOCAL_STORAGE, POST_LOGIN_REQUEST_URL, SIGN_IN_ACTION, TEAMS_SIGNIN_MODAL } from '@vantage-platform/auth';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';

import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { Router } from '@angular/router';
import { TeamsContextService } from '@vantage-platform/share-to-teams';
import { EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, take, takeUntil, delay } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, loadUser } from '@vantage-platform/store';
import { HelpersService } from '../../shared/helpers.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  constructor(
    public auth: AuthService,
    private msal: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(LOCAL_STORAGE) @Optional() private localStorage: Storage,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private router: Router,
    private helpersService: HelpersService,
    public ctx: TeamsContextService,
    private store: Store<AppState>,

  ) {
    if (!this.localStorage) {
      this.localStorage = window.localStorage;
    }
  }

  ngOnInit(): void {
    if (this.localStorage.getItem('logout') == 'logout') {
      this.localStorage.removeItem('logout');
      window.close();
    }

    if (this.auth.isLoggedIn) {
      this.routeAfterLogIn();
    } else {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((s) => s === InteractionStatus.None),
          take(1),
          takeUntil(this.destroy$),
          takeUntil(
            this.msalBroadcastService.msalSubject$.pipe(
              filter((ev) => ev.eventType === EventType.LOGIN_START)
            )
          )
        )
        .subscribe((s) => {
          if (!this.auth.isLoggedIn) {
            if (
              this.msal.instance.getAllAccounts().length < 1 &&
              !isTeamsApp()
            ) {
              this.markSignIN();
              this.auth.login();
            }
          } else {
            this.auth.checkAndSetActiveAccount();
            this.routeAfterLogIn();
          }
        });
    }
  }
  logInFromTeams() {
    this.markSignIN();
    this.ctx.msTeams.authentication
      .authenticate({
        url: window.location.origin + '/teamsloginmodal',
        width: 920,
        height: 390,
      })
      .then((r) => {
        this.routeAfterLogIn();
      });
  }

  private routeAfterLogIn = async () => {
    let path =
      this.localStorage.getItem(POST_LOGIN_REQUEST_URL) ||
      window.location.origin + '/';
    this.localStorage.removeItem(POST_LOGIN_REQUEST_URL);

    if (isTeamsApp()) {
      this.store.dispatch(loadUser());
      this.helpersService.initPageTitle()

      let teamsContext = this.ctx.context;
      if (teamsContext.page.subPageId) {
          this.router.navigateByUrl(teamsContext.page.subPageId.split(';').join('&'), {skipLocationChange: false, replaceUrl: true})
      } else  {
        let url = new URL(path);
        this.router.navigateByUrl(url.pathname + url.search, {skipLocationChange: false, replaceUrl: true})
      }
    } else window.location.href = path;
  };

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private markSignIN() {
    this.localStorage.setItem(SIGN_IN_ACTION, 'true')
  }
}
