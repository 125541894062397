<div
  *ngIf="card.rows"
  class="card-inner-wrapper"
  [ngClass]="{ 'no-bottom-border': card.link }"
>
  <vantage-platform-question
    [config]="config"
    [card]="card"
  ></vantage-platform-question>
  <div class="card-body">
    <vantage-platform-card-header
      [config]="config"
      [card]="card"
    ></vantage-platform-card-header>

    <ng-container
      *ngFor="let row of card.rows; let rowIndex = index; trackBy: trackByFn"
    >
      <vantage-platform-card-row
        *ngIf="rowHasData(row)"
        class="tiles-container row no-gutters"
        [ngClass]="{
          'space-between': row.spaceBetween,
          'no-v_divider': row.noDivider
        }"
        [row]="row"
        [cardConfig]="config"
        [card]="card"
      ></vantage-platform-card-row>
    </ng-container>
  </div>
  <div
    class="focus-button-block"
    [class.focus-button-block__with-card-link]="card.link"
  >
    <button
      *ngIf="
        !card.isFocusCard &&
        config &&
        (card.hasFocusBoard || config?.showButton)
      "
      (click)="focusCard()"
      class="text focus-button"
    >
      {{ config.focusButtonTitle }}
    </button>
  </div>
  <div class="card-bottom-border" *ngIf="card.link">
    <span class="card-bottom-right-border"></span>
    <span class="card-bottom-left-border"></span>
  </div>

  <!-- Temporary disabled - as it not configured on API side yet -->
  <!-- <vantage-platform-revard
    *ngIf="card.reward"
    [reward]="card.reward"
  ></vantage-platform-revard> -->
</div>
<div class="card-link" *ngIf="card.link">
  <div class="card-top-border">
    <div class="divider"></div>
    <span class="card-top-right-border"></span>
    <span class="card-top-left-border"></span>
  </div>
  <div class="card-link-body">
    <h4 class="question m-0"></h4>
    <div class="product-item">
      <button class="text" (click)="navigateToReport(card)">
        {{ getLinkConfig(card.link.appId).linkTitle }}
        <span
          class="icon icon-product_{{
            getLinkConfig(card.link.appId).indicatorClass
          }}"
        >
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
      </button>
    </div>
  </div>
</div>
<vantage-platform-card-skeleton
  *ngIf="!card.rows"
></vantage-platform-card-skeleton>
