<div
  *ngIf="!useNativeScroll"
  #el
  class="cards-wrapper ps"
  infinite-scroll
  [infiniteScrollDistance]="scrollDistance"
  [infiniteScrollUpDistance]="scrollUpDistance"
  [infiniteScrollThrottle]="throttle"
  [alwaysCallback]="true"
  (scrolledUp)="onScroll(true)"
  (scrolled)="onScrollDown()"
  (ps-x-reach-start)="onScroll(true)"
  (ps-scroll-down)="onScroll(false)"
  [scrollWindow]="false"
  [disabled]="disableScroll | async"
  [perfectScrollbar]
>
  <ng-container
    *ngTemplateOutlet="content; context: { container: el }"
  ></ng-container>
</div>

<div
  *ngIf="useNativeScroll"
  #el
  class="cards-wrapper"
  style="overflow-y: auto;"
  infinite-scroll
  [infiniteScrollDistance]="scrollDistance"
  [infiniteScrollUpDistance]="scrollUpDistance"
  [infiniteScrollThrottle]="throttle"
  [alwaysCallback]="true"
  (scrolledUp)="onScroll(true)"
  (scrolled)="onScrollDown()"
  (scroll)="onNativeScroll($event, el)"
  [scrollWindow]="false"
>
  <ng-container
    *ngTemplateOutlet="content; context: { container: el }"
  ></ng-container>
</div>

<ng-template #content let-container="container">
  <ng-content></ng-content>
  <div [class]="getCardContainerClass()">
    <ng-container *ngFor="let card of cards; trackBy: trackCardBy">
      <vantage-platform-card
        [card]="card"
        [config]="getCardConfig(card)"
        (focused)="container.scrollTop = 0"
      ></vantage-platform-card>
      <!-- [service]="cardService" -->
    </ng-container>

    <vantage-platform-caught-up
      [hidden]="!($isEndOfPage | async)"
    ></vantage-platform-caught-up>
    <vp-loading-indicator
      [hidden]="isCustomSpinnerExist || !($isLoading | async)"
    ></vp-loading-indicator>
    <div [class]="getEmptyCardContainerClass()" *ngIf="!config.noCards?.useCustomPlaceholder && ($isBoardEmpty | async)">
      <vantage-platform-empty
        [title]="config.noCards.title"
        [message]="config.noCards.message"
      ></vantage-platform-empty>
    </div>
    <ng-container *ngIf="config.noCards?.useCustomPlaceholder && ($isBoardEmpty | async)">
      <ng-content select="[no-cards-custom-placeholder]"></ng-content>
    </ng-container>
  </div>
</ng-template>
