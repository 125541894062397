import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsightService } from './insight.service';

@NgModule({
  imports: [CommonModule],
  providers: [InsightService]
})
export class AppInsightModule {
}
