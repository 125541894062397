import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vantage-platform-card-skeleton',
  templateUrl: './card-skeleton.component.html',
  styleUrls: ['./card-skeleton.component.scss']
})
export class CardSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
