import { ICard, ICardConfig, ITile, IUrlTile } from '../models/i-card';

const focusCardConfig: ICardConfig = {
  focusButtonTitle: 'FOCUS INSIGHT',
  indicatorClass: 'focus',
  type: 'focusCard',
  showButton: false,
};

const analyticsCardReportConfig: ICardConfig = {
  focusButtonTitle: 'View Report',
  linkTitle: 'View in Analytics',
  indicatorClass: 'analytics',
  type: 'report',
  showButton: true,
};

export const analyticsCardTableAUConfig: ICardConfig = {
  focusButtonTitle: 'View Report',
  linkTitle: 'View in Analytics',
  indicatorClass: 'analytics',
  type: 'tableau',
  showButton: true,
};

export const analyticsCardMapConfig: ICardConfig = {
  focusButtonTitle: 'View Map',
  indicatorClass: 'analytics',
  type: 'map',
  showButton: true,
};

// TODO: update during profile integration
const profilesCardConfig = {
  focusButtonTitle: '',
  indicatorClass: 'profiles',
  type: 'map',
  showButton: true,
};

export const workCardConfig: ICardConfig = {
  focusButtonTitle: 'Start Workflow',
  indicatorClass: 'work',
  type: 'work',
  showInNewTab: false,
  showButton: true,
};

export const getFocusCardConfig = (card: ICard): ICardConfig => focusCardConfig;

export const getAnalyticsCardConfig = (card: ICard): ICardConfig => {
  let config = analyticsCardReportConfig;
  card?.rows?.find((r) =>
    r.tiles.find((t: ITile) => {
      // check only IURL tile
      if (t.cardTileTypeID === 8) {
        const data: IUrlTile = t.data as IUrlTile;

        switch (data?.config?.reportType.toLowerCase()) {
        case 'covidmap': {
          config = analyticsCardMapConfig;
          break;
        }
        case 'externallink': {
          config = { ...analyticsCardTableAUConfig, config: data.config };
          break;
        }
        default: {
          config = analyticsCardReportConfig;
        }
        }

        return true;
      }
    })
  );
  return config;
};

export const getProfilesCardConfig = (card: ICard): ICardConfig => profilesCardConfig;

export const getWorkCardConfig = (card: ICard): ICardConfig => {
  // TODO: for testing purposes
  const testUrl = window['testDecisionNavigationUrl']

  let config = workCardConfig;
  card?.rows?.find((r) =>
    r.tiles.find((t: ITile) => {
      // check only IURL tile
      if (t.cardTileTypeID === 8) {
        const data: IUrlTile = t.data as IUrlTile;
        let _config = data.config;

        if(testUrl) { // OWERIDE URL for testing purposes
          _config = { ...data.config, url: testUrl};
        }

        const showInNewTab = _config.url.toLowerCase().includes('opennewtab=true')

        config = { ...workCardConfig, config: _config, showInNewTab };

        return;
      }
    })
  );

  return config;
};

export const CARDS_CONFIG = {
  1: getFocusCardConfig,
  2: getAnalyticsCardConfig,
  3: getProfilesCardConfig,
  5: getWorkCardConfig,
};

export const getConfigByAppId = (id: number): ICardConfig => CARDS_CONFIG[id]();
