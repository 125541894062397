import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AppGuard } from './shared/guards/app.guard';
import { AppResolver } from './shared/resolvers/app.resolver';
import { AppsSuiteComponent } from './pages/apps-suite/apps-suite.component';
import { AuthGuard } from '@vantage-platform/auth';
import { FocusBoardGuard } from './shared/guards/focus-board.guard';
import { InstanceGuard } from './shared/guards/instance.guard';
import { LoginComponent } from './pages/login/login.component';
import { NgModule } from '@angular/core';
import { ProductLayoutComponent } from './layouts/product-layout/product-layout.component';
import { ShareCardComponent } from './pages/share-card/share-card.component';
import { ShareCardGuard } from './shared/guards/sharecard.guard';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { TeamsConfigTabComponent } from './pages/teams-app/teams-config-tab/teams-config-tab.component';
import { TeamsLoginModalComponent } from './pages/teams-app/teams-login-modal/teams-login-modal.component';
import { TeamsRemoveTabComponent } from './pages/teams-app/teams-remove-tab/teams-remove-tab.component';
import { environment } from '../environments/environment';

const applicationsExtraRoutes = [];
if (environment.includeProfiles) {
  applicationsExtraRoutes.push({
    path: 'profiles',
    canActivate: [AuthGuard, AppGuard],
    resolve: { app: AppResolver },
    loadChildren: () =>
      import('@vantage-platform/profiles/profiles-screen').then(
        (mod) => mod.ProfilesScreenModule
      ),
  });
}

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'teamsloginmodal',
    pathMatch: 'full',
    component: TeamsLoginModalComponent,
  },
  {
    path: 'teamsconfigtab',
    pathMatch: 'full',
    component: TeamsConfigTabComponent,
  },
  {
    path: 'teamsremovetab',
    pathMatch: 'full',
    component: TeamsRemoveTabComponent,
  },
  {
    path: 'sharecard',
    pathMatch: 'full',
    canActivate: [ShareCardGuard],
    component: ShareCardComponent,
  },
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        component: AppsSuiteComponent,
      },
      {
        path: ':instance',
        canActivate: [AuthGuard, InstanceGuard],
        component: AppsSuiteComponent,
      },
    ],
  },
  {
    path: ':instance',
    canActivate: [AuthGuard, InstanceGuard],
    component: ProductLayoutComponent,
    children: [
      {
        path: 'focus',
        canActivate: [AuthGuard, AppGuard, FocusBoardGuard],
        resolve: { app: AppResolver },
        loadChildren: () =>
          import('@vantage-platform/focus/focus-screen').then(
            (mod) => mod.FocusScreenModule
          ),
      },
      {
        path: 'analytics',
        canActivate: [AuthGuard, AppGuard],
        resolve: { app: AppResolver },
        loadChildren: () =>
          import('@vantage-platform/analytics/analytics-screen').then(
            (mod) => mod.AnalyticsScreenModule
          ),
      },
      {
        path: 'work',
        canActivate: [AuthGuard, AppGuard],
        resolve: { app: AppResolver },
        loadChildren: () =>
          import('@vantage-platform/work/work-screen').then(
            (mod) => mod.WorkScreenModule
          ),
      },
      {
        path: 'submission',
        canActivate: [AuthGuard, AppGuard],
        resolve: { app: AppResolver },
        loadChildren: () =>
          import('@vantage-platform/submission/submission-screen').then(
            (mod) => mod.SubmissionScreenModule
          ),
      },
      {
        path: 'matching',
        canActivate: [AuthGuard, AppGuard],
        resolve: { app: AppResolver },
        loadChildren: () =>
          import('@vantage-platform/match').then((mod) => mod.MatchModule),
      },
      ...applicationsExtraRoutes,
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: !environment.production,
      preloadingStrategy: PreloadAllModules,
      urlUpdateStrategy: 'eager',
      useHash: false,
      //relativeLinkResolution: 'legacy', Deprecated in angular 14
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
