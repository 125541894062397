import * as StoreActions from './store.actions';

import { AppState, IAppConfig, IParams, IStaticLink } from '../../models';

import { VPState } from './store.models';
import { on } from '@ngrx/store';

export interface SidenavState {
  appConfig?: IAppConfig;
  appIcon: string;
  appTitle: string;
  categoriesTitle: string;
  categoriesAllTitle: string;
  showCategoriesAllBtn: boolean;
  isCategoriesMultiselect: boolean;
  isExtraUrlParams: boolean;
  multiple: boolean;
  extraUrlParams?: IParams;
  path: string;
  resolveData: boolean;
  containsMap?: boolean;
  dataService?: string;
  containsPeriod: boolean;
  staticLinks?: IStaticLink[];
}

export const initialSidenavState: SidenavState = {
  // set initial required properties
  appIcon: '',
  appTitle: '',
  categoriesTitle: '',
  categoriesAllTitle: '',
  isCategoriesMultiselect: false,
  showCategoriesAllBtn: false,
  isExtraUrlParams: false,
  multiple: false,
  resolveData: false,
  path: '',
  staticLinks: [],
  containsMap: false,
  containsPeriod: false,
};

export const SIDE_NAV_REDUCERS = [
  on(StoreActions.loadSidenavStoreForApp, (state: AppState, { payload }) => {
    let activeCategories = payload.categories.filter((c) => c.isSelected);
    let snState = state.sidenav;

    return {
      ...state,
      sidenav: {
        ...snState,
        ...payload,
        multiple: snState.multiple
          ? snState.multiple
          : activeCategories.length > 1,
      },
    };
  }),
  on(StoreActions.setCategories, (state: AppState, { payload }) => ({
    ...state,
    categories: payload,
  })),
  on(StoreActions.setExtraUrlParams, (state: AppState, { payload }) => ({
    ...state,
    sidenav: {
      ...state.sidenav,
      extraUrlParams: {
        ...state.sidenav.extraUrlParams,
        ...payload,
      },
    },
  })),
  on(StoreActions.setMultiple, (state: AppState, { payload }) => ({
    ...state,
    sidenav: {
      ...state.sidenav,
      multiple: payload,
    },
  })),

  on(StoreActions.selectSideNavCategories, (state: VPState, { ids }) => {
    ids = [...ids].map((i) => +i);
    const categories = state.categories.map((c) => {
      const col = { ...c, isSelected: ids.includes(c.categoryID) };
      return col;
    });

    return {
      ...state,
      multiple:
        ids.length > 1 && !state.sidenav.multiple
          ? true
          : state.sidenav.multiple,
      categories,
    };
  }),
  on(StoreActions.resetCategories, (state: VPState) => {
    const categories = state.categories.map((i: any) => {
      const item = { ...i };
      item.isSelected = false;
      item.isChipsSelected = false;

      return item;
    });

    return {
      ...state,
      categories,
    };
  }),
];
