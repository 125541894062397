import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ConfirmComponent } from './confirm.component';
import { IState } from './IState';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfirmModalService {
  bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService) {

  }

  show(state: IState, callback) {
    this.bsModalRef = this.modalService.show(ConfirmComponent, {
      initialState: {
        state,
      },
      class: 'modal-dialog-centered allign-footer-right modal-md',
    });
    this.bsModalRef.content.confirm.subscribe((s) => {
      if (s !== null) {
        callback(s);
      }
    });
  }
}
