import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  Renderer2,
  TemplateRef,
} from '@angular/core';

import { BottomSheetComponent } from './bottom-sheet.component';

@Directive({
  selector: '[vpBottomSheet]',
})
export class BottomSheetDirective {
  @Input() vpBottomSheet: TemplateRef<any>;

  componentRef: ComponentRef<any>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private renderer: Renderer2
  ) {}

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    this.appendToBody();
  }

  private appendToBody() {
    const viewRef = this.vpBottomSheet.createEmbeddedView({});
    viewRef.markForCheck();
    this.appRef.attachView(viewRef);
    // ContentRef([_viewRef.rootNodes], _viewRef);

    // Create a component reference from the component
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(BottomSheetComponent)
      .create(this.injector, [viewRef.rootNodes]);

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this.componentRef.hostView);

    // Get DOM element from component
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);
    this.registerOutsideClick();
  }

  private registerOutsideClick() {
    this.renderer.listen('document', 'closeBottomSheet', (event: any) => {
      this.componentRef.destroy();
    });
  }
}
