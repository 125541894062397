import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IIndicatorValue } from '../models/i-indicator-value';
import { environment } from 'apps/vantage-focus/src/environments/environment';
import { ISideNavItemModel } from '../models/i-side-nav-item-model';
import { IIndicatorSource } from '../models/i-indicator-source';
import { IInstanceIndicator } from '../models/i-instance-indicator';
import { Store } from '@ngrx/store';
import { AppState } from '../models';
import { instance } from '../+state/app/store.selectors';
import { switchMap, take } from 'rxjs/operators';
import { IBlacklistIndicator as IBlacklistIndicator } from '../models/i-blacklist-indicator';
import { IQuestion } from '../models/i-question';
import { IInstanceShapeFile } from '../models/i-instance-shape-file';
import { IDataService } from '../models/i-data-service';
import { IProfilesService } from '../models/i-profiles-service';
import { IProfilesConfiguration } from '../models/i-profiles-configuration';
import { IGeoLocationProfile } from '../models/i-geo-location-profile';
import { MapGeoLevel } from 'libs/profiles/profiles-screen/src/data-utilities/enums/mapGeoLevel.enum';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService implements IProfilesService {
  constructor(private http: HttpClient, public store: Store<AppState>) {}

  getInformationDomains(category:string): Observable<Array<IInstanceIndicator>> {
    return this.store.select(instance).pipe(
      take(1),
      switchMap((currentInstance) => {
        const endpoint = `indicators?instance-id=${currentInstance.id}&category=${category}`;
        return this.http.get(
          encodeURI(`${environment.dataV4.url}${endpoint}`)
        ) as Observable<Array<IInstanceIndicator>>;
      })
    );
  }

  getIndicatorValues(
    indicatorCode: string,
    geoLevel?: number,
    parentGeoLocationId?:number
  ): Observable<Array<IIndicatorValue>> {
    if (indicatorCode === null || typeof indicatorCode === 'undefined')
    return of(new Array<IIndicatorValue>());

    const encodedIndicatorCode = encodeURIComponent(indicatorCode);
    let endpoint = `indicators`;

    if (typeof geoLevel !== 'undefined'){
      endpoint += (parentGeoLocationId === null) 
      ? `/indicator-values-per-geo-level?indicator-code=${encodedIndicatorCode}&geo-level=${geoLevel}`   
      : `/indicator-values-per-geo-level?indicator-code=${encodedIndicatorCode}&geo-level=${geoLevel}&parent-location-id=${parentGeoLocationId}`   
    }
    else{
      endpoint += `/indicator-values?indicator-code=${encodedIndicatorCode}`
    } ;

    return this.http.get(
      `${environment.profiles.url}${endpoint}`
    ) as Observable<Array<IIndicatorValue>>;
  }

  getGeoLocations(mapGeoLevel?:MapGeoLevel): Observable<IGeoLocationProfile[]> {
    let endpoint = `geolocations/locations`;
    
    if(typeof mapGeoLevel !== 'undefined')
      endpoint += `?geo-level=${mapGeoLevel}`

    return this.http.get(
      encodeURI(`${environment.profiles.url}${endpoint}`)
    ) as Observable<IGeoLocationProfile[]>;
  }

  getIndicatorBlacklist(): Observable<IBlacklistIndicator[]> {  
    return this.store.select(instance).pipe(
      take(1),
      switchMap((currentInstance) => {
        const endpoint = `indicators/blacklist?instance-id=${currentInstance.id}`;
        return this.http.get(
          encodeURI(`${environment.profiles.url}${endpoint}`)
        ) as Observable<IBlacklistIndicator[]>;
      })
    );
  }

  getShapeFiles(): Observable<IInstanceShapeFile[]> {
    return this.store.select(instance).pipe(
      take(1),
      switchMap((currentInstance) => {
        const endpoint = `shape-files?instance-id=${currentInstance.id}`;
        return this.http.get(
          encodeURI(`${environment.profiles.url}${endpoint}`)
        ) as Observable<IInstanceShapeFile[]>;
      })
    );
  }

  getQuestions(): Observable<IQuestion[]> {
    return this.store.select(instance).pipe(
      take(1),
      switchMap((currentInstance) => {
        const endpoint = `questions?instance-id=${currentInstance.id}`;
        return this.http.get(
          encodeURI(`${environment.profiles.url}${endpoint}`)
        ) as Observable<IQuestion[]>;
      })
    );
  }

  getLocationId(locationName: string): Observable<number> {
    const endpoint = `geolocations/locationid?location-name=${locationName}`;
    return this.http.get(
      encodeURI(`${environment.profiles.url}${endpoint}`)
    ) as Observable<number>;
  }

  getDataServices(): Observable<IDataService[]> {
    return this.store.select(instance).pipe(
      take(1),
      switchMap((currentInstance) => {
        const endpoint = `data-services?instance-id=${currentInstance.id}`;
        return this.http.get(
          encodeURI(`${environment.profiles.url}${endpoint}`)
        ) as Observable<IDataService[]>;
      })
    );
  }

  getConfiguration(): Observable<IProfilesConfiguration> {
    return this.store.select(instance).pipe(
      take(1),
      switchMap((currentInstance) => {
        const endpoint = `configuration/settings?instance-id=${currentInstance.id}`;
        return this.http.get(
          encodeURI(`${environment.profiles.url}${endpoint}`)
        ) as Observable<IProfilesConfiguration>;
      })
    );
  }
}
