<!-- <div class="dropdown">
  <button class="toggle text">
    <div class="avatar">
      <img [hidden]="!profile.avatar" [src]="profile.avatar" />
      <span class="initials" [hidden]="!!profile.avatar">
        {{ profile.initials }}
      </span>
    </div>
    <span
      class="icon"
      ngClass="{
        icon-expand_less: isOpened;
        icon-expand_more: !isOpened;
      }"
    ></span>
  </button>
  <div class="outline">
    <div class="profile-info-wrapper">
      <div class="avatar">
        <img [hidden]="!profile.avatar" [src]="profile.avatar" />
        <span class="initials" [hidden]="!!profile.avatar">
          {{ profile.initials }}
        </span>
      </div>
      <div class="profile-info">
        <span class="profile-name">{{ profile.name }}</span>
        <span class="profile-email">{{ profile.email }}</span>
      </div>
    </div>
    <ul class="menu-items">
      <li>
        <span>Log out</span>
      </li>
      <li class="app-version">App Version: {{ version }}</li>
    </ul>
  </div>
</div> -->
<div
  class="btn-group"
  dropdown
  #profileDropdown="bs-dropdown"
  placement="bottom right"
>
  <button
    dropdownToggle
    type="button"
    class="text dropdown-toggle"
    aria-controls="dropdown-basic"
  >
    <div class="avatar large">
      <img [hidden]="!profile.avatar" [src]="profile.avatar" />
      <span class="initials" [hidden]="!!profile.avatar">
        {{ profile.initials }}
      </span>
    </div>
    <span
      class="icon"
      [ngClass]="{
        'icon-expand_less': profileDropdown.isOpen,
        'icon-expand_more': !profileDropdown.isOpen
      }"
    ></span>
  </button>
  <ul
    id="dropdown-basic"
    *dropdownMenu
    class="dropdown-menu menu-items dropdown-menu-right"
    role="menu"
    aria-labelledby="button-basic"
  >
    <div class="profile-info-wrapper">
      <div class="avatar large">
        <img [hidden]="!profile.avatar" [src]="profile.avatar" />
        <span class="initials" [hidden]="!!profile.avatar">
          {{ profile.initials }}
        </span>
      </div>
      <div class="profile-info">
        <span class="profile-name">{{ profile.name }}</span>
        <span class="profile-email">{{ profile.email }}</span>
      </div>
    </div>
    <li role="menuitem" (click)="logout($event)">
      <span>Log out</span>
    </li>
    <li role="menuitem " class="app-version disabled">
      App Version: {{ version }}
    </li>
  </ul>
</div>
