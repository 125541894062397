import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vantage-platform-caught-up',
  templateUrl: './caught-up.component.html',
  styleUrls: ['./caught-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaughtUpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
