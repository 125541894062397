import { RequestQueue, createQueue } from './request-queue';

import { HttpClient } from '@angular/common/http';
import { ICard } from '@vantage-platform/store/lib/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'apps/vantage-focus/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CardDataService {
  private requestsQueue: RequestQueue<ICard>;

  constructor(private http: HttpClient) {
    this.initRequestQueue();
  }

  private initRequestQueue() {
    this.requestsQueue = createQueue<ICard>(7);
  }

  getCard(cardId: number, focusBoardId: number): Observable<ICard> {
    const url = `me/boards/${focusBoardId}/cards/${cardId}?embed=all`;

    return this.requestsQueue.queue(
      this.http.get(`${environment.api.url}${url}`) as Observable<ICard>
    );
  }

  getCardByGeoLocation(
    cardId: number,
    focusBoardId: number,
    geoLocation: string
  ): Observable<ICard> {
    const url = `me/boards/${focusBoardId}/cards/${cardId}?embed=all&dynamic-location-name=${geoLocation}`;

    return this.http.get(`${environment.api.url}${url}`) as Observable<ICard>;
  }

  pinCard(cardId: number, collectionId: number) {
    console.log(cardId, collectionId);
    const url = `me/boards/collections/${collectionId}/cards/${cardId}`;
    const body = {
      focusBoardID: collectionId,
      focusBoardCardID: cardId,
    };

    return this.http.post(`${environment.api.url}${url}`, body);
  }

  unPinCard(cardId: number, collectionId: number) {
    console.log(cardId, collectionId);
    const url = `me/boards/collections/${collectionId}/cards/${cardId}`;

    return this.http.delete(`${environment.api.url}${url}`, {
      observe: 'response',
    });
  }
}
