import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { ApexOptions } from 'ngx-apexcharts';
import { IDualBarChartData } from '../../models/i-card';
import { numberFormatter } from '../../utils/helpers';

@Component({
  selector: 'vantage-platform-dual-bar-chart',
  templateUrl: './dual-bar-chart.component.html',
  styleUrls: ['./dual-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DualBarChartComponent implements OnInit {
  @Input() data: IDualBarChartData;

  leftChartOptions: ApexOptions = {
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        data: [],
      },
    ],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
      width: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        left: 0,
        right: -23,
        top: -20,
        bottom: 0,
      },
    },
    yaxis: {
      show: true,
      reversed: true,
      axisTicks: {
        show: true,
      },
      labels: {
        show: false,
      },
    },
    xaxis: {
      crosshairs: { show: false },
      categories: [],
      labels: {
        hideOverlappingLabels: true,
        formatter: (val) => {
          return numberFormatter(val);
        },
      },
      title: {
        text: '',
        offsetY: 10,
        style: {
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: 'Inter',
          color: '#171B2C',
        },
      },
    },
    fill: {
      colors: [],
    },
  };
  rightChartOptions: ApexOptions = {
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        data: [],
      },
    ],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
      width: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        left: -25,
        right: 0,
        top: -20,
        bottom: 0,
      },
    },
    yaxis: {
      show: true,
      reversed: false,
      axisTicks: {
        show: true,
      },
      labels: {
        show: false,
        align: 'center',
        style: {
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: 'Inter',
          colors: '#171B2C',
        },
      },
    },
    xaxis: {
      categories: [],
      labels: {
        hideOverlappingLabels: true,
        formatter: (val) => {
          return numberFormatter(val);
        },
      },
      title: {
        text: '',
        offsetY: 10,
        style: {
          fontSize: '12px',
          fontWeight: '500',
          fontFamily: 'Inter',
          color: '#171B2C',
        },
      },
    },
    colors: [],
  };

  constructor() {}

  ngOnInit(): void {
    if (this.data.series.length === 0) {
      return;
    }

    this.rightChartOptions.series[0]['data'] =
      this.data.series?.[1]?.data ?? [];
    this.rightChartOptions.colors = [this.data.series_colors[1]];
    this.rightChartOptions.xaxis.title.text = this.data.title;
    this.rightChartOptions.xaxis.categories = this.data.list;

    if (this.data.series.length === 2 && this.data.series_colors.length === 2) {
      this.leftChartOptions.series[0]['data'] =
        this.data.series?.[0]?.data ?? [];
      this.leftChartOptions.colors = [this.data.series_colors[0]];
      this.leftChartOptions.xaxis.title.text = this.data.title;
      this.leftChartOptions.xaxis.categories = this.data.list;
    }
  }
}
