<div *ngIf="$assistantEnabled | async">
  
  <div class="assistant-wrapper" [ngClass]="{ active: isActive }">
    <div class="assistant-view-wrapper">
      <div class="assistant-view">
          <div class="assistant-messages" #messagesList>
            <ng-container *ngFor="let message of messages" [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{'message': message, 'profile': profile}"></ng-container>

            <div class="typing-indicator" *ngIf="typing">
                <span></span>
                <span></span>
                <span></span>
            </div>
          </div>
        <input
          class="assistant-input"
          (keyup.enter)="sendMessage($event.target.value); $event.target.value = ''"
          placeholder="Ask me something..."
          >
      </div>
    </div>
  </div>
  <button
    class="assistant-fab-button"
    (click)="assistantPopover.hide(); toggleAssistant()"
    [tooltip]="assistantTooltipTemplate"
    #assistantPopover="bs-tooltip"
    containerClass="assistant-tooltip"
    placement="right"
    [isDisabled]="isActive"
  >
    <span
      class="icon"
      [ngClass]="{ 'icon-close': isActive, 'icon-vector': !isActive }"
    ></span>
  </button>
</div>

<ng-template #assistantTooltipTemplate
  ><div>
    <div><span class="icon icon-vector"></span></div>
    <p>
      Have a programme related question? Ask Vantage Assistant to guide you to
      find the right answers.
    </p>
  </div>
</ng-template>

<ng-template #content let-message="message" let-isAnswer="message.from.id == this.profile.email"  let-cls="isAnswer ? 'question' : 'answer'">
    <!-- {this.messages.map((m) => {
      const isAnswer = m.from.id === this.email;
      const cls = isAnswer ? 'question' : 'answer';
      return ( -->
        <div [ngClass]="['assistant-message', 'assistant-message-'+getMessageClassExt(message)]">
          <div [ngClass]="['assistant-'+ getMessageClassExt(message)]">
            <div [innerHTML]="getMarkdown(message.text)"
              class="assistant-message-text"
              ></div>
              <!-- innerHTML={this.getMarkdown(m.text)} --> 
            <!-- {this.getPredictions(m)} -->
            <!-- {this.exploreBtn(!isAnswer, m)} -->
            <ng-container *ngIf="message.channelData && message.channelData.predictions" [ngTemplateOutlet]="predictions" [ngTemplateOutletContext]="{'predictions': message.channelData.predictions}"></ng-container>

            <ng-container *ngIf="message.card" [ngTemplateOutlet]="focusbtn" [ngTemplateOutletContext]="{'message': message}"></ng-container>

            <div class="assistant-time">
              {{message.date.toLocaleString(locale, {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
              })}}
            </div>
          </div>
        </div>
      <!-- );
    })} -->
</ng-template>

<ng-template #focusbtn let-message="message">
  <button
    class="assistant-message-explore-button"
    (click)="this.explore(message.card)"
  >
        <span>Explore in focus</span> <span class="icon icon-arrow_forward" ></span>
  </button>
</ng-template>

<ng-template #predictions let-predictions="predictions">
  <ul class="assistant-predictions">
    
        <li class="assistant-predictions-item" *ngFor="let prediction of predictions">
          <a (click)="this.sentPrediction(prediction.sampleUtterance)">
            {{prediction.sampleUtterance}}
          </a>
        </li>
  </ul>
</ng-template>
