import * as jsonpatch from 'fast-json-patch';

import { ActivatedRoute, Router } from '@angular/router';
import {
  AppState,
  ICollection,
  instance,
  sidenav,
} from '@vantage-platform/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { removeCollections, updateCollections } from '@vantage-platform/store';

import { CollectionsDataService } from '@vantage-platform/store/lib/services/collections-data.service';
import { ConfirmComponent } from '../confirm/confirm.component';
import { InsightService } from '@vantage-platform/app-insight';
import { Store } from '@ngrx/store';
import { collectionsUniquenessValidator } from './unic-collection.validator';
import { take } from 'rxjs/operators';

@Component({
  selector: 'v4-collections-crud',
  templateUrl: './collections-crud.component.html',
  styleUrls: ['./collections-crud.component.scss'],
})
export class CollectionsCrudComponent implements OnInit {
  bsModalRef: BsModalRef;

  editMode = false;
  data: ICollection = { instanceID: 1, focusBoardTypeID: 2, name: '' };
  dataSnapshot: ICollection;

  myGroup: UntypedFormGroup;

  loading = false;

  constructor(
    public modalRef: BsModalRef,
    private collectionDS: CollectionsDataService,
    private store: Store<AppState>,
    private modalService: BsModalService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private track: InsightService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.myGroup = this.fb.group({
      name: [
        this.data.name,
        [
          Validators.required,
          Validators.minLength(4),
          collectionsUniquenessValidator(this.store),
        ],
      ],
    });

    this.store
      .select(instance)
      .pipe(take(1))
      .subscribe((ins) => {
        this.data = { ...this.data, instanceID: ins.id };
      });

    this.dataSnapshot = { ...this.myGroup.value };
  }

  createCollection(event: UIEvent) {
    event.preventDefault();
    const newCollection = {
      ...this.data,
      name: this.myGroup.controls.name.value,
    };

    if (this.myGroup.valid) {
      this.collectionDS.createCollections(newCollection).subscribe((r) => {
        this.store.dispatch(updateCollections({ payload: r }));
        this.track.event('createCollection');
        this.modalRef.hide();
      });
    }
  }

  saveCollection(event: UIEvent) {
    event.preventDefault();
    const patch = jsonpatch.compare(
      this.dataSnapshot,
      this.myGroup.value,
      false
    );
    if (this.myGroup.valid) {
      this.loading = true;
      this.collectionDS.saveCollections(this.data.id, patch).subscribe((r) => {
        this.store.dispatch(updateCollections({ payload: r }));
        this.track.event('saveCollection', { collectionId: this.data.id });
        this.modalRef.hide();
      });
    }
  }

  deleteCollection() {
    this.bsModalRef = this.modalService.show(ConfirmComponent, {
      initialState: {
        state: {
          title: 'Delete Collection',
          body: 'Are you sure you want to permanently delete this collection?',
          confirmText: 'Delete',
          cancelText: 'Cancel',
        },
      },
      class: 'modal-dialog-centered allign-footer-right modal-md',
    });
    this.bsModalRef.content.confirm.subscribe((s) => {
      if (s) {
        this.track.event('deleteCollection');
        this.loading = true;
        this.removeCollection();
      }
    });
  }

  removeCollection() {
    this.collectionDS.removeCollections(this.data.id).subscribe(
      (s) => {
        this.modalRef.hide();
        this.store.dispatch(removeCollections({ payload: this.data }));
        this.track.event('removeCollection', {
          collectionId: this.data.id,
        });

        let sn;
        this.store
          .select(sidenav)
          .pipe(take(1))
          .subscribe((nav) => {
            sn = nav;
          });

        this.router.navigate([`${this.data.instanceID}/${sn.path}`]);
      },
      (err) => {
        if (err.status === 409) {
          this.bsModalRef = this.modalService.show(ConfirmComponent, {
            initialState: {
              state: {
                title: 'Delete Collection',
                body: JSON.parse(err.error).ErrorMessage,
                confirmText: 'OK',
                hideCancelBtn: true,
              },
            },
            class: 'modal-dialog-centered allign-footer-right modal-md',
          });
          this.bsModalRef.content.confirm.subscribe((s) => {
            this.modalRef.hide();
          });
        }
      }
    );
  }
}
