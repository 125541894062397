import {
  APPS,
  AppState,
  IInstance,
  getCurrentApp,
  isHeaderVisible,
  setHeaderVisible,
} from '@vantage-platform/store';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { map, take } from 'rxjs/operators';

import { AuthService } from '@vantage-platform/auth';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { SideNavService } from '@vantage-platform/common-ui';
import { Store } from '@ngrx/store';
import { TeamsContextService } from '@vantage-platform/share-to-teams';
import gitInfo from './../../../git-version.json';

@Component({
  selector: 'vp-product-layout',
  templateUrl: './product-layout.component.html',
  styleUrls: ['./product-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductLayoutComponent implements OnInit, OnDestroy {
  isSideNavOpen = true;
  version = gitInfo.hash;
  $isHeaderVisible: Observable<boolean>;
  instance: IInstance;
  profile: any = {};
  isDesktop$ = this.breakpoints
    .observe([Breakpoints.Large, Breakpoints.XLarge])
    .pipe(map((q) => q.matches));
  showAssistant: boolean;

  constructor(
    public auth: AuthService,
    private store: Store<AppState>,
    public msal: MsalService,
    public sidenavService: SideNavService,
    public teamsCTX: TeamsContextService,
    public breakpoints: BreakpointObserver
  ) {
    this.$isHeaderVisible = this.store.select(isHeaderVisible);
  }

  ngOnInit(): void {
    if (this.auth.isLoggedIn) {
      const { name, emails } = this.auth.user.idTokenClaims as any;
      this.profile = {
        name,
        email: emails[0],
      };

      this.store.dispatch(setHeaderVisible({ isHeaderVisible: true }));

      this.store
        .select(getCurrentApp)
        .pipe(take(1))
        .subscribe(
          (currentApp) =>
            (this.showAssistant =
              !this.isCurrentAppProfiles(currentApp))
        );
    }
  }

  private isCurrentAppProfiles(app): boolean {
    return app.name.toLowerCase() === APPS.profiles;
  }

  ngOnDestroy(): void {}
}
