import { Component, OnInit } from '@angular/core';
import * as microsoftTeams from "@microsoft/teams-js";

@Component({
  selector: 'vp-teams-remove-tab',
  templateUrl: './teams-remove-tab.component.html',
  styleUrls: ['./teams-remove-tab.component.scss']
})
export class TeamsRemoveTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    microsoftTeams.app.initialize();
    microsoftTeams.authentication.notifySuccess();
  }
}
