import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShareCardGuard  {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    if (window['export']) {
      return true;
    }

    return of(this.router.createUrlTree(['']));
  }
}
