import { Component, Input, OnInit } from '@angular/core';
import { ICard, ICardConfig, ICardRow, ITile } from '../../models/i-card';

import { Tiles } from '../card/tiles';

@Component({
  selector: 'vantage-platform-card-row',
  templateUrl: './card-row.component.html',
  styleUrls: ['./card-row.component.scss'],
})
export class CardRowComponent {
  @Input() row: ICardRow;
  @Input() card: ICard;
  @Input() cardConfig: ICardConfig;

  public tiles = Tiles;

  constructor() {}

  getTileTemplate(tile: ITile) {
    return Tiles[tile.cardTileTypeID];
  }
}
