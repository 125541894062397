export const builtInButtons = {
  cancel: {
    classes: 'cancel-button',
    secondary: true,
    text: 'Done',
    type: 'cancel',
  },
  next: {
    text: 'Next',
    type: 'next',
  },
  back: {
    text: 'Back',
    type: 'back',
  },
};

export const defaultStepOptions = {
  classes: 'vantage-on-boarding',
  cancelIcon: {
    enabled: true,
  },
  canClickTarget: false,
  modal: true,
  when: {
    show: function () {
      const tour = this.getTour();

      // creating step pagination wrapper
      const pagination = document.createElement('div');
      pagination.className = 'pagination-wrapper';

      for (let i = 0; i < tour.steps.length; i++) {
        const index = tour.steps.indexOf(tour.getCurrentStep());

        // creating step indicator element
        const bullet = document.createElement('div');

        // skipping first step for the list, setting active
        bullet.className =
          i === index ? 'pagination-step-active' : 'pagination-step';

        // adding indicator for each step
        pagination.appendChild(bullet);
      }

      // placing pagination in step footer, before first button
      this.el
        .querySelector('.shepherd-footer')
        .insertBefore(pagination, this.el.querySelector('.shepherd-button'));
    },
  },
};

export type TourDeviceType = {
  deviceType: 'mobile';
} | {
  deviceType: 'tablet' | 'desktop';
  profilesSearchContainerType: 'mobile' | 'desktop'; // there is a separate breakpoint for indicators search and it should be considered
};

export interface TriggerSideMenuMobileParams {
  btnSelector: string;
  panelSelector: string;
  shouldOpen: boolean;
  platformRequiresToOpenSideNav: boolean;
}

export const triggerSideMenuMobile = ({
  btnSelector,
  panelSelector,
  shouldOpen,
  platformRequiresToOpenSideNav,
}: TriggerSideMenuMobileParams) => {
  return () =>
    new Promise((resolve: (val?: unknown) => void) => {
      if (platformRequiresToOpenSideNav) {
        // if side menu is hidden
        const isPanelClosed =
          window.getComputedStyle(document.querySelector(panelSelector), null)
            ?.width === '0px';
        if (isPanelClosed === shouldOpen) {
          const el = document.querySelector(btnSelector);
          (el as any)?.click();
          setTimeout(() => resolve(), 500);
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
};

export const triggerSideMenuDesktop = ({
  btnSelector,
  panelSelector,
  shouldOpen,
}) => {
  return () =>
    new Promise((resolve: (val?: unknown) => void) => {debugger
      // if side menu is hidden
      const isPanelClosed =
        !document.querySelector(panelSelector)?.classList?.contains('open');
      if (isPanelClosed && shouldOpen) {
        const el = document.querySelector(btnSelector);
        (el as any)?.click();
        setTimeout(() => resolve(), 500);
      } else {
        resolve();
      }
    });
};

export interface TriggerCardActionsModalParams {
  openBtnSelector: string;
  closeBtnSelector: string;
  modalSelector: string;
  shouldOpen: boolean;
  platformRequiresToOpenCardActionsModal: boolean;
}

export const triggerCardActionsModalCallback = ({
  openBtnSelector,
  closeBtnSelector,
  modalSelector,
  shouldOpen,
  platformRequiresToOpenCardActionsModal,
}) => (resolve: (val?: unknown) => void) => {
  if (platformRequiresToOpenCardActionsModal) {
    // if side menu is hidden
    const isModalClosed = [null, undefined].includes(
      document.querySelector(modalSelector)
    );

    if (isModalClosed === shouldOpen) {
      const el = document.querySelector(
        shouldOpen ? openBtnSelector : closeBtnSelector
      );
      (el as any)?.click();
      setTimeout(() => resolve(), 1000);
    } else {
      resolve();
    }
  } else {
    resolve();
  }
};

export const triggerCardActionsModal = (
  args: TriggerCardActionsModalParams
) => {
  return () => new Promise(triggerCardActionsModalCallback(args));
};

export interface StepPreparationsParams {
  shouldOpenSideNav: boolean;
  shouldOpenCardsMenu: boolean;
  platformRequiresToOpenSideNav: boolean;
  platformRequiresToOpenCardActionsModal: boolean;
}

export const triggerSideMenuMobileByState = ({shouldOpenSideNav, platformRequiresToOpenSideNav}) =>  triggerSideMenuMobile({
  btnSelector: '.side-toggle-btn',
  panelSelector: '.side-menu',
  shouldOpen: shouldOpenSideNav,
  platformRequiresToOpenSideNav,
});

export const triggerCardActionsModalByState = ({shouldOpenCardsMenu, platformRequiresToOpenCardActionsModal}) => triggerCardActionsModal({
  openBtnSelector: '.show-mobile-menu-btn',
  closeBtnSelector: '.bottom-sheet-button',
  modalSelector: 'vp-bottom-sheet',
  shouldOpen: shouldOpenCardsMenu,
  platformRequiresToOpenCardActionsModal,
});

export const triggerSideMenuDesktopByState = ({shouldOpen}) => triggerSideMenuDesktop({
  btnSelector: '.side-toggle-btn',
  panelSelector: 'vp-side-nav',
  shouldOpen
});

export const preparations = ({
  shouldOpenSideNav,
  shouldOpenCardsMenu,
  platformRequiresToOpenSideNav,
  platformRequiresToOpenCardActionsModal,
}: StepPreparationsParams, otherPreparations?: Promise<unknown>[]): Promise<void> => {
  return Promise.all([
    triggerSideMenuMobileByState({platformRequiresToOpenSideNav, shouldOpenSideNav})(),
    triggerCardActionsModalByState({platformRequiresToOpenCardActionsModal, shouldOpenCardsMenu})(),
    ...(otherPreparations ? otherPreparations : [])
  ]).then(() => {});
};
