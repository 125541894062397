<div class="tile_list">
  <h3>{{ data.title }}</h3>
  <div class="grid-list">
    <ng-container *ngFor="let listItem of data.list">
      <span class="has-tooltip"
        >{{ listItem.title }}

        <span
          class="megamenu-tooltip-container"
          [tooltip]="categoryTextTooltip"
          containerClass="category-tooltip"
          placement="bottom"
          triggers="click:mouseleave"
          ><i class="icon icon-info"></i
        ></span>

        <ng-template #categoryTextTooltip>
          <div [id]="'category' + listItem.legend">
            <h3>Source</h3>
            <p>
              {{ listItem.config.sourceName }}
            </p>
            <p>
              <a target="_blank" [href]="listItem.config.sourceDescription">
                {{ listItem.config.sourceDescription }}
              </a>
            </p>
          </div>
        </ng-template>
      </span>
      <strong>{{ listItem.legend | customThousandSeparator }}</strong>
    </ng-container>
  </div>
</div>
