import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { ITextTitleListData } from '../../models/i-card';

@Component({
  selector: 'vantage-platform-text-list',
  templateUrl: './text-list.component.html',
  styleUrls: ['./text-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextListComponent implements OnInit {
  @Input() data: ITextTitleListData;

  constructor() {}

  ngOnInit(): void {}
}
