import { APPS } from '@vantage-platform/store';
import { ITour } from './i-tour';
import { analyticsTour } from './analytics.tour';
import { focusTour } from './focus-tour';
import { TourDeviceType } from './default-step-options';
import { profilesTour } from "./profiles-tour";

export * from './default-step-options';
export * from './focus-tour';
export * from './i-tour';
export * from './analytics.tour';
export * from './profiles-tour';

export const APPS_TOUR_MAP = new Map<
  APPS,
  (deviceType: TourDeviceType) => ITour
>([
  [APPS.focus, focusTour],
  [APPS.analytics, analyticsTour],
  [APPS.profiles, profilesTour],
]);
