<div class="step-welcome">
  <div class="header">
    <span class="icon icon-{{ tour.icon }}"></span>
  </div>
  <div class="content">
    <div class="title">{{ tour?.welcomeMsg }}</div>
    <div class="text">{{ tour?.tourDescription }}</div>
    <div class="buttons">
      <button (click)="startTour()">Get Started</button>
    </div>
  </div>
</div>
