import { Injectable, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/vantage-focus/src/environments/environment';
import { IInstanceConfig, IInstanceConfigBody } from "../models";
import { Store } from '@ngrx/store';
import { AppState, IInstance, instance } from '@vantage-platform/store';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InstancesConfigService {
  private _config: IInstanceConfig;
  private instance: IInstance;

  get config(): IInstanceConfig {
    return this._config;
  }

  set config(value: IInstanceConfig) {
    this._config = value;
  }

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) {
    this.store.select(instance).subscribe((ins: IInstance) => this.instance = ins);
  }

  // get instance configuration
  public getConfig(configType: number = 7): Observable<IInstanceConfigBody> {
    const url = `${environment.config.url}instanceconfiguration/${this.instance.id}:${configType}`;
    return this.http.get<IInstanceConfigBody>(url);
  }
}
