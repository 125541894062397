import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OnBoardingComponent } from './on-boarding/on-boarding.component';
import { OnBoardingService } from './on-boarding.service';
import { OnBoardingServiceImpl } from './on-boarding-implementation.service';

@NgModule({
    imports: [CommonModule],
    exports: [OnBoardingComponent],
    declarations: [OnBoardingComponent],
    providers: [{ provide: OnBoardingService, useClass: OnBoardingServiceImpl }]
})
export class OnBoardingModule {}
