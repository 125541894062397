import { AppState, IApp, ISideNavItemModel } from '../../models';
import { Observable, forkJoin, of } from 'rxjs';

import { ConfigService } from '../../services/config.service';
import { Injectable } from '@angular/core';
import { ProfilesService } from '../../services/profiles.service';
import { SidenavState } from './store.reducers.sidenav';
import { map } from 'rxjs/operators';
import { InsightService } from '@vantage-platform/app-insight';

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  constructor(
    private configDS: ConfigService,
    private profilesService: ProfilesService,
    private track: InsightService,
  ) {}

  public resolveCategories(
    app: IApp,
    sidenav: SidenavState,
    storeState: AppState
  ): Observable<ISideNavItemModel[]> {
    const params = {
      'app-id': app.id,
      'instance-id': storeState.state.instance.id,
    };

    return this[sidenav.dataService].getCategories(app.id, params).pipe(
      map((categories: ISideNavItemModel[]) => {
        return this.updateCategories(categories, storeState);
      })
    );
  }

  private updateCategories(
    categories: ISideNavItemModel[],
    storeState: AppState
  ) {
    // keep side nav categories updated
    const areas = storeState.router.state.root.queryParams['area'];
    if (areas) {
      const ids = (Array.isArray(areas) ? areas : [areas]).map((p) => +p);
      return categories.map((c) => {
        return {
          ...c,
          isSelected: ids.includes(c.categoryID),
        };
      });
    }

    return categories || [];
  }

  logUserSignIn() {
    this.track.event('userSignIn');
  }
}
