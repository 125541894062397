import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

export const nullRoute = '/index';

@Injectable()
export class RoutingStateService {
  private history = [] as string[];
  private readonly maxTrackedHistory:number = 10

  constructor(
    private router: Router
  ) {}

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        if(this.history.length === this.maxTrackedHistory){
         this.history = this.history.splice(1,1);
        }

        this.history = [...this.history, urlAfterRedirects];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || nullRoute;
  }
}
