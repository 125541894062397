import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { IProfile } from '@vantage-platform/store';

@Component({
  selector: 'vp-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMenuComponent implements OnInit {
  @Input() public profile: IProfile;
  @Input() public version: string;
  @Output() onLogout = new EventEmitter();

  public isOpened: boolean;

  constructor() {}

  ngOnInit(): void {}

  logout($event) {
    this.onLogout.emit($event);
  }
}
