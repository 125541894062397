import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CollectionsCrudComponent } from '@vantage-platform/common-ui';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  private bsModalRef: BsModalRef;
  onToggle$ = new Subject<boolean>();

  constructor(private router: Router, private modalService: BsModalService) {}

  createCollection() {
    this.bsModalRef = this.modalService.show(CollectionsCrudComponent, {
      keyboard: true,
      class: 'modal-dialog-centered modal-md allign-footer-right',
    });
  }
}
