import { Injectable, OnInit } from '@angular/core';

import { environment } from 'apps/vantage-focus/src/environments/environment';
import { IPublicClientApplication, PublicClientApplication, AuthenticationResult, BrowserAuthError } from "@azure/msal-browser";

@Injectable()
export class AuthService implements OnInit {
  msal: IPublicClientApplication;
  loggedIn = false;

  constructor() {
    this.createMsalInstance(true);
  }

  ngOnInit() {
    this.checkAccount();
  }

  checkAccount() {
    const account = this.msal.getAllAccounts()[0];
    if (account) {
      this.msal.setActiveAccount(account);
    }
    this.loggedIn = !!this.msal.getActiveAccount();
  }

  public login(): Promise<any> {
    return this.msal
      .loginPopup({
        ...environment.share_config.msalScopes,
        ...environment.share_config.auth,
      })
      .then(
        (idToken) => {
          this.checkAccount();
          return idToken;
        },
        (err) => {
          console.error(err);
          return null;
        }
      );
  }

  public logout() {
    this.msal.logout();
  }

  private async createMsalInstance(force?: boolean) {
    this.msal = new PublicClientApplication(
      environment.share_config
    );

    this.msal.handleRedirectPromise()
      .catch(error => {
        if (error) {
          console.log(`teams auth error: ${error}`);
        }
      });
    this.checkAccount();

    if (!this.loggedIn && force) {
      return this.login();
    }
  }

  // MSAL call to get refresh token without login
  private acquireToken(
    resolve: (res: AuthenticationResult) => void,
    reject: (err: BrowserAuthError) => void,
    counter = 0
  ) {
    let account = this.msal.getAllAccounts().find(a => a.idTokenClaims.aud === environment.share_config.auth.clientId)
    this.msal
      .acquireTokenSilent({
        ...environment.share_config.msalScopes,
        ...environment.share_config.auth,
        account,
      })
      .then((accessToken) => {
        resolve(accessToken);
      })
      .catch(async (error) => {
        this.msal
          .acquireTokenPopup({
            ...environment.share_config.msalScopes,
            ...environment.share_config.auth,
            account,
          })
          .then((accessToken) => {
            resolve(accessToken);
          })
          .catch(async (err: BrowserAuthError) => {
            if (err.errorCode === 'popup_window_error') {
              reject(err);
            } else if (counter < 4) {
              await this.createMsalInstance(true);
              this.acquireToken(resolve, reject, ++counter);
            } else {
              reject(err);
            }
          });
      });
  }

  public getToken(): Promise<AuthenticationResult> {
    return new Promise((resolve, reject) => {
      this.acquireToken(resolve, reject);
    });
  }
}
