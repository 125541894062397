import { Pipe, PipeTransform } from '@angular/core';

import { IInstance } from '@vantage-platform/store';

@Pipe({
  name: 'disableInstance',
})
export class DisableInstancePipe implements PipeTransform {
  transform(items: IInstance[], instance: number): IInstance[] {
    return items.map((i) => {
      return { ...i, disabled: i.id === instance };
    });
  }
}
