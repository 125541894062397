import { HttpClient, HttpHeaders } from '@angular/common/http';

import { IInstanceConfig } from '../models';
import { Injectable } from '@angular/core';
import { InstancesConfigService } from './instances-config.service';

@Injectable({ providedIn: 'root' })
export class FlowService {
  private config: IInstanceConfig;

  constructor(
    private configService: InstancesConfigService,
    private http: HttpClient
  ) {
    this.config = this.configService.config;
  }

  // create the adaptive card
  private static getAdaptiveCard(
    message: string,
    buttonText: string,
    buttonUrl: string,
    cardImg: any
  ) {
    return {
      type: 'AdaptiveCard',
      body: [
        {
          type: 'Container',
          description: 'Main',
          items: [
            {
              type: 'TextBlock',
              text: 'Message: ' + message,
              size: 'Medium',
              wrap: true,
            },
            {
              type: 'Image',
              altText: 'Vantage Card',
              url: cardImg,
            },
          ],
        },
      ],
      actions: [
        {
          type: 'Action.OpenUrl',
          title: buttonText,
          url: buttonUrl,
        },
      ],
      $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
      version: '1.0',
    };
  }

  // create the main message
  private static getMessageBody(
    teamID: string,
    channelID: string,
    adaptiveCard: any
  ) {
    return {
      teamID: teamID,
      channelID: channelID,
      cardBody: adaptiveCard,
    };
  }

  // posting message to Flow API
  public postMessage(
    teamID: string,
    channelID: string,
    cardImg: any,
    message: string
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': this.config.mimeTypes.json,
      }),
    };
    const subject = 'Please look at the following card from Vantage';
    const buttonText = 'Open in Vantage Focus';
    const buttonUrl = 'https://vantage-focus-web-dev.broadreachanalytics.ch/';
    const adaptiveCard = FlowService.getAdaptiveCard(
      message,
      buttonText,
      buttonUrl,
      cardImg
    ); //generate the adaptive card
    const messageBody = FlowService.getMessageBody(
      teamID,
      channelID,
      adaptiveCard
    ); //create the main message with the adaptive card as attachment
    return this.http.post(
      this.config.flowConfig.apiFlow,
      messageBody,
      httpOptions
    ); //post the message to teams
  }
}
