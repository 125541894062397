import {
  BlobStorageClientFactory,
  BlobStorageRequest,
} from '../models/azure-storage';

import { BlobServiceClient } from '@azure/storage-blob';
import { InjectionToken } from '@angular/core';

export function azureBlobStorageFactory(): BlobStorageClientFactory {
  const buildConnectionString = (options: BlobStorageRequest) => {
    return (
      `BlobEndpoint=${options.storageUri};` +
      `SharedAccessSignature=${options.storageAccessToken}`
    );
  };

  return (options) =>
    BlobServiceClient.fromConnectionString(buildConnectionString(options));
}

export const BLOB_STORAGE_TOKEN = new InjectionToken<BlobStorageClientFactory>(
  'BLOB_STORAGE_TOKEN',
  { providedIn: 'root', factory: azureBlobStorageFactory }
);
