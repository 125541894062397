<div
  class="tile-text"
  [ngClass]="
    data.tileAligment ? 'align-items-' + data.tileAligment : 'align-items-left'
  "
>
  <h3 [ngStyle]="styles?.titleStyle">
    {{ data.title | customThousandSeparator }}
  </h3>
  <p [ngStyle]="styles?.legendStyle">{{ data.legend }}</p>
</div>
