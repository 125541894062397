import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private toastrService: ToastrService) {}

  public info(message?: string, title?: string, errorObject?: any) {
    this.toastrService.info(message, title);
  }

  public error(message?: string, title?: string, configOverrides?: Partial<IndividualConfig>) {
    this.toastrService.error(message, title, configOverrides);
  }

  public warning(message?: string, title?: string, errorObject?: any) {
    this.toastrService.warning(message, title);
  }

  public success(message?: string, title?: string, errorObject?: any) {
    this.toastrService.success(message, title);
  }
}
