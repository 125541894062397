import {
  IApp,
  IAppFocusBoard,
  ICollection,
  IInstance,
  IInstanceConfig,
  IParams,
  IPeriod,
  ISideNavItemModel,
  IUserConfigModel,
} from '../../models';
import { createAction, props } from '@ngrx/store';
import { State } from './store.reducer';

export const AppActionTypes = {
  selectPeriod: '[user.period] select user period',
};

export const loadConfig = createAction('[Store] Load App Config');

export const LoadUserAction = '[Store] Load User';
export const loadUser = createAction(LoadUserAction);

export const loadUserConfigSuccess = createAction(
  '[Store] Load User config',
  props<{ payload: Partial<State> }>()
);

export const resetLoadState = createAction('[Store] Set app loading state');

export const setAppLoading = createAction(
  '[Store] Set app loading state',
  props<{ appLoading: boolean }>()
);

export const detectApp = createAction(
  '[Store] get Active App',
  (payload?: string) => ({ payload })
);

export const setApp = createAction(
  '[Store] Set Active App',
  props<{ app: IApp }>()
);

export const pathcAppFocusboard = createAction(
  '[Store] Patch Active App focusboard',
  props<{ focusboard: IAppFocusBoard }>()
);

export const setApps = createAction(
  '[Store] Set available Apps',
  props<{ apps: IApp[] }>()
);

export const setInstance = createAction(
  '[Store] Set Instance config',
  props<{ instance: IInstance }>()
);

export const updateInstance = createAction(
  '[Store] Update Instance config',
  props<{ config: IInstanceConfig }>()
);

export const loadStoreSuccess = createAction(
  '[Store] Load Store Success',
  props<{ payload: any }>()
);

export const loadStoreFailure = createAction(
  '[Store] Load Store Failure',
  props<{ error: any }>()
);

export const setUser = createAction(
  '[user.focus] set user focus config',
  props<{ payload: IUserConfigModel }>()
);

export const setPeriods = createAction(
  '[user.period] set user period config',
  props<{ payload: IPeriod[] }>()
);

export const selectPeriod = createAction(
  '[user.period] select user period',
  props<{ periodType: string }>()
);

export const updateCollections = createAction(
  '[user.collections] update user collections list',
  props<{ payload: ICollection }>()
);
export const removeCollections = createAction(
  '[user.collections] remove collection from list',
  props<{ payload: ICollection }>()
);

export const selectCollection = createAction(
  '[sidenav.collection] select collection',
  props<{ id: number }>()
);

export const resetCollections = createAction(
  '[sidenav.collections] reset collections config'
);

export const setHeaderVisible = createAction(
  '[page.config] page header state',
  props<{ isHeaderVisible: boolean }>()
);

export const patchExpandMap = createAction(
  '[page.config] update expand map data state',
  props<{ expandMap: boolean }>()
);

export const getPeriods = createAction('[user.period] get user period config');
export const getCollections = createAction(
  '[user.collections] get user collections list'
);
export const getFocusAreas = createAction(
  '[user.focus] get user focus area config'
);

/** SIDE NAV */

export const loadSidenavStore = createAction('[Store] Load sidenav store');

export const loadSidenavStoreForApp = createAction(
  '[Store]  Load sidenav for application',
  props<{ payload: any }>()
);

export const setCategories = createAction(
  '[sidenav.categories] set categories array',
  props<{ payload: ISideNavItemModel[] }>()
);

export const resetLoadedState = createAction(
  '[sidenav.categories] reset loaded state'
);

export const setExtraUrlParams = createAction(
  '[sidenav.categories] set extra url params',
  props<{ payload: IParams }>()
);

export const setMultiple = createAction(
  '[sidenav.multiple] set multiple indicator',
  props<{ payload: boolean }>()
);

export const selectSideNavCategories = createAction(
  '[sidenav.categories] select categories',
  props<{ ids: number[] }>()
);

export const resetCategories = createAction(
  '[sidenav.categories] reset categories config'
);
