import { Action, createReducer, on } from '@ngrx/store';
import {
  clearBoardParams,
  patchAPICallParamsState,
  patchCardsItems,
  removeCardItem,
  resetCardsState,
  resetCardsUIState,
  setAPICallParamsState,
  setCardsItems,
  setCardsUIState,
  setDynamicLocationName,
  setEndOfPage,
  setIsLoadingState,
  updateCardsItem,
} from './cards-ui-state.actions';

import { ICard } from '../../models/i-card';
import { IParams } from '@vantage-platform/store';

export const CARDSUISTATE_FEATURE_KEY = 'cardsUiState';

/**
 * Interface for the 'CardsUiState' data used in
 *  - CardsUiState, and the reducer function
 *
 */

export interface BoardState {
  params: IParams;
  boardId: number;
  loaded: boolean;
  isLoading: boolean;
  boardEmpty: boolean;
}

export interface CardsUiState extends BoardState {
  cards: ICard[];
  endOfPage: boolean;
  dynamicLocationName?: string;
}

export interface CardsUiStatePartialState {
  readonly [CARDSUISTATE_FEATURE_KEY]: CardsUiState;
}

export const initialState: CardsUiState = {
  cards: [],
  params: undefined,
  boardId: undefined,
  loaded: false,
  isLoading: false,
  boardEmpty: false,
  endOfPage: false,
};

const storeReducer = createReducer(
  initialState,
  on(setCardsUIState, (state, { payload }) => ({
    ...state,
    ...payload,
  })),
  on(resetCardsUIState, (state) => ({
    ...initialState,
  })),

  on(removeCardItem, (state, { id }) => {
    const cards = state.cards.filter((c) => c.id !== id);
    return {
      ...state,
      cards: cards,
    };
  }),
  on(setCardsItems, (state, { payload }) => {
    return {
      ...state,
      isLoading: false,
      boardEmpty: false,
      cards: payload,
    };
  }),
  on(patchCardsItems, (state, { payload }) => {
    const cards = [...state.cards, ...payload];
    return {
      ...state,
      isLoading: false,
      cards,
    };
  }),
  on(updateCardsItem, (state, { payload }) => {
    const cards = [...state.cards];
    const index = cards.findIndex((c) => c.id === payload.id);
    const card = {
      ...cards[index],
      ...payload,
    };
    // if ([144, 143, 142, 141].includes(payload.id)) {
    //   // Temporary solution for VF-2687
    //   card.link = {
    //     question: 'test',
    //     appId: 2,
    //     config: {
    //       reportId: 'dc728d38-b6e9-4537-a22a-76aa750a3c01',
    //       groupId: 'f2aafe43-8245-4a74-ae75-9b79d688bdd0',
    //       applyIndicatorFilter: true,
    //     },
    //   };
    // }

    cards[index] = card;
    return {
      ...state,
      cards: cards,
    };
  }),
  on(removeCardItem, (state, { id }) => {
    const cards = state.cards.filter((c) => c.id !== id);
    return {
      ...state,
      cards: cards,
      boardEmpty: cards.length === 0 ? true : state.boardEmpty,
      endOfPage: cards.length === 0 ? false : state.endOfPage,
    };
  }),
  on(setAPICallParamsState, (state, { payload }) => {
    return {
      ...state,
      params: {
        ...payload,
        page: 1,
      },
    };
  }),
  on(patchAPICallParamsState, (state, { payload }) => {
    return {
      ...state,
      params: {
        ...state.params,
        ...payload,
      },
    };
  }),
  on(setIsLoadingState, (state, { payload }) => {
    return {
      ...state,
      boardEmpty: false,
      isLoading: payload,
    };
  }),
  on(clearBoardParams, (state) => {
    return {
      ...state,
      params: undefined,
      boardId: undefined,
      loaded: false,
      isLoading: false,
      boardEmpty: false,
      cards: [],
      endOfPage: false,
    };
  }),

  on(resetCardsState, (state) => {
    return {
      ...state,
      boardEmpty: false,
      cards: [],
      endOfPage: false,
    };
  }),

  on(setEndOfPage, (state, { payload }) => {
    return {
      ...state,
      endOfPage: payload,
    };
  }),

  on(setDynamicLocationName, (state, { payload }) => {
    return {
      ...state,
      dynamicLocationName: payload,
    };
  })
);

export function reducer(state: CardsUiState | undefined, action: Action) {
  return storeReducer(state, action);
}
