import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ITextTileData, ITextTileStyles, ITile } from '@vantage-platform/store';

@Component({
  selector: 'vantage-platform-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent implements OnInit {
  @Input() tile: ITile;
  data: ITextTileData;
  styles: ITextTileStyles = {};

  constructor() {}

  ngOnInit(): void {
    if (this.tile) {
      this.data = this.tile.data as ITextTileData;
      if (this.tile.itemStyle) {
        this.styles = JSON.parse(this.tile.itemStyle);
      }
    }
  }
}
