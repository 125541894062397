<div
  class="vp-col tile"
  *ngFor="let tile of row.tiles; let i = index"
  [ngClass]="'col-md-' + tile.width"
>
  <div
    class="tile-wrapper"
    [ngSwitch]="tiles[tile.cardTileTypeID]"
    [tooltip]="tileTooltip"
    [isDisabled]="!tile.Tooltip"
    isOpen="true"
    containerClass="tileTooltipContainer"
    placement="top"
    [adaptivePosition]="true"
  >
    <!--
    `} -->
    <!-- <div>{{ tile.cardTileTypeID }}</div>
    <ng-cotainer
      [ngTemplateOutlet]="tiles[tile.cardTileTypeID]"
      [ngTemplateOutletContext]="{ data: tile }"
    ></ng-cotainer> -->
    <ng-template ngSwitchCase="v4TileText" #v4TileText>
      <vantage-platform-text [tile]="tile"></vantage-platform-text>
    </ng-template>

    <ng-template ngSwitchCase="v4TileDynamicText" #v4TileDynamicText>
      <vantage-platform-dynamic [tile]="tile"></vantage-platform-dynamic>
    </ng-template>

    <ng-template ngSwitchCase="v4TileRadialBarChart" #v4TileRadialBarChart>
      <vantage-platform-radial-bar-chart
        [data]="tile.data"
      ></vantage-platform-radial-bar-chart>
    </ng-template>

    <ng-template ngSwitchCase="v4TileKpi" #v4TileKpi>
      <vantage-platform-kpi [data]="tile.data"></vantage-platform-kpi>
    </ng-template>

    <ng-template ngSwitchCase="v4TileTable" #v4TileTable>
      <vantage-platform-table [data]="tile.data"></vantage-platform-table>
    </ng-template>

    <ng-template ngSwitchCase="v4TileStackedBarChart" #v4TileStackedBarChart>
      <vantage-platform-stacked-bar-chart
        [data]="tile.data"
      ></vantage-platform-stacked-bar-chart>
    </ng-template>

    <ng-template ngSwitchCase="v4TileBulletList" #v4TileBulletList>
      <vantage-platform-bullet-list
        [data]="tile.data"
      ></vantage-platform-bullet-list>
    </ng-template>

    <ng-template ngSwitchCase="v4TileUrl" #v4TileUrl>
      <vantage-platform-url
        [data]="tile.data"
        [card]="card"
        [cardConfig]="cardConfig"
      ></vantage-platform-url>
    </ng-template>

    <ng-template ngSwitchCase="v4TileTextList" #v4TileTextList>
      <vantage-platform-text-list
        [data]="tile.data"
      ></vantage-platform-text-list>
    </ng-template>
    
    <ng-template ngSwitchCase="v4DualBarChart" #v4DualBarChart>
      <vantage-platform-dual-bar-chart
      [data]="tile.data"
      ></vantage-platform-dual-bar-chart>
    </ng-template>
    
    <ng-template ngSwitchCase="v4TileCategoryText" #v4TileCategoryText>
      <vantage-platform-category-text
      [data]="tile.data"
      ></vantage-platform-category-text>
    </ng-template>
    
    <ng-template ngSwitchCase="v4TileNGLineChartText" #v4TileNGLineChartText>
      <vantage-platform-line-chart
      [data]="tile.data"
      ></vantage-platform-line-chart>
    </ng-template>

    <ng-template #tileTooltip>
      <div class="tile-tooltip" *ngIf="tile.Tooltip">
        <div
          class="tile-tooltip-color-item"
          *ngFor="let range of tile.Tooltip.graphColorRanges"
          >
          <span class="color-circle-value">
            <span
              class="color-item-circle"
              [style.background-color]="range.Colour"
            ></span>
            {{ range.Message }}:
          </span>
          <span> {{ range.MinValue }}% - {{ range.MaxValue }}% </span>
        </div>
      </div>
    </ng-template>
  </div>
</div>
