import { Component } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'vp-not-supported-viewport',
  templateUrl: './not-supported-viewport.component.html',
  styleUrls: ['./not-supported-viewport.component.scss']
})
export class NotSupportedViewportComponent {

  constructor(private bsModalRef: BsModalRef) { }

  close() {
    this.bsModalRef.hide();
  }
}
