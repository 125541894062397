import { createAction, props } from '@ngrx/store';

import { CardsUiState } from './cards-ui-state.reducer';
import { ICard } from '../../models/i-card';
import { IParams } from '@vantage-platform/store';

export const setCardsUIState = createAction(
  '[CardsUiState] Set cards state',
  props<{ payload: CardsUiState }>()
);

export const resetCardsUIState = createAction(
  '[CardsUiState] Reset cards state'
);

export const patchCardsItems = createAction(
  '[CardsUiState] Patch cards items state',
  props<{ payload: ICard[] }>()
);

export const setCardsItems = createAction(
  '[CardsUiState] set card state',
  props<{ payload: ICard[] }>()
);

export const updateCardsItem = createAction(
  '[CardsUiState] update card state',
  props<{ payload: ICard }>()
);

export const removeCardItem = createAction(
  '[CardsUiState] remove card state',
  props<{ id: number; forceRefresh?: boolean }>()
);

export const setAPICallParamsState = createAction(
  '[CardsUiState] Set api call params state',
  props<{ payload: IParams }>()
);

export const patchAPICallParamsState = createAction(
  '[CardsUiState] Patch api call params state',
  props<{ payload: IParams }>()
);

export const setIsLoadingState = createAction(
  '[CardsUiState] Set isLoading state',
  props<{ payload: boolean }>()
);

export const clearBoardParams = createAction(
  '[CardsUiState] Set empty data to props and board id state'
);

export const resetCardsState = createAction(
  '[CardsUiState] Set empty data to cards list'
);

export const setEndOfPage = createAction(
  '[CardsUiState] Set EndOfPage state',
  props<{ payload: boolean }>()
);

export const setDynamicLocationName = createAction(
  '[CardsUiState] Set DynamicLocationName',
  props<{ payload: string, payload2: number }>()
);
