import {
  APPS,
  APPS_MAP,
  AppState,
  ICard,
  getStore,
} from '@vantage-platform/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { IInstanceConfig } from '../models';
import { Injectable } from '@angular/core';
import { InstancesConfigService } from './instances-config.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from 'apps/vantage-focus/src/environments/environment';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GraphService {
  public bearer = 'Bearer ';
  public config: IInstanceConfig;

  constructor(
    private http: HttpClient,
    private configService: InstancesConfigService,
    private state: Store<AppState>
  ) {}

  // create the adaptive card
  private getAdaptiveCard(
    message: string,
    buttonText: string,
    buttonUrl: string,
    imageURL: string
  ) {
    return {
      type: 'AdaptiveCard',
      body: [
        {
          type: 'Container',
          description: 'Main',
          items: [
            {
              type: 'Image',
              altText: 'Vantage Card',
              url: `${imageURL}`,
            },
            {
              type: 'TextBlock',
              text: `Message: ${message}`,
              size: 'Medium',
              wrap: true,
            },
          ],
        },
      ],
      actions: [
        {
          type: 'Action.OpenUrl',
          title: buttonText,
          url: buttonUrl,
        },
      ],
      $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
      version: '1.0',
    };
  }

  // create the main message
  private getMessageBody(
    attachmentID: string,
    subject: string,
    adaptiveCard: any
  ) {
    return {
      subject: subject,
      body: {
        contentType: this.config.mimeTypes.html,
        content: '<attachment id="' + attachmentID + '"></attachment>',
      },
      attachments: [
        {
          id: attachmentID,
          contentType: this.config.mimeTypes.adaptiveCard,
          content: adaptiveCard,
        },
      ],
    };
  }

  // generate a unique id for the attachment
  public generateAttachmentId() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  // graph api call to get the users registered teams
  public getUserTeams(token: string): Observable<any> {
    this.config = this.configService.config;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': this.config.mimeTypes.json,
        Authorization: this.bearer + token,
      }),
    };
    return this.http.get<any>(
      this.config.graphConfig.defaultUrl + this.config.graphConfig.teamsURL,
      httpOptions
    );
  }

  // graph api call to get the users registered channels
  public getTeamChannel(token: string, teamID: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': this.config.mimeTypes.json,
        Authorization: this.bearer + token,
      }),
    };
    return this.http.get<any>(
      this.config.graphConfig.defaultUrl + `/teams/${teamID}/channels`,
      httpOptions
    );
  }

  // graph api call to post the message to teams
  public postMessage(
    token: string,
    form,
    imageURL: string,
    card: ICard
  ): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': this.config.mimeTypes.json,
        Authorization: this.bearer + token,
      }),
    };
    const attachmentID = this.generateAttachmentId();
    const subject = 'Please look at the following card from Vantage';
    let buttonText = 'Open in ';
    let buttonUrl = environment.share_config.auth.redirectUri;

    this.state
      .select(getStore)
      .pipe(take(1))
      .subscribe((state) => {
        const app = APPS_MAP.get(
          APPS[state.apps.find((a) => a.id === card.appID).name.toLowerCase()]
        );
        buttonText += app.appTitle;

        if (app.appConfig && app.appConfig.reportPath) {
          // analytics report
          buttonUrl += `/${state.instance.id}${app.appConfig.reportPath}/${card.boardId}/${card.id}`;
        } else if (app.appTitle === 'Vantage Profiles') {
          let params = new URL(window.location.href).searchParams;
          buttonUrl += `/${state.instance.id}${
            app.path
          }?displaymap=false&gl=${card.geoLocation}&area=${
            card.categoryID
          }&page=1&pglid=${params.get('pglid')}`;
        } else {
          // focus
          buttonUrl += `/${state.instance.id}${app.path}?card=${card.id}&period=${card.periodType}`;
        }
      });

    //generate the adaptive card
    const adaptiveCard = this.getAdaptiveCard(
      form.message,
      buttonText,
      buttonUrl,
      imageURL
    );

    //create the main message with the adaptive card as attachment
    const messageBody = this.getMessageBody(
      attachmentID,
      subject,
      JSON.stringify(adaptiveCard)
    );

    //post the message to teams
    return this.http.post(
      this.config.graphConfig.betaUrl +
        `/teams/${form.team}/channels/${form.channel}/messages`,
      messageBody,
      httpOptions
    );
  }
}
