import { Component, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'v4-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  state: {
    title: string;
    body: string;
    confirmText: string;
    cancelText?: string;
    hideCancelBtn?: boolean;
  };

  confirm: BehaviorSubject<boolean>;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.confirm = new BehaviorSubject(null);
  }

  confirmAction() {
    this.confirm.next(true);
    this.bsModalRef.hide();
  }

  cancelAction() {
    this.confirm.next(false);
    this.bsModalRef.hide();
  }
}
