import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AppState, getCurrentApp, instance } from '@vantage-platform/store';
import { combineLatest, map } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor(
    private store: Store<AppState>,
    private titleService: Title)
  {}

  initPageTitle() {
    combineLatest([
      this.store.select(instance),
      this.store.select(getCurrentApp),
    ])
      .pipe(
        map(([ins, app]) => {
          let title = environment.title;
          if (ins && app) {
            return `${title} - ${app.name} - ${ins.name}`;
          } else if (ins) {
            return `${title} - ${ins.name}`;
          }

          return title;
        })
      )
      .subscribe((t) => {
        this.titleService.setTitle(t);
      });
  }
}
