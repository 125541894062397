import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ICard, ICardConfig, ICardRow } from '../../models/i-card';

import { CardsService } from '../../cards/cards.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { getConfigByAppId } from '../../cards/cards';
import { APPS } from '@vantage-platform/store';
import { InsightService } from "@vantage-platform/app-insight";

@Component({
  selector: 'vantage-platform-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() config: ICardConfig;
  @Input() card: ICard;
  @Output() focused = new EventEmitter();
  private subs: Subscription;

  @HostBinding('class.rewards') get r() {
    return this.card.reward;
  }

  @HostBinding('class.focus') get f() {
    return this.card.isFocusCard;
  }

  @HostBinding('class.no-bottom-radius') get l() {
    return this.card.link;
  }

  constructor(
    private cardsService: CardsService,
    private router: Router,
    private insight: InsightService
  ) {}

  ngOnInit(): void {
    this.resolveCardData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['card'] && !changes['card'].firstChange) {
      const { id, boardId, geoLocation } = changes['card']
        .previousValue as ICard;
      if (
        id !== this.card.id ||
        boardId !== this.card.boardId ||
        geoLocation !== this.card.geoLocation
      ) {
        // need to reload card
        this.subs?.unsubscribe();
        this.resolveCardData();
      }
    }
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  resolveCardData() {
    const func = this.card.geoLocation.includes('Profiles_Board_Card')
      ? this.cardsService.getCardByGeoLocation(
          this.card.id,
          this.card.boardId,
          this.cardsService.dynamicLocationName
        )
      : this.cardsService.getCard(this.card.id, this.card.boardId);

    if (this.card && !this.card.rows) {
      this.subs = func.subscribe((data) => {
        if (data && data.id) {
          this.card = { ...data, ...this.card };
        }
      });
    }
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  getLinkConfig(appId: number) {
    return getConfigByAppId(appId);
  }

  navigateToReport(card: ICard) {
    this.insight.event('viewCardInAnalytics', {
      cardId: card.id,
      categoryId: card.categoryID,
      cardTitle: card.title,
      question: card.question,
      questionId: card.questionID,
      targetAnalyticsReportReportId: card.link.config.reportId,
      targetAnalyticsReportGroupId: card.link.config.groupId,
      targetAnalyticsReportFilter: card.link.config.filter,
    });
    this.router.navigate(
      [
        `${card.instanceID}/analytics/report/${card.link.config.groupId}/${card.link.config.reportId}`,
      ],
      {
        queryParams: {
          filter: card.link.config.filter,
          pageName: card.link.config.pageName
        },
      }
    );
  }

  focusCard() {
    this.cardsService.setFocusCard(this.card, this.config);
    this.focused.emit();
  }

  rowHasData(row:ICardRow): boolean {
    if(this.isProfilesApp()){
      return row.tiles.some((a) => a.data['title'] !== ' ');
    }

    return row.tiles && row.tiles.length > 0;
  }

  private isProfilesApp(): boolean {
    const cardConfig = getConfigByAppId(this.card.appID);
    return cardConfig.indicatorClass === APPS.profiles;
  }
}
