import {
  AppState,
  IInstance,
  IInstanceConfig,
  IInstanceConfigData,
  IPeriod,
  ISideNavItemModel,
  IUser,
} from '@vantage-platform/store/lib/models';
import { IApp, IAppFocusBoard } from '../models/i-app';
import { Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'apps/vantage-focus/src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(private http: HttpClient, public store: Store<AppState>) {}

  getCategories(appId: number, params): Observable<ISideNavItemModel[]> {
    const language = 1;
    // const url = `apps/${appId}:${language}/categories`;
    const url = `me/instance-app-categories`;

    return this.http.get(`${environment.api.url}${url}`, {
      params,
    }) as Observable<ISideNavItemModel[]>;
  }

  getPeriodsData(app: IApp, instance: IInstance): Observable<IPeriod[]> {
    const defaultPeriod: IPeriod[] = [
      {
        PeriodType: '',
        PeriodName: '',
        PeriodNumber: 0,
        YearNumber: 0,
        YearName: null,
        active: true,
      },
    ];

    if (typeof instance.config === 'undefined')
      return of(defaultPeriod) as Observable<IPeriod[]>;

    const url = `me/boards/${app.defaultBoardID}/availableperiods`;

    return this.http.get(`${environment.api.url}${url}`).pipe(
      map((p: IPeriod[]) => {
        return p;
      })
    );
  }

  getUser(): Observable<IUser> {
    const url = 'me';
    return this.http.get(`${environment.config.url}${url}`).pipe(
      map((p: IUser) => {
        return p;
      })
    );
  }

  validateAccess(params) {
    const url = 'access';
    return this.http.get(`${environment.config.url}${url}`, {
      params,
      observe: 'response',
    });
  }

  getAppFeature(app: IApp) {
    const url = 'app-features';
    return this.http.get(`${environment.config.url}${url}`, {
      params: { appID: `${app.id}` },
    });
  }

  getInstances(): Observable<IInstance[]> {
    const url = 'me/instances?language-id=1';
    return this.http.get(`${environment.config.url}${url}`).pipe(
      map((p: IInstance[]) => {
        return p;
      })
    );
  }

  getInstanceConfig(instance: IInstance): Observable<IInstanceConfig> {
    // 13 default instance config type
    const url = `instanceconfiguration/${instance.id}:13`;
    return this.http.get(`${environment.config.url}${url}`).pipe(
      map((r: IInstanceConfig) => {
        let newIns = { ...r };
        newIns.data = JSON.parse(r.value) as IInstanceConfigData;

        return newIns;
      })
    );
  }

  getMeApps(instanceId: number): Observable<IApp[]> {
    const url = `me/apps?language-id=1&instance-id=${instanceId}`;
    return this.http.get(`${environment.config.url}${url}`).pipe(
      map((p: IApp[]) => {
        return p;
      })
    );
  }

  getApps(): Observable<IApp[]> {
    const url = `apps?language-id=1`;
    return this.http.get(`${environment.config.url}${url}`).pipe(
      map((p: IApp[]) => {
        return p;
      })
    );
  }

  getAppDefaultFocusboard(
    instanceId: number,
    appId: number
  ): Observable<IAppFocusBoard> {
    const url = `me/instance-app-default-board/${instanceId}:${appId}`;

    return this.http.get(`${environment.api.url}${url}`) as Observable<
      IAppFocusBoard
    >;
  }
}
