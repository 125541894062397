<form
  [formGroup]="myGroup"
  (keydown.enter)="editMode ? saveCollection($event) : createCollection($event)"
  (ngSubmit)="editMode ? saveCollection($event) : createCollection($event)"
>
  <div class="modal-header">
    <h4 class="v4-collections-crud-header pull-left">
      {{ editMode ? 'Edit Collection' : 'Create New Collection' }}
    </h4>
    <button aria-label="Close" class="text" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-floating">
      <input
        type="email"
        formControlName="name"
        ngDefaultControl
        placeholder="Collection Name"
        class="form-control"
        [class.error]="myGroup.get('name').errors?.unic"
      />
      <label for="floatingInput">Collection Name</label>
      <div
        class="assistive_text_container"
        *ngIf="myGroup.get('name').errors?.unic"
      >
        <span class="assistive_text">{{
          myGroup.get('name').errors?.unic ? 'The name already exists' : ''
        }}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="text" *ngIf="editMode" (click)="deleteCollection()">
      Delete collection
    </button>

    <div class="buttons-right-container">
      <button class="text" (click)="modalRef.hide()">Cancel</button>
      <button
        type="submit"
        *ngIf="!editMode"
        [disabled]="!myGroup.valid"
        (click)="createCollection($event)"
      >
        Create
      </button>
      <button
        type="submit"
        *ngIf="editMode"
        [disabled]="!myGroup.valid"
        (click)="saveCollection($event)"
      >
        Save
      </button>
    </div>
  </div>
  <vp-loading-indicator *ngIf="loading"></vp-loading-indicator>
</form>
