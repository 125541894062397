<div>
  <div class="modal-header">
    <h4 class="pull-left">{{ state.title }}</h4>
    <button aria-label="Close" class="text" (click)="cancelAction()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">{{ state.body }}</div>
  <div class="modal-footer">
    <div class="buttons-right-container">
      <button
        class="text"
        *ngIf="!state.hideCancelBtn"
        (click)="cancelAction()"
      >
        {{ state.cancelText }}
      </button>
      <button *ngIf="state.confirmText" (click)="confirmAction()">
        {{ state.confirmText }}
      </button>
    </div>
  </div>
</div>
<div class="backdrop"></div>
