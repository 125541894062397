<div class="focus-button-container">
  <h4>{{ card.question }}</h4>
  <button
    class="text focus-button"
    *ngIf="
      !card.isFocusCard && config && (card.hasFocusBoard || config?.showButton)
    "
    (click)="focusCard()"
  >
    {{ config.focusButtonTitle }}
  </button>
</div>
