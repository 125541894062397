import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ICard, ICardConfig, IUrlTile } from '@vantage-platform/store';

import { CardsService } from '../../cards/cards.service';

@Component({
  selector: 'vantage-platform-url',
  templateUrl: './url.component.html',
  styleUrls: ['./url.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UrlComponent implements OnInit {
  @Input() data: IUrlTile;
  @Input() card: ICard;
  @Input() cardConfig: ICardConfig;

  constructor(private cardService: CardsService) {}

  ngOnInit(): void {}

  navigateToReport() {
    this.cardService.setFocusCard(this.card, this.cardConfig);
  }
}
