import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { APIS } from 'apps/vantage-focus/src/environments/environment';
import { AuthService } from './auth.service';
import { Inject, Injectable, Optional } from "@angular/core";
import { Observable, of } from "rxjs";
import { LOCAL_STORAGE, LOGGED_IN_AT, SESSION_DURATION } from "./constants";
import { Router } from '@angular/router';

/** Temporary leave this CLASS as MsalInterceptor to avoid conflicts. */
@Injectable()
export class MsalInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private router: Router,
    @Inject(LOCAL_STORAGE) @Optional() private localStorage: Storage,
  ) {
    if (!this.localStorage) {
      this.localStorage = window.localStorage;
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    if (this.auth.checkIfSessionExpired()) {
      this.auth.logOut(window.location.origin + this.router.url);
      return of();
    }

    this.localStorage.setItem(LOGGED_IN_AT, this.auth.user.idTokenClaims.iat.toString());
    const reqApi = APIS.find((i) => req.url.includes(i.url));
    const header = {};

    if (reqApi && reqApi['key']) {
      header['Ocp-Apim-Subscription-Key'] = reqApi['key'];
    }

    const requestClone = req.clone({
      setHeaders: header,
    });
    return next.handle(requestClone);
  }
}
