import {
  ApexAxisChartSeries,
  ApexChart,
  ApexLegend,
  ApexMarkers,
  ApexStroke,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ngx-apexcharts';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

import { INGLineChart } from '../../models/i-card';

@Component({
  selector: 'vantage-platform-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent implements OnInit {
  constructor() {}
  @ViewChild('graph') graph: ChartComponent;
  @Input() data: INGLineChart;

  xaxis: ApexXAxis;
  series: ApexAxisChartSeries;
  colors: any[];

  chart: ApexChart = {
    height: 350,
    type: 'line',
    toolbar: {
      show: false,
    },
  };

  markers: ApexMarkers = {
    size: 4,
    strokeWidth: 2,
    colors: [],
    strokeColors: [],
    hover: {
      size: 6,
    },
  };

  legend: ApexLegend = {
    show: true,
    position: 'top',
    horizontalAlign: 'left',
    floating: false,
    height: 30,
    itemMargin: {
      horizontal: 10,
      vertical: 0,
    },
    customLegendItems: null,
  };

  yaxis: ApexYAxis = {
    show: true,
    showAlways: true,
    floating: false,
    forceNiceScale: false,
    labels: {
      minWidth: 79,
      maxWidth: 80,
      offsetX: -40,
      offsetY: 0,
      align: 'left',
      style: {
        colors: '#a8a8aa',
      },
    },
  };
  stroke: ApexStroke = {
    width: 3,
    curve: 'straight',
    dashArray: [],
  };

  ngOnInit(): void {
    this.xaxis = {
      axisTicks: {
        show: false,
      },
      categories: this.data.categories,
      labels: {
        style: {
          colors: '#a8a8aa',
        },
      },
    };

    this.series = this.data.series;
    this.colors = this.data.colors;
    this.setStrokeConfig(this.data);
  }

  private setStrokeConfig(data: INGLineChart) {
    this.markers.colors = this.getMarkerColors(data); // required for stroke option
    this.markers.strokeColors = this.getMarkerStrokeColors(data); // required for stroke option
    this.stroke.dashArray = this.getLineStroke(data); //required for stroke option
  }

  private getMarkerColors(data: INGLineChart) {
    const color = 'white';

    return this.data.colors.map((c, i) => {
      if (data.series[i].stroke) {
        return color;
      }

      return c;
    });
  }

  private getMarkerStrokeColors(data: INGLineChart) {
    const color = 'white';

    return this.data.series.map((s, i) => {
      if (s.stroke) {
        return this.data.colors[i];
      }

      return color;
    });
  }

  private getLineStroke(data: INGLineChart) {
    return this.data.series.map((s, i) => {
      if (s.stroke) {
        return 8; // set stroke width
      }

      return 0; // no stroke - line without dashes
    });
  }
}
