import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { GraphService } from './graph.service';
import {
  BlobContainerRequest,
  BlobFileRequest,
  BlobStorageClientFactory,
  BlobStorageRequest,
} from '../models/azure-storage';
import { BLOB_STORAGE_TOKEN } from './token';
import {
  BlobServiceClient,
  BlockBlobClient,
  ContainerClient,
} from '@azure/storage-blob';
import { UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ICard } from '@vantage-platform/store';
import { NotificationService } from '@vantage-platform/toastr';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { InsightService } from '@vantage-platform/app-insight';
import { InstancesConfigService } from './instances-config.service';
import { IChannel, IInstanceConfig, ITeam } from '../models';
import { environment } from 'apps/vantage-focus/src/environments/environment';
import { BrowserAuthError, AuthenticationResult } from "@azure/msal-browser";

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  public authToken: string;
  public teams: BehaviorSubject<ITeam[]> = new BehaviorSubject([]);
  public channels: BehaviorSubject<IChannel[]> = new BehaviorSubject([]);
  public closeModal = new Subject();
  private config: IInstanceConfig;

  constructor(
    @Inject(BLOB_STORAGE_TOKEN)
    private getBlobClient: BlobStorageClientFactory,
    private authService: AuthService,
    private graphService: GraphService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private configService: InstancesConfigService,
    private track: InsightService,
    private http: HttpClient
  ) {}

  // to change Blob to File
  static blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return b as File;
  }

  public handleError(
    err: HttpErrorResponse | BrowserAuthError,
    message: string = 'error'
  ) {
    console.error(`${message}: `, err);
    if ('errorCode' in err && err.errorCode === 'popup_window_error') {
      this.notificationService.error(
        'The pop-up for sharing to Teams was blocked.  Please check your browser for pop-up warnings.'
      );
    } else {
      this.notificationService.error(
        'There was an error connecting to Teams - please try sharing the card again.'
      );
    }
    this.closeModal.next(undefined);
  }

  public async shareToTeams(
    element: HTMLElement,
    form: UntypedFormGroup,
    card: ICard
  ) {
    const frm: any = form.value;
    const fileName: string = new Date().getTime().toString();
    const abc = this.config.azure_blob_config;

    const blobFileRequest: BlobFileRequest = {
      storageUri: `https://${abc.accountName}.blob.core.windows.net/${abc.folderName}/`,
      storageAccessToken: abc.sasToken,
      containerName: abc.container,
      filename: fileName,
    };
    const blockBlobClient = this.getBlockBlobClient(blobFileRequest);

    let imgBlob = await this.getCardAsImage(card, fileName);
    let file = this.convertBlobToFile([imgBlob], fileName);
    await this.uploadFile(blockBlobClient, file);
    this.postGraph(frm, fileName, card);
  }

  public getTeams(): void {
    this.config = this.configService.config;
    //silently refresh user token
    this.authService.getToken().then(
      (token: AuthenticationResult) => {
        this.authToken = token.accessToken;
        //get the users registered teams list via graph api
        this.graphService.getUserTeams(token.accessToken).subscribe(
          (data) => {
            this.teams.next(data.value);
            this.spinner.hide('share-spinner');
          },
          (er) => this.handleError(er, 'getTeams error')
        );
      },
      (err) => this.handleError(err)
    );
  }

  // Create a snapshot of the div to share
  public async getCardAsImage(card: ICard, fileName: string) {
    this.spinner.show('share-spinner');

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' }); // ... Set content type to JSON
    headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, DELETE, PUT'
    );
    headers.append(
      'Access-Control-Allow-Headers',
      'X-Requested-With, Content-Type, Content-Disposition, Origin, Authorization, Accept, Client-Security-Token, Accept-Encoding'
    );
    let options = {
      headers,
      responseType: 'blob' as 'json',
    };

    const body = {
      page: `${window.location.origin}/sharecard`,
      buckets: {
        card: card,
      },
      excludeArgs: ['--disable-http2'],
      skipTimeout: true,
      viewportW: 1040,
    };

    return this.http
      .post(
        environment.share_config.share_screenshot_api,
        JSON.stringify(body),
        options as any
      )
      .toPromise();
  }

  public test(data, name) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    var blob = new Blob(data, { type: 'octet/stream' }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  public getChannels(teamId) {
    this.spinner.show('share-spinner');
    //silently refresh user token
    this.authService.getToken().then(
      (token: AuthenticationResult) => {
        this.authToken = token.accessToken;
        //get the users channels via graph api
        this.graphService.getTeamChannel(this.authToken, teamId).subscribe(
          (data) => {
            this.channels.next(data.value);
            this.spinner.hide('share-spinner');
          },
          (er) => this.handleError(er, 'getChannels error')
        );
      },
      (err) => {
        this.handleError(err, 'getToken error');
      }
    );
  }

  private getBlockBlobClient(request: BlobFileRequest): BlockBlobClient {
    const containerClient: ContainerClient = this.getContainerClient(request);
    return containerClient.getBlockBlobClient(request.filename);
  }

  private getContainerClient(request: BlobContainerRequest): ContainerClient {
    const blobServiceClient: BlobServiceClient = this.buildClient(request);
    return blobServiceClient.getContainerClient(request.containerName);
  }

  private buildClient(options: BlobStorageRequest): BlobServiceClient {
    return this.getBlobClient(options);
  }

  private async uploadFile(blockBlobClient: BlockBlobClient, file: File) {
    try {
      blockBlobClient
        .uploadBrowserData(file, {
          blobHTTPHeaders: {
            blobContentType: file.type,
          },
        })
        .then((res) => {
          return res;
        });
    } catch (error) {
      this.notificationService.error(
        'Unable to retrieve data from Teams. Please try sharing card again.'
      );
      this.closeModal.next(undefined);;
      console.error(error);
    }
  }

  // Post message via the graph api
  private postGraph(form, imgName: string, card: ICard) {
    try {
      const conf = this.config.azure_blob_config;
      const imgURL = `https://${conf.accountName}.blob.core.windows.net/${conf.folderName}/${conf.container}/${imgName}`;

      return this.graphService
        .postMessage(this.authToken, form, imgURL, card)
        .subscribe(
          (data) => {
            this.track.event('shareToTeams', { cardId: card.id });
            this.notificationService.error('Insight card shared with team');
            this.closeModal.next(undefined);;
            return data;
          },
          (er) => this.handleError(er, 'postMessage error')
        );
    } catch (error) {
      this.notificationService.error(
        'Unable to retrieve data from Teams. Please try sharing card again.'
      );
      this.closeModal.next(undefined);
      console.error(error);
    }
  }

  private convertBlobToFile(blobArr, fileName) {
    let file;

    if (!navigator.msSaveBlob) {
      file = new File(blobArr, fileName, { type: 'image/jpeg' });
    } else {
      file = new Blob(blobArr, { type: 'image/jpeg' });
      file = TeamsService.blobToFile(file, fileName);
    }

    return file;
  }
}
