<div class="header-content" *ngIf="profile.name">
  <form>
    <ngx-select
      *ngIf="showInstanceSelector"
      [items]="
        instances$ | async | disableInstance: (selectedInstance$ | async).id
      "
      [defaultValue]="(selectedInstance$ | async).id"
      optionTextField="name"
      optionValueField="id"
      [ngModelOptions]="{ standalone: true }"
      [noAutoComplete]="true"
      [disabled]="!!(app$ | async)"
      [multiple]="false"
      [allowClear]="false"
      (select)="instanceSelect($event)"
      [ngModel]="(selectedInstance$ | async).id"
      class="ngx-select__clear"
    >
    </ngx-select>
  </form>
  <button
    class="text round btn-tour"
    *ngIf="$tourAvailable | async"
    (click)="openGuideTourModal()"
  >
    <span class="icon icon-help"></span>
  </button>
  <vp-profile-menu
    [profile]="profile"
    [version]="appVersion"
    (onLogout)="logOut()"
  >
  </vp-profile-menu>
</div>
