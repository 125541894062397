import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AppState, isAppAvailable } from '@vantage-platform/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { ConfigService } from '@vantage-platform/store/lib/services/config.service';
import { Injectable } from '@angular/core';
import { LoadingIndicatorService } from '@vantage-platform/store/lib/services/loading-indicator/loading-indicator.service';
import { NotificationService } from '@vantage-platform/toastr';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AppGuard  {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private notification: NotificationService,
    private loadingIndicatorService: LoadingIndicatorService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    this.loadingIndicatorService.show();

    return this.store
      .select(isAppAvailable, {
        app: next.routeConfig.path,
      })
      .pipe(
        filter((r) => !!r),
        tap((app) => {
          if (!app?.isAppAvailable) {
            this.notification.error(
              `User does not have permission to access this application`
            );
            this.router.navigate([`/${app?.instanceRef?.id}`]);
          }
        }),
        switchMap((app) => {
          console.log('guard', app);
          this.loadingIndicatorService.show(app);

          if (!app || !app.isAppAvailable) {
            return of(false);
          }

          return of(app.isAppAvailable);
        })
      );
  }
}
