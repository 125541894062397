import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ICard, ICardConfig } from '../../models/i-card';
import { CardsService } from '../../cards/cards.service';

@Component({
  selector: 'vantage-platform-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionComponent {
  @Input() public config: ICardConfig;
  @Input() card: ICard;

  constructor(private cardsService: CardsService) {}

  focusCard() {
    this.cardsService.setFocusCard(this.card, this.config);
  }
}
