<ngx-spinner
  [fullScreen]="true"
  bdColor="rgba(255,255,255,0.8)"
  type="ball-clip-rotate"
  [color]="appConfig?.color"
  size="large"
  class="loading-screen"
  name="loading-screen"
>
  <div class="icon icon-{{ appConfig?.indicator }}"></div>
  <span class="spinner-msg">Loading {{ appConfig?.title }}</span>
</ngx-spinner>
