import { APPS, APPS_MAP } from './store.constants';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import {
  AppActionTypes,
  LoadUserAction,
  detectApp,
  loadStoreSuccess,
  loadUser,
  loadUserConfigSuccess,
  patchExpandMap,
  selectPeriod,
  setApp,
  setExtraUrlParams,
  setInstance,
  updateInstance,
} from './store.actions';
import { AppState, IApp, IAppFocusBoard, ICollection } from '../../models';
import {
  RouterNavigatedPayload,
  SerializedRouterStateSnapshot,
  routerNavigationAction,
} from '@ngrx/router-store';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { CollectionsDataService } from '../../services/collections-data.service';
import { ConfigService } from '../../services/config.service';
import { DEFAULT_PERIOD_TYPE } from '../../constants';
import { HelpersService } from './helpers.service';
import { Inject, Injectable, Optional } from '@angular/core';
import { LoadingIndicatorService } from '../../services/loading-indicator/loading-indicator.service';
import { SidenavState } from './store.reducers.sidenav';
import { Store } from '@ngrx/store';
import { LOCAL_STORAGE, SIGN_IN_ACTION } from '@vantage-platform/auth';

@Injectable()
export class AppStoreEffects {
  /**
   * this effect called at the start of application,
   * solve data for current user
   */
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUser, routerNavigationAction),
      withLatestFrom(this.store$),
      filter(([action, storeState]) => {
        // prevent fetching data after navigation action after page refresh
        if (
          '@ngrx/router-store/navigation' === action.type &&
          !storeState.state.user.id
        ) {
          this.loadingIndicatorService.hide();

          return false;
        }
        const instance = this.getInstance(
          LoadUserAction === action.type
            ? storeState.router.state.root
            : action.payload.routerState.root
        );
        // // stop logic when same instance
        console.log('effect');

        const isSameInstance = +instance !== storeState.state.instance?.id;
        if (!isSameInstance) {
          this.loadingIndicatorService.hide();
        }
        return isSameInstance;
      }),
      switchMap(([action, storeState]) =>
        forkJoin({
          user: this.configDS.getUser(),
          instances: this.configDS.getInstances(),
          storeState: of(storeState),
        }).pipe(
          mergeMap((data) => {
            let currentInstance = data.instances[0];
            const instance = this.getInstance(
              LoadUserAction === action.type
                ? data.storeState.router.state.root
                : action.payload.routerState.root
            );
            if (instance) {
              currentInstance = data.instances.find((i) => i.id === +instance);
            }

            this.addUserLogs()

            return [
              loadUserConfigSuccess({
                payload: data,
              }),
              setInstance({
                instance: currentInstance,
              }),
            ];
          })
        )
      )
    )
  );

  /**
   * effect for getting list of available apps
   */
  loadApps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setInstance),
      filter((r) => !!r.instance),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        const params = {
          'instance-id': storeState.state.instance.id,
        };
        return forkJoin({
          instanceConfig: this.configDS.getInstanceConfig(
            storeState.state.instance
          ),
          meApps: this.configDS.getMeApps(storeState.state.instance.id),
          apps: this.configDS.getApps(),
          storeState: of(storeState),
        }).pipe(
          mergeMap((data) => {
            return [
              loadStoreSuccess({
                payload: {
                  apps: this.mergeApps(data.meApps, data.apps),
                  loaded: true,
                  loading: false,
                  isHeaderVisible: true,
                },
              }),
              updateInstance({ config: data.instanceConfig }),
              detectApp(storeState.router.state.url),
            ];
          })
        );
      })
    )
  );

  /**
   * effect called each time after instance selection
   * resolve data required per instance
   */
  loadConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setApp),
      filter((a) => !!a.app),
      withLatestFrom(this.store$),
      filter(([action, storeState]) => !!storeState.state.instance.config),
      switchMap(([action, storeState]) => {
        const state = APPS_MAP.get(APPS[action.app.name.toLowerCase()]);

        // Skip resolwing data for such apps as Submission
        if (!state.resolveData) {
          return of(true).pipe(
            map(() => {
              this.loadingIndicatorService.hide(true);
              return loadStoreSuccess({
                payload: {
                  categories: [],
                  collections: [],
                  period: [],
                  appLoading: false,
                  appLoaded: true,
                  sidenav: state,
                  isHeaderVisible: true,
                },
              });
            })
          );
        }
        return this.fetchAppRelatedData(storeState, state);
      })
    )
  );

  /*
   * used for detection application
   * called from focus resolver
   */
  setCurrentApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(detectApp),
      withLatestFrom(this.store$),
      filter(([action, storeState]) => {
        if (!storeState.state.apps.length || !action.payload) {
          this.loadingIndicatorService.hide();

          return false;
        }
        const newApp = this.getActiveApp(storeState.state.apps, action.payload);
        this.loadingIndicatorService.show(newApp);
        const detect =
          newApp &&
          newApp.id !== storeState.state.apps.find((a) => a.isActive)?.id;

        if (!detect) {
          this.loadingIndicatorService.hide(true);
        }

        return detect;
      }),
      switchMap(([action, storeState]) => {
        const newApp = this.getActiveApp(storeState.state.apps, action.payload);
        const state = APPS_MAP.get(APPS[newApp.name.toLowerCase()]);

        // Skip resolwing data for such apps as Submission
        if (!state.resolveData) {
          return of(
            setApp({
              app: { ...newApp },
            })
          );
        }

        return this.configDS
          .getAppDefaultFocusboard(storeState.state.instance.id, newApp.id)
          .pipe(
            map((board: IAppFocusBoard) => {
              return setApp({
                app: { ...newApp, defaultBoardID: board.defaultBoardID },
              });
            })
          );
      }),
      filter((action) => !!action)
    )
  );

  getExtraUrlParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectPeriod, loadStoreSuccess),
      withLatestFrom(this.store$),
      filter(([action, storeState]) => {
        return storeState.state.sidenav.containsPeriod;
      }),
      map(([action, storeState]) => {
        if (!storeState.state.sidenav.isExtraUrlParams) {
          return;
        }

        let period;
        if (action.type === AppActionTypes.selectPeriod) {
          period = (action as { periodType: string }).periodType;
        } else {
          period = storeState.state.period.find((p) => p.active)?.PeriodType;
        }

        return setExtraUrlParams({
          payload: {
            period,
          },
        });
      }),
      filter((action) => !!action)
    )
  );

  getDisplayMapParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      withLatestFrom(this.store$),
      filter(([action, storeState]) => {
        return storeState.state.sidenav.containsMap;
      }),
      switchMap(([action, storeState]) => {
        let displaymap = true;
        let urlDisplayMap =
          action.payload.routerState.root.queryParams.displaymap;
        if (urlDisplayMap) {
          displaymap = JSON.parse(urlDisplayMap);
        }

        return [
          setExtraUrlParams({
            payload: {
              displaymap,
              parentGeoLocationId:
                action.payload.routerState.root.queryParams.parentGeoLocationId,
              geolocation:
                action.payload.routerState.root.queryParams.geolocation,
            },
          }),
          patchExpandMap({ expandMap: displaymap }),
        ];
      }),
      filter((action) => !!action)
    )
  );

  constructor(
    private actions$: Actions,
    private configDS: ConfigService,
    private collectionsDS: CollectionsDataService,
    private activatedRoute: ActivatedRoute,
    private store$: Store<AppState>,
    private loadingIndicatorService: LoadingIndicatorService,
    private helpers: HelpersService,
  ) {}

  //check period and mark selected one
  private getActivePeriod(): string {
    const params = this.activatedRoute.queryParams['value'];
    const period = params.period || DEFAULT_PERIOD_TYPE;

    return period;
  }

  // Update collections data with icon and isSelected property for sidenav
  private updCollectionsData(
    collections: ICollection[],
    storeState
  ): ICollection[] {
    const storeCollections = storeState.state.collections;

    collections.forEach((c) => {
      c.iconName = 'gesture';
      c['isSelected'] = storeCollections.some(
        (i) => i.id === c.id && i.isSelected
      );
    });

    return collections;
  }

  // mark application as active, detect it by URL path
  private getActiveApp(apps, route: string) {
    const app = apps.find((a) => route.includes(`${a.name.toLowerCase()}`));

    if (app) {
      return {
        ...app,
        isActive: true,
      };
    }

    return;
  }

  private fetchAppRelatedData(storeState: AppState, sidenav: SidenavState) {
    const appConfig = APPS_MAP.get(
      APPS[storeState.state.app.name.toLocaleLowerCase()]
    );

    return forkJoin({
      period: this.configDS.getPeriodsData(
        storeState.state.app,
        storeState.state.instance
      ),
      collections: this.collectionsDS.getCollections(),
      storeState: of(storeState),
      categories: this.helpers.resolveCategories(
        storeState.state.app,
        sidenav,
        storeState
      ),
    }).pipe(
      mergeMap((data) => {
        this.loadingIndicatorService.hide(true);
        return [
          loadStoreSuccess({
            payload: {
              collections: this.updCollectionsData(
                data.collections || [],
                storeState
              ),
              period: data.period,
              categories: data.categories,
              appLoading: false,
              appLoaded: true,
              isHeaderVisible: true,
              sidenav: {
                ...sidenav,
                multiple:
                  data.categories.filter((i) => i.isSelected).length > 1,
              },
            },
          }),
          selectPeriod({ periodType: this.getActivePeriod() }),
        ];
      })
    );
  }

  private getInstance(route: ActivatedRouteSnapshot) {
    return (
      route.firstChild?.firstChild?.params['instance'] ||
      route.firstChild.params['instance']
    );
  }

  private mergeApps(meApps: IApp[], apps: IApp[]) {
    return apps.map((a) => {
      let ma = meApps.find((i) => i.id === a.id) || ({} as IApp);
      return {
        ...a,
        ...ma,
        isAppAvailable: ma.isAppAvailable || false,
        hasNewUpdate: ma.hasNewUpdate || false,
        url: ma.url,
      };
    });
  }

  private addUserLogs() {
    if(!!localStorage.getItem(SIGN_IN_ACTION)) {
      setTimeout(() => {
        this.helpers.logUserSignIn();
        localStorage.removeItem(SIGN_IN_ACTION);
      }, 0) // add timeout to wait for store update
    }
  }
}
