import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ISpinnerConfig } from '@vantage-platform/store/lib/services/loading-indicator/APP-TYPES';
import { LoadingIndicatorService } from '@vantage-platform/store/lib/services/loading-indicator/loading-indicator.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vp-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingScreenComponent implements OnInit, OnDestroy {
  showIndicator: Observable<boolean>;
  destroy$ = new Subject<void>();
  appConfig: ISpinnerConfig;

  constructor(
    private spinner: NgxSpinnerService,
    private loadingIndicatorService: LoadingIndicatorService
  ) {
    this.loadingIndicatorService.loadingIndicator
      .pipe(takeUntil(this.destroy$))
      .subscribe((config: ISpinnerConfig) => {
        if (config) {
          this.appConfig = config;
          this.spinner.show('loading-screen');
        } else {
          this.spinner.hide('loading-screen', 1000);
        }
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
