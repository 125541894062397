<div class="tile_text">
  <h3>{{ data.title | customThousandSeparator }}</h3>
  <div class="p has-tooltip">
    {{ data.legend }}
    <span
      class="megamenu-tooltip-container"
      [tooltip]="categoryTextTooltip"
      containerClass="category-tooltip"
      triggers="click:mouseleave"
      placement="bottom"
      ><i class="icon icon-info"></i
    ></span>

    <ng-template #categoryTextTooltip>
      <div [id]="'category' + data.legend">
        <h3>Source</h3>
        <p>
          {{ data.config.sourceName }}
        </p>
        <p>
          <a target="_blank" [href]="data.config.sourceDescription">
            {{ data.config.sourceDescription }}
          </a>
        </p>
      </div>
    </ng-template>
  </div>
</div>
