export const Tiles = {
  1: 'v4TileText',
  2: 'v4TileRadialBarChart',
  3: 'v4TileKpi',
  4: 'v4TileTable',
  5: 'v4TileStackedBarChart',
  6: 'v4TileNGLineChartText',
  7: 'v4TileBulletList',
  8: 'v4TileUrl',
  9: 'v4TileTextList',
  10: 'v4DualBarChart',
  11: 'v4TileCategoryText',
  22: 'v4TileDynamicText',
  23: 'v4TileDynamicText',
};
