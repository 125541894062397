import { AppState, collections, ICollection } from '@vantage-platform/store';

import { AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

export const collectionsUniquenessValidator = (store: Store<AppState>) => {
  return (control: AbstractControl) => {
    let _collections: ICollection[];
    store
      .select(collections)
      .pipe(take(1))
      .subscribe((values) => {
        _collections = values;
      });
    return _collections.find(
      (i) => i.name.toLowerCase() === (control.value as string).toLowerCase()
    ) && control.dirty
      ? { unic: { required: true } }
      : null;
  };
};
