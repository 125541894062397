import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState, IXPagination } from '@vantage-platform/store';
import { filter, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, of} from 'rxjs';
import { patchCardsItems, removeCardItem, setEndOfPage } from './cards-ui-state.actions';
import { CardsUiStatePartialState } from './cards-ui-state.reducer';
import { FocusDataService } from '../../services/focus-data.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class CardsUiStateEffects {
  refreshCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeCardItem),
      filter((a) => a.forceRefresh),
      withLatestFrom(this.store$),
      switchMap(([action, state]) => {
        return forkJoin({
          cards: this.focusDS.getFocusData(
            state.cardsUiState.params,
            state.cardsUiState.boardId
          ),
          cardsUiState: of(state.cardsUiState),
        }).pipe(
          mergeMap((data) => {
            let existing = data.cardsUiState.cards.map((c) => c.id) as number[];
            let cards = (data.cards.body || []).filter(
              (c) => !existing.includes(c.id)
            );
            const pagination = JSON.parse(
              data.cards.headers.get('X-Pagination')
            ) as IXPagination;
            return [
              patchCardsItems({
                payload: cards,
              }),
              setEndOfPage({
                payload:
                  pagination && pagination.PageNumber === pagination.TotalPages,
              }),
            ];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private focusDS: FocusDataService,
  ) {}
}
