import { SidenavState } from './store.reducers.sidenav';

const baseSidenav = {
  isLoading: false,
  loaded: false,
  showCategoriesAllBtn: true,
  isCategoriesMultiselect: true,
  isExtraUrlParams: false,
  multiple: false,
  resolveData: false,
  containsPeriod: false,
  categories: [],
  staticLinks: [],
};

export const FOCUS_SIDENAV_DEFAULT: SidenavState = {
  ...baseSidenav,
  appIcon: 'product_focus',
  appTitle: 'Vantage Focus',
  categoriesTitle: 'Focus Categories',
  categoriesAllTitle: 'View All Focus Categories',
  isExtraUrlParams: true,
  path: '/focus',
  resolveData: true,
  containsPeriod: true,
  dataService: 'configDS',
};

export const PROFILES_SIDENAV_DEFAULT: SidenavState = {
  ...baseSidenav,
  appIcon: 'product_profiles',
  appTitle: 'Vantage Profiles',
  categoriesTitle: 'Profiles Categories',
  categoriesAllTitle: 'View All Profiles Categories',
  isExtraUrlParams: true,
  path: '/profiles',
  resolveData: true,
  dataService: 'configDS',
  containsMap: true,
};

export const ANALYTICS_AREAS_DEFAULT: SidenavState = {
  ...baseSidenav,
  appIcon: 'product_analytics',
  appTitle: 'Vantage Analytics',
  appConfig: {
    reportPath: '/analytics/report',
    mapPath: '/analytics/map',
  },
  categoriesTitle: 'Visualisations',
  categoriesAllTitle: 'View All Visualisations',
  path: '/analytics',
  resolveData: true,
  dataService: 'configDS',
};

export const WORK_AREAS_DEFAULT: SidenavState = {
  ...baseSidenav,
  appIcon: 'product_work',
  appTitle: 'Vantage Work',
  categoriesTitle: 'Visualisations',
  categoriesAllTitle: 'View All Visualisations',
  path: '/work',
  resolveData: true,
  dataService: 'configDS',
};

export const SUBMISSION_SIDENAV_DEFAULT: SidenavState = {
  ...baseSidenav,
  appIcon: 'product_submission',
  appTitle: 'Vantage Submission',
  categoriesTitle: 'Submission Areas',
  categoriesAllTitle: 'View All Submission Areas',
  path: '/submission',
  resolveData: false,
  dataService: 'configDS',
  staticLinks: [
    { link: 'submission', title: 'Files Logs', icon: 'svg-piece_of_paper' },
    { link: 'submission/upload', title: 'Upload', icon: 'submit' },
  ],
};

export const MATCHING_SIDENAV_DEFAULT: SidenavState = {
  ...baseSidenav,
  appIcon: 'product_matching',
  appTitle: 'Matching Engine',
  categoriesTitle: 'Matching Areas',
  categoriesAllTitle: 'View All Matching Areas',
  path: '/matching',
  resolveData: false,
  dataService: 'configDS',
  staticLinks: [
    {
      link: 'matching',
      title: 'Matching Engine Rules',
      icon: 'parent_node',
    },
  ],
};

export enum APPS {
  focus = 'focus',
  analytics = 'analytics',
  profiles = 'profiles',
  submission = 'submission',
  matching = 'matching',
  work = 'work'
}

export const APPS_MAP = new Map<APPS, SidenavState>([
  [APPS.focus, FOCUS_SIDENAV_DEFAULT],
  [APPS.analytics, ANALYTICS_AREAS_DEFAULT],
  [APPS.work, WORK_AREAS_DEFAULT],
  [APPS.profiles, PROFILES_SIDENAV_DEFAULT],
  [APPS.submission, SUBMISSION_SIDENAV_DEFAULT],
  [APPS.matching, MATCHING_SIDENAV_DEFAULT],
]);
