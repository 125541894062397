import { CommonModule } from '@angular/common';
import { ConfigService } from '@vantage-platform/store/lib/services/config.service';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HelpersService } from './helpers.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, FormsModule],
  providers: [ConfigService, HelpersService]
})
export class SharedModule {}
