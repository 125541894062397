import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { ApexOptions } from 'ngx-apexcharts';
import { IStackedBarChart } from '../../models/i-card';

@Component({
  selector: 'vantage-platform-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StackedBarChartComponent implements OnInit {
  @Input() data: IStackedBarChart;

  chartOptions: ApexOptions = {
    chart: {
      height: 350,
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      width: '100%',
      events: {
        legendClick: (chartContext, seriesIndex, config) => {
          if (!config.globals.collapsedSeriesIndices.includes(seriesIndex)) {
            setTimeout(() => {
              const name = config.globals.seriesNames[seriesIndex];
              chartContext.hideSeries(
                config.globals.seriesNames.find((s) => s !== name)
              );
              chartContext.showSeries(name);
            }, 0);
          } else {
            setTimeout(() => {
              const name = config.globals.seriesNames[seriesIndex];
              chartContext.showSeries(name);
            }, 0);
          }
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    colors: [],
    series: [],
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontFamily: 'Inter',
        fontSize: '10px',
        colors: [],
      },
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        maxWidth: 200,
        minWidth: 150,
        align: 'left',
        offsetX: 0,
        style: {
          cssClass: 'labels',
          fontFamily: 'Inter',
          fontSize: '14px',
        },
      },
    },
    tooltip: {
      y: {
        formatter: this.formatter.bind(this),
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      hover: {},
    },
    legend: {
      fontFamily: 'Inter',
      fontSize: '14px',
      position: 'bottom',
      horizontalAlign: 'center',
      markers: {
        radius: 12,
        width: 12,
        height: 12,
      },
      customLegendItems: null,
    },
  };

  constructor() {}

  ngOnInit(): void {
    this.chartOptions.chart = {
      ...this.chartOptions.chart,
      ...this.data.config.chart,
    };
    this.chartOptions.dataLabels.style.colors = this.data.series_colors;
    this.chartOptions.series = this.data.series;
    this.chartOptions.colors = this.data.colors;
    this.chartOptions.xaxis.categories = this.data.categories;
  }

  formatter(val: string) {
    return this.data.config.chart.stackType === 'normal' ? val : val + '%';
  }
}
