export const numberFormatter = (val) => {
  let newval = 0;
  if (val > 1000) {
    if (val < 1000000) {
      newval = Math.round(val / 100) / 10;
      return newval + 'k';
    } else {
      newval = Math.round(val / 100000) / 10;
      return newval + 'm';
    }
  } else {
    return val;
  }
};
