import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

import { ITable } from '@vantage-platform/store';

@Component({
  selector: 'vantage-platform-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit {
  @Input() data: ITable;

  get headerExtraClass() {
    const columns = this.data.columns.filter(c => c.main !== true);

    if(columns.length === 1 && columns[0].config.alignment === "right") {
      return 'right'
    }
    return '';
  }

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {}

  @HostListener('window:resize')
  resize(event: Event) {
    this.updateTable();
  }

  updateTable() {
    Array.from(this.elRef.nativeElement.getElementsByTagName('tr')).map(
      (e: HTMLTableRowElement) => {
        const vis = e.getElementsByClassName(
          'visible'
        )[0] as HTMLTableCellElement;
        const hid = e.getElementsByClassName(
          'hidden'
        )[0] as HTMLTableCellElement;
        const width =
          vis.getAttribute('scope') === 'col'
            ? hid.offsetHeight - 73
            : hid.offsetHeight - 43;
        // set same height as hidden element have - elements padding and border width
        vis.style.height = `${width}px`;
      }
    );
  }
}
