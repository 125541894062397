import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFocusChannelData } from './interfaces/i-config';
import { IInstance } from '@vantage-platform/store';
import { environment } from 'apps/vantage-focus/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssistantDataService {

  constructor(private http: HttpClient) { }

  public getCardId(focusChannelData: IFocusChannelData, instance: IInstance) {
    const params = {
      geoLevel: focusChannelData.geoLevel,
      geoLocation: focusChannelData.geoLocation,
      indicator: focusChannelData.indicator,
      intent: focusChannelData.intent,
      'period-type': focusChannelData.period,
      'instance-id': instance.id
    };

    return this.http.get(
      `${environment.api.url}me/board-cards`, {params}
    )
  }

  public getHistory(url: string) {
    this.http.get(url)
  }
}
