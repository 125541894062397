<div>
  <h5 *ngIf="data.title" class="chart-title">
    {{ data.title }}
  </h5>
  <apx-chart
    [series]="series"
    [chart]="chart"
    [plotOptions]="plotOptions"
    [colors]="colors"
  ></apx-chart>
</div>