import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IChannel, ITeam } from '../models';

import { AuthService } from '../auth/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ICard } from '@vantage-platform/store';
import { InstancesConfigService } from '../services/instances-config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '@vantage-platform/toastr';
import { Subscription } from 'rxjs';
import { TeamsService } from '../services/teams.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'v4-share-2-teams',
  templateUrl: './share-2-teams.component.html',
  styleUrls: ['./share-2-teams.component.scss'],
})
export class Share2TeamsComponent implements OnInit, OnDestroy {
  public elem: HTMLElement;
  public card: ICard;
  public teams: Array<ITeam> = [];
  public channels: Array<IChannel> = [];
  public shareForm: UntypedFormGroup = this.fb.group({
    team: ['', Validators.required],
    channel: ['', Validators.required],
    message: [''],
  });
  private $subs: Subscription[] = [];

  constructor(
    public modalRef: BsModalRef,
    public authService: AuthService,
    private teamsService: TeamsService,
    private fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private configService: InstancesConfigService,
    private notificationService: NotificationService
  ) {}

  async ngOnInit() {
    this.teamsService.channels.next([]);
    if (!this.authService.loggedIn) {
      const token = await this.authService.login();
      if (!token) {
        this.notificationService.error(
          'Unable to retrieve data from Teams. Please try sharing card again.'
        );
        this.modalRef.hide();
      }
    }

    if (this.authService.loggedIn) {
      this.spinner.show('share-spinner');

      this.getConfig();

      this.$subs.push(
        this.shareForm
          .get('team')
          .valueChanges.subscribe((id) => this.teamsService.getChannels(id)),
        this.teamsService.teams.subscribe((teams) => (this.teams = teams)),
        this.teamsService.channels.subscribe(
          (channels) => (this.channels = channels)
        ),
        this.teamsService.closeModal
          .pipe(delay(400))
          .subscribe((_) => this.modalRef.hide())
      );
    }
  }

  public onSubmit(): void {
    this.spinner.show('share-spinner');
    this.teamsService.shareToTeams(this.elem, this.shareForm, this.card);
  }

  private getConfig() {
    this.$subs.push(
      this.configService.getConfig().subscribe((res) => {
        this.configService.config = JSON.parse(res['value']);
        this.teamsService.getTeams();
      })
    );
  }

  public ngOnDestroy() {
    if (this.$subs) this.$subs.forEach((s) => s.unsubscribe());
  }
}
