import {
  APPS_SPINNER_CONFIG,
  BASE_SPINNER_CONFIG,
  ISpinnerConfig,
} from './APP-TYPES';

import { BehaviorSubject } from 'rxjs';
import { IApp } from '@vantage-platform/store';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {
  public loadingIndicator: BehaviorSubject<
    ISpinnerConfig
  > = new BehaviorSubject(undefined);
  private isAppRelated: boolean;

  constructor() {}

  public show(app?: IApp) {
    const config = APPS_SPINNER_CONFIG[app?.id];
    if (config) {
      this.isAppRelated = true;
      this.loadingIndicator.next(config);
    } else {
      this.isAppRelated = false;
      this.loadingIndicator.next(BASE_SPINNER_CONFIG);
    }
  }

  public hide(force?: boolean) {
    if ((this.isAppRelated && force) || !this.isAppRelated) {
      this.loadingIndicator.next(undefined);
    }
  }
}
