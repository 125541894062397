import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { CardsService } from '../../cards/cards.service';
import { ICard } from '../../models/i-card';

@Component({
  selector: 'vantage-platform-add-to-collection',
  templateUrl: './add-to-collection.component.html',
  styleUrls: ['./add-to-collection.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddToCollectionComponent implements OnInit {
  @Input() card: ICard;

  constructor(public cardsService: CardsService) {}

  ngOnInit(): void {}

  pinCard(collectionId: number) {
    this.cardsService.pinCardToCollecton(this.card, collectionId).then(() => {
      this.card = { ...this.card };
      this.card.myFocusBoards = [...this.card.myFocusBoards, collectionId];
    });
  }

  unPinCard(collectionId: number) {
    this.cardsService
      .unPinCardFromCollecton(this.card, collectionId)
      .then(() => {
        this.card = { ...this.card };
        this.card.myFocusBoards = this.card.myFocusBoards.filter(
          (i) => i !== collectionId
        );
      });
  }
}
