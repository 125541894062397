import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { SlideUpToggleAnimation } from '../utilits/slide-up-toggle.animation';

@Component({
  selector: 'vp-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  animations: [SlideUpToggleAnimation],
  encapsulation: ViewEncapsulation.None,
})
export class BottomSheetComponent {
  constructor() {}

  /**
   * Closes bottom sheet component
   */
  close() {
    let ev = new CustomEvent('closeBottomSheet');
    document.dispatchEvent(ev);
  }
}
