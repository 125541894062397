import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AppState, instance } from '@vantage-platform/store';
import { Observable, of } from 'rxjs';
import { catchError, map, withLatestFrom } from 'rxjs/operators';

import { FocusDataService } from '@vantage-platform/cards-ui';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class FocusBoardGuard  {
  constructor(
    private fdService: FocusDataService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    const cardId =
      next.firstChild?.queryParams['card'] || next.queryParams['card'];

    if (!cardId) {
      return true;
    }

    return this.fdService.validateFocusBoardAccess(cardId).pipe(
      withLatestFrom(this.store.select(instance)),
      map(([r, ins]) => {
        if (r.status === 403 || r.status === 404) {
          return this.router.createUrlTree(['']);
        }

        if (r.body.instanceID !== ins.id) {
          // if URL is copy pased with unrelated changes
          return this.router.createUrlTree(['']);
        }

        return true;
      }),
      catchError(() => {
        //By default redirect to host,
        // based on logim will be selected assigned to user instance
        this.router.navigate([`/${window.location.pathname}`]);

        //Catch 403 error when user have no access to the instance or instance id missing
        return of(false);
      })
    );
  }
}
