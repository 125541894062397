import {
  AppState,
  IApp,
  IInstance,
  IUser,
  getCurrentApp,
  instance,
  user,
} from '@vantage-platform/store';
import { Store, select } from '@ngrx/store';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ICustomProperties } from "@microsoft/applicationinsights-core-js";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'apps/vantage-focus/src/environments/environment';

export interface ITrackService {
  event(name: string, customProperties?: any): void;

  pageView(
    name?: string,
    uri?: string,
    refUri?: string,
    pageType?: string,
    isLoggedIn?: boolean,
    properties?: {
      duration?: number;
      [key: string]: any;
    }
  ): void;
}

@Injectable({
  providedIn: 'root',
})
export class InsightService implements ITrackService {
  public insight = new ApplicationInsights({
    config: {
      instrumentationKey: environment.azureInsights.instrumentationKey,
    },
  });

  user: IUser = null;
  app: IApp = null;
  instance: IInstance = null;
  $user: Observable<IUser>;
  $app: Observable<IApp>;
  $instance: Observable<IInstance>;

  constructor(private store: Store<AppState>) {
    this.insight.loadAppInsights();
    this.$user = store.pipe(select(user));
    this.$app = store.pipe(select(getCurrentApp));

    this.$instance = store.pipe(select(instance));
    this.$app.subscribe((a) => (this.app = a));

    this.$user.subscribe((u) => (this.user = u));
    this.$instance.subscribe((i) => (this.instance = i));
  }

  public flush() {
    this.insight.flush();
  }

  public event(name: string, properties?: ICustomProperties): void {
    this.insight.trackEvent({
      name,
      properties: {
        username: this.user.username,
        user: `${this.user.firstname} ${this.user.surname}`,
        userId: this.user.id,
        instance: this.instance?.id,
        instanceName: this.instance?.name,
        appId: this.app?.id,
        appName: this.app?.name,
        ...properties,
      },
    });
  }

  public pageView(
    name?: string,
    uri?: string,
    refUri?: string,
    pageType?: string,
    isLoggedIn?: boolean,
    properties?: {
      duration?: number;
      [key: string]: any;
    }
  ): void {
    this.insight.trackPageView({
      name,
      uri,
      refUri,
      pageType,
      isLoggedIn,
      properties: {
        ...properties,
        username: this.user.username,
        user: `${this.user.firstname} ${this.user.surname}`,
        userId: this.user.id,
        instance: this.instance.id,
        instanceName: this.instance.name,
        appId: this.app?.id,
        appName: this.app?.name,
      },
    });
  }
}
