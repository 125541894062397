import { ITour } from './i-tour';
import {
  builtInButtons,
  preparations,
  TourDeviceType,
} from './default-step-options';
import shepherd from 'shepherd.js';

export const analyticsSteps = (
  deviceType: TourDeviceType
): shepherd.Step.StepOptions[] => {
  const onlyVerticalAlignment = deviceType.deviceType === 'mobile';
  const platformRequiresToOpenSideNav =
    deviceType.deviceType === 'mobile' || deviceType.deviceType === 'tablet';
  const platformRequiresToOpenCardActionsModal =
    deviceType.deviceType === 'mobile';
  const focusButtonInTheCardHeader = deviceType.deviceType !== 'mobile';
  const _preparations = (x: {
    shouldOpenCardsMenu: boolean;
    shouldOpenSideNav: boolean;
    requiresScrollTop?: true;
  }) =>
    preparations({
      shouldOpenCardsMenu: x.shouldOpenCardsMenu,
      shouldOpenSideNav: x.shouldOpenSideNav,
      platformRequiresToOpenSideNav,
      platformRequiresToOpenCardActionsModal,
    }).then(async () => {
      if ('requiresScrollTop' in x) {
        const cardsScrollContainer = document.querySelector('.cards-wrapper');
        cardsScrollContainer.scrollTop = 0;
        await new Promise((resolve) => setTimeout(resolve, 300)); // 250ms is header animation
      }
    });

  return [
    {
      attachTo: {
        element: '.tile-text',
        on: 'top-start',
      },
      beforeShowPromise: () =>
        _preparations({
          shouldOpenSideNav: false,
          shouldOpenCardsMenu: false,
          requiresScrollTop: true,
        }),
      scrollTo: false,
      popperOptions: {
        modal: false,
      },
      buttons: [builtInButtons.next],
      classes: 'step-insight-cards',
      id: 'insightCards',
      title: 'Insight Cards',
      text:
        'Scroll to discover new data Visualisations, prioritized in order of relevance to you. ',
    } as shepherd.Step.StepOptions,
    {
      attachTo: {
        element:
          'div.ps-content > nav:nth-child(1) > ul.bottom-line-devider+ul > li:nth-child(1)',
        on: onlyVerticalAlignment ? 'bottom-end' : 'left-start',
      },
      beforeShowPromise: () =>
        _preparations({ shouldOpenSideNav: true, shouldOpenCardsMenu: false }),
      buttons: [builtInButtons.back, builtInButtons.next],
      classes: 'step-focus-areas',
      id: 'focusAreas',
      title: 'Visualisations',
      text:
        'Filter the Insight Cards by selecting a Visualisation from the Navigation Panel.',
    } as shepherd.Step.StepOptions,
    {
      attachTo: {
        element: focusButtonInTheCardHeader
          ? 'vantage-platform-question > div.focus-button-container > button'
          : '.focus-button-block>.focus-button',
        on: onlyVerticalAlignment ? 'bottom-end' : 'right-start',
      },
      scrollTo: true,
      beforeShowPromise: () =>
        _preparations({ shouldOpenSideNav: false, shouldOpenCardsMenu: false }),
      buttons: [builtInButtons.back, builtInButtons.next],
      highlightClass: 'visible',
      classes: 'step-focus-card',
      id: 'focusCard',
      title: 'View Report',
      text: 'Click here to view full Microsoft BI Analytics report.',
    } as shepherd.Step.StepOptions,
    {
      attachTo: {
        element: '.static-content-wrapper .nav-item.create-collection',
        on: 'top-start',
      },
      beforeShowPromise: () =>
        _preparations({ shouldOpenSideNav: true, shouldOpenCardsMenu: false }),
      scrollTo: true,
      buttons: [builtInButtons.back, builtInButtons.next],
      classes: 'step-create-collection',
      highlightClass: 'hover-effect-btn',
      id: 'createCollection',
      title: 'Create a Collection',
      text:
        'Creating a Collection is a great way to group a set of Insight Cards for a specific meeting or analysis. Your Collections will be saved in the navigation panel.',
    } as shepherd.Step.StepOptions,
    ...(platformRequiresToOpenCardActionsModal
      ? ([
          {
            attachTo: {
              element: '.show-mobile-menu-btn',
              on: onlyVerticalAlignment ? 'bottom-end' : 'right-end',
            },
            beforeShowPromise: () =>
              _preparations({
                shouldOpenCardsMenu: false,
                shouldOpenSideNav: false,
                requiresScrollTop: true,
              }),
            scrollTo: true,
            buttons: [builtInButtons.back, builtInButtons.next],
            classes: 'step-add-card',
            highlightClass: 'hover-effect-icon-btn',
            id: 'addToCollection',
            title: `Cards's actions menu`,
            text: 'You can add cards to collections and share using this menu',
          } as shepherd.Step.StepOptions,
          {
            attachTo: {
              element: 'vantage-platform-add-to-collection.mobile>button',
              on: onlyVerticalAlignment ? 'top-end' : 'right-end',
            },
            beforeShowPromise: () =>
              _preparations({
                shouldOpenCardsMenu: true,
                shouldOpenSideNav: false,
              }),
            buttons: [builtInButtons.back, builtInButtons.next],
            classes: 'step-add-card',
            highlightClass: 'hover-effect-icon-btn',
            id: 'addToCollection',
            title: 'Add Card to Collection',
            text:
              'You can add an Insight Card to a Collection, there is no limit.',
          } as shepherd.Step.StepOptions,
        ] as shepherd.Step.StepOptions[])
      : ([
          {
            attachTo: {
              element: 'vantage-platform-add-to-collection>button',
              on: onlyVerticalAlignment ? 'bottom-end' : 'right-end',
            },
            beforeShowPromise: () =>
              _preparations({
                shouldOpenCardsMenu: false,
                shouldOpenSideNav: false,
              }),
            buttons: [builtInButtons.back, builtInButtons.next],
            classes: 'step-add-card',
            highlightClass: 'hover-effect-icon-btn',
            id: 'addToCollection',
            title: 'Add Card to Collection',
            text:
              'You can add an Insight Card to a Collection, there is no limit.',
          } as shepherd.Step.StepOptions,
        ] as shepherd.Step.StepOptions[])),
    {
      attachTo: {
        element: platformRequiresToOpenCardActionsModal
          ? 'button.bottom-sheet-menu-item.card-share-2-teams'
          : 'button.card-share-2-teams',
        on: 'bottom-end',
      },
      beforeShowPromise: () =>
        _preparations({
          shouldOpenCardsMenu: true,
          shouldOpenSideNav: false,
        }),
      buttons: [builtInButtons.back, builtInButtons.next],
      classes: 'step-share-card',
      highlightClass: 'hover-effect-icon-btn',
      id: 'shareCard',
      title: 'Share Insight Card',
      text:
        'Share Insight Cards directly to your preferred teams or team members in Microsoft Teams.',
    } as shepherd.Step.StepOptions,
    {
      attachTo: {
        element:
          'vp-side-nav > div.side-menu > div.side-menu-header > button.text.menu-btn > span',
        on: onlyVerticalAlignment ? 'bottom' : 'left',
      },
      beforeShowPromise: () =>
        _preparations({
          shouldOpenSideNav: true,
          shouldOpenCardsMenu: false,
        }),
      buttons: [builtInButtons.back, builtInButtons.next],
      highlightClass: 'hover-effect-btn',
      classes: 'step-apps',
      id: 'appsSelector',
      title: 'Vantage Product Suite',
      text: 'Access your full Vantage Product Suite here.',
    } as shepherd.Step.StepOptions,
    {
      attachTo: {
        element: '.assistant-fab-button',
        on: onlyVerticalAlignment ? 'top' : 'right-end',
      },
      beforeShowPromise: () =>
        _preparations({ shouldOpenCardsMenu: false, shouldOpenSideNav: false }),
      buttons: [builtInButtons.back, builtInButtons.cancel],
      classes: 'step-assistant',
      id: 'assistant',
      title: 'Ask the Vantage Assistant',
      text:
        'Have a program-related question? Ask Vantage Assistant to guide you to find the right answer.',
    } as shepherd.Step.StepOptions,
  ] as shepherd.Step.StepOptions[];
};

export const analyticsTour = (deviceType: TourDeviceType): ITour => ({
  welcomeMsg: 'Welcome to Vantage Analytics',
  tourDescription:
    'Slice, dice and access your data for deeper analysis and reporting.',
  path: 'analytics',
  icon: 'product_analytics',
  steps: analyticsSteps(deviceType),
});
