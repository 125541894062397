import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IDynamicTileData, ITile } from '@vantage-platform/store';

@Component({
  selector: 'vantage-platform-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DynamicComponent implements OnInit {
  @Input() tile: ITile;
  data: IDynamicTileData;
  
  constructor(private sanitizer: DomSanitizer) {}

  get htmlText() {
    return this.sanitizer.bypassSecurityTrustHtml(this.convertToHtml(this.data.dynamicLanguage || this.data.dynamicText))
  }

  ngOnInit(): void {
    if (this.tile) {
      this.data = this.tile.data as IDynamicTileData;
    }
  }

  private convertToHtml(list: string[]) {
    return list.reduce((prev, next) => prev + '<br>' + next)
  }
}
