import {
  ApexChart,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
} from 'ngx-apexcharts';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import { IRadialBarChartData } from '@vantage-platform/store';

@Component({
  selector: 'vantage-platform-radial-bar-chart',
  templateUrl: './radial-bar-chart.component.html',
  styleUrls: ['./radial-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadialBarChartComponent implements OnInit {
  @HostBinding('id') elId = 'chart'; // used for tour
  @Input() data: IRadialBarChartData;

  series: ApexNonAxisChartSeries;
  colors: any[];
  chart: ApexChart = {
    height: 260,
    type: 'radialBar',
  };

  plotOptions: ApexPlotOptions = {
    radialBar: {
      hollow: {
        size: '50%',
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          show: true,
          fontSize: '30px',
          offsetY: 10,
        },
      },
      track: {
        strokeWidth: '70',
        dropShadow: {
          enabled: false,
        },
      },
    },
  };

  constructor() {}

  ngOnInit(): void {
    this.series = this.data.series;
    this.colors = this.data.colors;
  }
}
